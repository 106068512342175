import { createSlice } from '@reduxjs/toolkit'
import { Message, MessageDetail } from '../types/Messages'

interface MessageState {
  Messages: Message[]
  Message?: MessageDetail
}

const initialState: MessageState = {
  Messages: [],
  Message: undefined,
}

const messageSlice = createSlice({
  initialState,
  name: 'MESSAGE',
  reducers: {
    LOAD_MESSAGES_SUCCESS(state, action) {
      return {
        ...state,
        Messages: action.payload,
      }
    },
    LOAD_SINGLE_MESSAGE_SUCCESS(state, action) {
      return {
        ...state,
        Message: action.payload,
      }
    },
  },
})

// eslint-disable-next-line import/no-unused-modules
export const { LOAD_MESSAGES_SUCCESS, LOAD_SINGLE_MESSAGE_SUCCESS } = messageSlice.actions

export default messageSlice.reducer
