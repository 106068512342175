import {
  VALIDATE_CUSTOMER_PHONE_SUCCESS,
  VALIDATE_NEW_CUSTOMER_SMS_CODE_SUCCESS,
  CREATE_ACCOUNT_SUCCESS,
  SEND_USER_REGISTRATION_EMAIL,
} from '../../reducers/registrationSlice'
import {
  validateCustomerPhone,
  validateNewCustomerSMSCode,
  createAccount,
  sendUserRegistrationEmail,
} from '../apiAccount'
import { createUseRequest } from './createUseRequest'

export const useValidateCustomerPhone = createUseRequest({
  apiCall: validateCustomerPhone,
  successActionCreator: VALIDATE_CUSTOMER_PHONE_SUCCESS,
})

export const useValidateNewCustomerSMSCode = createUseRequest({
  apiCall: validateNewCustomerSMSCode,
  successActionCreator: VALIDATE_NEW_CUSTOMER_SMS_CODE_SUCCESS,
})

export const useCreateAccount = createUseRequest({
  apiCall: createAccount,
  successActionCreator: CREATE_ACCOUNT_SUCCESS,
})

export const useSendUserRegistrationEmail = createUseRequest({
  apiCall: sendUserRegistrationEmail,
  successActionCreator: SEND_USER_REGISTRATION_EMAIL,
})
