import React from 'react'
import useI18n from '../../../i18n/useI18n'
import { TranslationKey } from '../../../types/generated/TranslationKey'

const formErrorMapping: Record<string, TranslationKey> = {
  existing_email: 'The email is already registered',
  customer_exists: 'The customer number is already registered in the system',
  to_many_attempts:
    'The customer number has been blocked for registration throug mypage. Please contact customer service.',
  default: 'Invalid customer and phone number combination',
}

const FormError = ({ errorMessage }: { errorMessage?: string }) => {
  const { t } = useI18n()
  if (!errorMessage) return null

  return (
    <div>
      <div className="notification--error">
        {t(formErrorMapping[errorMessage] || formErrorMapping['default'])}
      </div>
    </div>
  )
}

export default FormError
