import { createSlice, PayloadAction } from '@reduxjs/toolkit'
type SimpleRegistrationState = {
  PinCode?: string
  hasCreatedUser: boolean
}

const initialState: SimpleRegistrationState = {
  hasCreatedUser: false,
}

const simpleRegistrationSlice = createSlice({
  name: 'simpleRegistration',
  initialState,
  reducers: {
    VALIDATE_SMS_SUCCESS(state, action: PayloadAction<{ PinCode: string }>) {
      state.PinCode = action.payload.PinCode
    },
    CREATE_USER_SUCCESS(state) {
      state.hasCreatedUser = true
    },
    REQUEST_SMS() {},
  },
})

// eslint-disable-next-line import/no-unused-modules
export const {
  VALIDATE_SMS_SUCCESS,
  CREATE_USER_SUCCESS,
  REQUEST_SMS,
} = simpleRegistrationSlice.actions

export const simpleRegistrationReducer = simpleRegistrationSlice.reducer
