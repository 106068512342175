import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import BackgroundImagePage from '../../components/pages/BackgroundImagePage'

const NotFoundPage: FC = () => {
  return (
    <BackgroundImagePage>
      <div className="center">
        <h1>Cant find page</h1>
        <Link to="/">go to home page</Link>
      </div>
    </BackgroundImagePage>
  )
}

export default NotFoundPage
