import React from 'react'
import useI18n from '../../../i18n/useI18n'
import { CameraIcon } from '../../../assets/icons'

const VideoExpandable = () => {
  const { t } = useI18n()
  const showIcons = window.screen.availWidth > 320
  return (
    <div className="grid mb-6">
      <div className="expandable-list">
        {showIcons && (
          <div className="expandable-list__icon flex justify center items-center">
            <CameraIcon className="h-9 flex-1" style={{ height: '46px' }} />
          </div>
        )}
        <div className="expandable-list__section">
          <div className="expandable-list__summary">
            <div className="expandable-list__text">
              <h2>{t('VIDEO_ONLY_AVAILABLE_IN_APP')}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VideoExpandable
