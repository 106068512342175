import './onboarding-wizard-footer.scss'

import cx from 'classnames'
import React, { FC } from 'react'
import useI18n from '../../../i18n/useI18n'
import { OnboardingWizardStep } from '../constants'

type Props = {
  activeStep: OnboardingWizardStep
}

const Step: FC<{ active: boolean; done: boolean }> = ({ active, done, children }) => (
  <div
    className={cx(
      'onboarding-wizard-footer__step',
      active && 'onboarding-wizard-footer__step--active',
      done && 'onboarding-wizard-footer__step--done',
    )}
  >
    <div className="onboarding-wizard-footer__step-text">{children}</div>
  </div>
)

const wizardSteps = Object.values(OnboardingWizardStep)

const OnboardingWizardFooter: FC<Props> = ({ activeStep }) => {
  const { t } = useI18n()
  const activeIndex = wizardSteps.indexOf(activeStep)
  return (
    <footer className="onboarding-wizard-footer">
      {Object.values(OnboardingWizardStep).map((step, index) => (
        <Step key={step} active={step === activeStep} done={activeIndex > index}>
          {t(step)}
        </Step>
      ))}
    </footer>
  )
}

export default OnboardingWizardFooter
