import styled from 'styled-components'
import Toast, { addToast } from '../../../../components/Crow/Toast/Toast'
import { Paragraph } from '../../../../components/Crow/Typography'
import { Loader } from '../../../../components/Elements/Loaders'
import TwoFactorPrompt, {
  useTwoFactorPrompt,
} from '../../../../components/TwoFactorPrompt/TwoFactorPrompt'
import useI18n from '../../../../i18n/useI18n'
import { useGetDirections, useSetDirections } from '../../../../services/requestHooks/directions'
import { Accesses } from '../../../../types/PermanentUser'
import { useOnMount } from '../../../../utils/commonHooks'
import FailedRequest from '../../../onboardingWizard/components/FailedRequest'
import { usePanelId } from '../../hooks/usePanelId'
import PasswordPrompt, { usePasswordPrompt } from '../../Panel/PasswordPromt'
import LocationAndDirectionsForm, { DirectionsFormValues } from './LocationAndDirectionsForm'

const LocationAndDirectons = ({ permissions }: { permissions: Accesses | any }) => {
  const { t } = useI18n()
  const panelId = usePanelId()
  const { promptState: twoFactorPromptState, promptForTwoFactor } = useTwoFactorPrompt()
  const { promptState: passwordPromptState, promptForPassword } = usePasswordPrompt()
  const returnUrl = '/systems/' + panelId + '/settings'

  const {
    run: getDirections,
    data: directions,
    error: getDirectionsError,
    isLoading: getDirectionsLoading,
  } = useGetDirections()

  const {
    run: saveDirections,
    error: setDirectionsError,
    isLoading: setDirectionsLoading,
  } = useSetDirections({
    onSuccess: () => {
      addToast({
        title: `${t('Directions')} ${t('Is set').toLowerCase()}`,
        type: 'success',
        time: 2000,
      })
    },
  })

  useOnMount(() => {
    promptForTwoFactor(returnUrl).then((code) => {
      if (!code) return
      getDirections({ PanelId: panelId, ValidationCode: code })
    })
  })

  const handleOnSubmit = async (values: DirectionsFormValues) => {
    const { directions = '' } = values
    const ValidationCode = await promptForTwoFactor()
    if (ValidationCode) {
      const Password = await promptForPassword()
      if (Password) {
        if (!permissions?.IsLegalOwner && !permissions?.IsAdminUser) {
          addToast({
            title: `${t('full_access')} ${t('Required')}`,
            type: 'error',
            time: 2000,
          })
        } else
          saveDirections({
            PanelId: panelId,
            ValidationCode,
            Directions: directions,
            Password,
          })
      }
    }
  }

  return (
    <>
      <DrawerContentContainer>
        <LocationAndDirectionsForm
          onSubmit={handleOnSubmit}
          defaultValues={directions !== undefined ? { directions } : undefined}
          required
        />
        {getDirectionsLoading || setDirectionsLoading ? (
          <div className="flex align-center">
            <Loader />
            <LocationAndDirectionsLoadingText grey light>
              {t('change_in_progress')}
            </LocationAndDirectionsLoadingText>
          </div>
        ) : null}
        {getDirectionsError && (
          <FailedRequest
            text={t(
              'Could not load location information, please try again or contact customer service.',
            )}
          />
        )}
        {setDirectionsError && <FailedRequest text={t('Something went wrong. Please try again')} />}
      </DrawerContentContainer>
      <PasswordPrompt promptState={passwordPromptState} />
      <TwoFactorPrompt promptState={twoFactorPromptState} />
      <Toast />
    </>
  )
}

const DrawerContentContainer = styled.div`
  padding-left: 50px;
  max-width: 450px;
  @media only screen and (max-width: 375px) {
    padding-left: 0;
  }
`

const LocationAndDirectionsLoadingText = styled(Paragraph)`
  line-height: 18px;
  padding-left: 12px;
`

export default LocationAndDirectons
