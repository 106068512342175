import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { Title } from '../Crow/Typography/Typography'
import { LoaderProps } from '../Elements/Loaders'
import Footer from '../Footer'
import HeaderTemplate from '../HeaderTemplate'
import LoadPage from '../LoadPage'

interface PageProps {
  heading: ReactNode
  isLoading?: boolean
  loaderProps?: LoaderProps
  className?: string
  contentPadding?: string
  large?: boolean
}

const SplitPage: FC<PageProps> = ({
  children,
  heading,
  isLoading,
  loaderProps,
  contentPadding,
  large,
  className,
}) => {
  return (
    <div className="flex-1 split-page">
      <div className={`background-container ${className}`}>
        <div className={`background-image ${className}`} />
        <div className={`background-overlay px-8 ${className}`}>
          <HeaderTemplate />
          <div className="flex-1 flex justify-center items-center">
            {large ? <h1>{heading}</h1> : <Title large>{heading}</Title>}
          </div>
        </div>
      </div>
      <Main contentPadding={contentPadding!}>
        <div className="grid">
          <div className="grid__one-third">
            <LoadPage isLoading={isLoading} loaderProps={loaderProps}>
              {children}
            </LoadPage>
          </div>
        </div>
      </Main>
      <Footer />
    </div>
  )
}

const Main = styled.main<{ contentPadding?: string }>`
  height: 100%;
  padding-top: ${({ contentPadding }) => (contentPadding ? contentPadding : '48px')};
`

export default SplitPage
