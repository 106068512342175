import { SubTitle } from '../../../components/Crow/Typography'
import useI18n from '../../../i18n/useI18n'
import { MountType } from '../../../types/DoorsAndWindows'
import DeviceList from './DeviceList'
import FloorAndPanelStatus from './types/DoorAndWindowsType'

const DoorsAndWindowDetails = ({ floors, panelStatus }: FloorAndPanelStatus) => {
  const { t } = useI18n()

  const devices = floors.Rooms.flatMap((device) => device.Devices)

  const hasWindows = devices.some((deviceType) => deviceType.MountType === MountType.WINDOW)
  const hasDoors = devices.some((deviceType) => deviceType.MountType === MountType.DOOR)

  return (
    <div className="pb-4">
      {hasDoors && <SubTitle className="pl-4 pb-3">{t('Doors')}</SubTitle>}
      <DeviceList floors={floors} panelStatus={panelStatus} type={MountType.DOOR} />
      {hasWindows && <SubTitle className="pl-4 pb-3 pt-8">{t('Windows')}</SubTitle>}
      <DeviceList floors={floors} panelStatus={panelStatus} type={MountType.WINDOW} />
    </div>
  )
}

export default DoorsAndWindowDetails
