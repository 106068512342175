import { UPDATE_CONTACTS } from '../../reducers/contactsSlice'
import { RootState } from '../../store'
import { getContactPersons, setContactPersons } from '../settingsApi'
import { createUseRequest } from './createUseRequest'

const selectContacts = (state: RootState, panelId: string) => state.contacts[panelId]?.Contacts

export const useContacts = createUseRequest({
  successActionCreator: UPDATE_CONTACTS,
  apiCall: getContactPersons,
  selector: selectContacts,
  requirePanelId: true,
})

export const useSetContacts = createUseRequest({
  successActionCreator: UPDATE_CONTACTS,
  apiCall: setContactPersons,
  requirePanelId: true,
})
