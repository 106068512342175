import React, { FC, useEffect, useState } from 'react'
import Button from '../../../../../components/Button'
import LoadPage from '../../../../../components/LoadPage'
import Modal from '../../../../../components/Modal'
import useI18n from '../../../../../i18n/useI18n'
import { usePanelId } from '../../../hooks/usePanelId'
import FailedRequest from '../../../../onboardingWizard/components/FailedRequest'
import AddPropertyContactModal, {
  useAddPropertyContactModal,
} from '../AddContactUser/AddContactUserModal'
import PropertyContactForm, { PropertyContactSubmit } from './PropertyContactForm'
import {
  useGetPropertyContacts,
  useSetPropertyContact,
} from '../../../../../services/requestHooks/propertyContact'

type Props = {
  show: boolean
  onSubmit?: () => void
  onCancel?: () => void
}

const PropertyContactModal: FC<Props> = ({ show, onSubmit, onCancel }) => {
  const panelId = usePanelId()
  const { t } = useI18n()
  const {
    run: loadPropertyContacts,
    data: propertyContacts,
    isLoading: loadingPropertyContacts,
    error: loadContactsError,
  } = useGetPropertyContacts()

  const {
    run: setPropertyContact,
    isLoading: settingPropertyContact,
    error: setContactError,
  } = useSetPropertyContact({
    onSuccess: () => {
      onSubmit?.()
    },
  })
  const { modalState: addContactState, promptForNewContact } = useAddPropertyContactModal()

  const onAddClick = async () => {
    const userAdded = await promptForNewContact()
    if (userAdded) {
      loadPropertyContacts({ panelId })
    }
  }

  const handleSubmit: PropertyContactSubmit = ({ selectedUserId: AppUserId }) => {
    if (propertyContacts && AppUserId) {
      setPropertyContact({ AppUserId, panelId })
    }
  }

  useEffect(() => {
    loadPropertyContacts({ panelId })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelId])

  const showModal = show && !addContactState.show

  const isLoading = loadingPropertyContacts || settingPropertyContact

  return (
    <>
      {showModal && (
        <Modal handleClose={isLoading ? undefined : onCancel}>
          <LoadPage isLoading={loadingPropertyContacts || settingPropertyContact}>
            {loadContactsError ? (
              <>
                <FailedRequest errorId="propertyContact-failed-request" text={loadContactsError} />
                <Button buttonType="secondary">{t('Cancel')}</Button>
              </>
            ) : (
              <PropertyContactForm
                onAddClick={onAddClick!}
                propertyContacts={propertyContacts}
                onSubmit={handleSubmit}
                onCancel={onCancel!}
                panelId={panelId}
              />
            )}
            {!!setContactError && (
              <FailedRequest errorId="propertyContact-error" text={setContactError} />
            )}
          </LoadPage>
        </Modal>
      )}

      <AddPropertyContactModal {...addContactState} />
    </>
  )
}

export const usePropertyContactModal = () => {
  const [modalState, setModalState] = useState<{
    show: boolean
    onSubmit?: () => void
    onCancel?: () => void
  }>({ show: false })

  const promptForContactUser = () => {
    return new Promise<true | undefined>((resolve) => {
      setModalState({
        show: true,
        onSubmit: () => {
          setModalState({ show: false })
          resolve(true)
        },
        onCancel: () => {
          setModalState({ show: false })
          resolve(undefined)
        },
      })
    })
  }
  return {
    promptForContactUser,
    modalState,
  }
}

export default PropertyContactModal
