import './contactperson-list-item.scss'
import React, { FC } from 'react'
import Button from '../../../../../components/Button'
import useI18n from '../../../../../i18n/useI18n'
import { ContactPerson, PhoneNumberType } from '../../../../../types/ContactPerson'
import { UserIcon } from '../../../../../assets/icons'

type Props = {
  person: ContactPerson
  onClick: (person: ContactPerson) => void
  changePriority: (person: ContactPerson, priority: 'up' | 'down') => void
  deletePerson: (chosenPerson: ContactPerson) => void
  isFirst: boolean
  isLast: boolean
}

const ContactPersonListItem: FC<Props> = ({
  person,
  onClick,
  changePriority,
  deletePerson,
  isFirst,
  isLast,
}) => {
  const { t } = useI18n()
  const { FirstName, LastName, Mobile, Home, Work } = person

  return (
    <li className="contactperson-list-item mb-4">
      <details className="contactperson-list-item__details">
        <summary className="contactperson-list-item__summary pb-2">
          <UserIcon className="icon icon--tiny icon--left" />
          <h3>{`${FirstName} ${LastName}`}</h3>
          <p>
            {Object.entries({ Mobile, Home, Work }).map(
              ([key, phoneNumber]) =>
                phoneNumber && `${phoneNumber} (${t(key as PhoneNumberType)}) `,
            )}
          </p>
        </summary>
        <Button
          buttonType="secondary"
          size="small"
          disabled={isFirst}
          onClick={() => changePriority(person, 'up')}
        >
          ↑
        </Button>
        <Button
          buttonType="secondary"
          size="small"
          disabled={isLast}
          onClick={() => changePriority(person, 'down')}
        >
          ↓
        </Button>
        <Button buttonType="secondary" size="small" onClick={() => onClick(person)}>
          {t('Edit')}
        </Button>
        <Button buttonType="third" size="small" type="button" onClick={() => deletePerson(person)}>
          {t('Delete')}
        </Button>
      </details>
    </li>
  )
}

export default ContactPersonListItem
