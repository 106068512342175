import {
  GET_AVAILABLE_TIMESLOTS,
  SET_TIMESLOT,
  SET_BOOKING_DONE,
} from '../../reducers/bookingSlice'
import { RootState } from '../../store'
import { getAvailableTimeSlots, setTimeSlot, setPreInstallationWizardDone } from '../settingsApi'
import { createUseRequest } from './createUseRequest'

const selectAvailableTimeSlots = (state: RootState, panelId: string) =>
  state.booking[panelId]?.availableTimeSlots

export const useAvailableTimeSlots = createUseRequest({
  successActionCreator: GET_AVAILABLE_TIMESLOTS,
  apiCall: getAvailableTimeSlots,
  selector: selectAvailableTimeSlots,
  requirePanelId: true,
})

export const useSetTimeSlot = createUseRequest({
  successActionCreator: SET_TIMESLOT,
  apiCall: setTimeSlot,
  requirePanelId: true,
})

export const useSetPreInstallationWizardDone = createUseRequest({
  successActionCreator: SET_BOOKING_DONE,
  apiCall: setPreInstallationWizardDone,
  requirePanelId: true,
})
