import React, { ReactNode, FC } from 'react'
import styled, { css } from 'styled-components'

import { colors } from '../Style/theme'

type Position = 'top' | 'right' | 'bottom' | 'left'

export interface Props {
  text: string
  position?: Position // tooltip placement
  active?: boolean // shows tooltip (on hover)
  shouldGrow?: boolean // makes children take up the available horizontal space
  children: ReactNode
  className?: string
}

const Tooltip: FC<Props> = ({
  text,
  position = 'top',
  active = true,
  shouldGrow = true,
  children,
  className,
}) => {
  return (
    <Container shouldGrow={shouldGrow} className={className}>
      {active && (
        <Content position={position} shouldGrow={shouldGrow}>
          {text}
        </Content>
      )}
      <ChildrenWrapper shouldGrow={shouldGrow}>{children}</ChildrenWrapper>
    </Container>
  )
}

const arrowBase = css`
  position: absolute;
  content: '';
  background-color: ${colors.black900};
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
`

const placeTooltip = (tooltipPosition: Position, shouldGrow: boolean) => {
  switch (tooltipPosition) {
    case 'top':
      return css`
        bottom: calc(100% + 10px);
        left: ${shouldGrow && '16px'};

        &::before {
          ${arrowBase}
          bottom: -3px;
          left: ${shouldGrow ? '16px' : 'calc(50% - 6px)'};
        }
      `
    case 'right':
      return css`
        left: calc(100% + 10px);

        &::before {
          ${arrowBase}
          top: 14px;
          left: -3px;
        }
      `
    case 'bottom':
      return css`
        top: calc(100% + 10px);
        left: ${shouldGrow && '16px'};

        &::before {
          ${arrowBase}
          top: -3px;
          left: ${shouldGrow ? '16px' : 'calc(50% - 6px)'};
        }
      `
    case 'left':
      return css`
        right: calc(100% + 10px);

        &::before {
          ${arrowBase}
          top: 14px;
          right: -3px;
        }
      `
  }
}

const Container = styled.div<{ shouldGrow: boolean }>`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: ${({ shouldGrow }) => (shouldGrow ? 'flex-start' : 'center')};
  width: ${({ shouldGrow }) => (shouldGrow ? '100%' : 'fit-content')};
`

const Content = styled.div<{ position: Position; shouldGrow: boolean }>`
  display: none;
  position: absolute;
  padding: 8px 12px;
  font-family: 'Metric';
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  border-radius: 12px;
  background-color: ${colors.black900};
  width: max-content;
  color: ${colors.white};

  ${({ position, shouldGrow }) => placeTooltip(position, shouldGrow)}

  @media only screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 20px;
  }

  ${Container}:hover & {
    display: block;
  }
`

const ChildrenWrapper = styled.div<{ shouldGrow: boolean }>`
  flex-grow: ${({ shouldGrow }) => (shouldGrow ? 1 : 0)};
`

export default Tooltip
