import React, { FC } from 'react'
import { useIrishCheck } from '../../../../../i18n/useI18n'
import { ContactPerson, ContactPersonType } from '../../../../../types/ContactPerson'
import ContactPersonList from './ContactPersonList'

type Props = {
  contacts?: ContactPerson[]
  actions: {
    updatePerson: (updatedPerson: ContactPerson, originalPerson?: ContactPerson) => void
    changePriority: (chosenPerson: ContactPerson, priority: 'up' | 'down') => void
    deletePerson: (chosenPerson: ContactPerson) => void
  }
}

const personType = ContactPersonType.RESIDENT

const Residents: FC<Props> = ({ contacts = [], actions }) => {
  const { checkIrish } = useIrishCheck()

  const hasMobile = contacts.some((contact) => !!contact.Mobile)

  return (
    <ContactPersonList
      contacts={contacts}
      personType={personType}
      actions={actions}
      title={checkIrish('Residents', 'PW_RESIDENTS')}
      formTitle={checkIrish('resident contact', 'PW_RESIDENTS')}
      description={checkIrish(
        'We will contact them at any type of alarm such as fire, burglary, SOS, low battery etc.',
        'PW_RESIDENT_CONTACT_PERSON_ADD',
      )}
      maxContacts={4}
      maxContactsKey={checkIrish(
        'Max number of residents reached. Remove one to add another.',
        'YOU_CAN_ONLY_ADD_FOUR',
      )}
      requireMobile={!hasMobile}
    />
  )
}

export default Residents
