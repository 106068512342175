import React, { FC, useState } from 'react'
import Modal from '../components/Modal'
import useI18n from '../i18n/useI18n'

const closedKey = 'IE11_info_closed'

const BrowserNotSupported: FC = () => {
  const { t } = useI18n()
  const [show, setShow] = useState(!sessionStorage.getItem(closedKey))

  const handleClose = () => {
    sessionStorage.setItem(closedKey, 'true')
    setShow(false)
  }

  return (
    <>
      {show && (
        <Modal handleClose={handleClose}>
          <h1>{t('BROWSER_NOT_SUPPORTED_TITLE')}</h1>
          <p>{t('BROWSER_NOT_SUPPORTED_TEXT')}</p>
          <ul>
            <li>
              <a href="https://www.google.com/chrome/">Google Chrome</a>
            </li>
            <li>
              <a href="https://www.mozilla.org/firefox/download/">Mozilla Firefox</a>
            </li>
            <li>
              <a href="https://www.microsoft.com/edge">Microsoft Edge</a>
            </li>
          </ul>
        </Modal>
      )}
    </>
  )
}

export default BrowserNotSupported
