import {
  ACTIVATE_PANEL,
  ARM_ANNEX_SUCCESS,
  ARM_COMMAND_SUCCESS,
  DISARM_ANNEX_SUCCESS,
  DISARM_COMMAND_SUCCESS,
  LOAD_PANEL_INFO_SUCCESS,
  LOAD_PANEL_STATUS_SUCCESS,
  PARTIAL_ARM_COMMAND_SUCCESS,
  SET_PANEL_SETTINGS_SUCCESS,
  TOGGLE_ARC_CONSENT,
} from '../../reducers/panelInfoSlice'
import { RootState } from '../../store'
import {
  activatePanel,
  armAnnex,
  armPanel,
  disarmAnnex,
  disarmPanel,
  panelInfo,
  panelStatus,
  partialArmPanel,
} from '../apiPanel'
import { setArcVideoConsent, setPanelSettings } from '../settingsApi'
import { createUseRequest } from './createUseRequest'

const selectPanel = (state: RootState, panelId: string) => state.panel[panelId]?.Info
const selectStatus = (state: RootState, panelId: string) => state.panel[panelId]?.Status

export const useArmCommand = createUseRequest({
  apiCall: armPanel,
  successActionCreator: ARM_COMMAND_SUCCESS,
  requirePanelId: true,
})
export const usePartialArmCommand = createUseRequest({
  apiCall: partialArmPanel,
  successActionCreator: PARTIAL_ARM_COMMAND_SUCCESS,
  requirePanelId: true,
})
export const useDisarmCommand = createUseRequest({
  apiCall: disarmPanel,
  successActionCreator: DISARM_COMMAND_SUCCESS,
  requirePanelId: true,
})
export const useArmAnnex = createUseRequest({
  apiCall: armAnnex,
  successActionCreator: ARM_ANNEX_SUCCESS,
  requirePanelId: true,
})
export const useDisarmAnnex = createUseRequest({
  apiCall: disarmAnnex,
  successActionCreator: DISARM_ANNEX_SUCCESS,
  requirePanelId: true,
})

export const usePanelInfo = createUseRequest({
  successActionCreator: LOAD_PANEL_INFO_SUCCESS,
  apiCall: panelInfo,
  selector: selectPanel,
  requirePanelId: true,
})

export const usePanelStatus = createUseRequest({
  successActionCreator: LOAD_PANEL_STATUS_SUCCESS,
  apiCall: panelStatus,
  selector: selectStatus,
  requirePanelId: true,
})

export const useActivatePanel = createUseRequest({
  successActionCreator: ACTIVATE_PANEL,
  apiCall: activatePanel,
  requirePanelId: true,
})

export const useSetPanelSettings = createUseRequest({
  successActionCreator: SET_PANEL_SETTINGS_SUCCESS,
  apiCall: setPanelSettings,
  requirePanelId: true,
})

export const useSetArcConsent = createUseRequest({
  successActionCreator: TOGGLE_ARC_CONSENT,
  apiCall: setArcVideoConsent,
  requirePanelId: true,
})
