import { UPDATE_SYSTEM_LIST } from '../../reducers/systemListSlice'
import { RootState } from '../../store'
import { getAlarmSystems } from '../apiAccount'
import { createUseRequest } from './createUseRequest'

const selectSystemList = (state: RootState) => state.systemList.systems

export const useSystemList = createUseRequest({
  successActionCreator: UPDATE_SYSTEM_LIST,
  apiCall: getAlarmSystems,
  selector: selectSystemList,
})
