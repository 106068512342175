import { useCallback } from 'react'
import { useParams, generatePath } from 'react-router-dom'
import { OnboardingWizardStep } from './constants'

export const useWizardUrl = (
  urlList: Array<{
    step: OnboardingWizardStep
    url: string
    exact?: boolean
  }>,
  baseUrl: string,
  endPath: string,
  cancelPath: string,
) => {
  const params = useParams()

  const getUrl = useCallback(
    (step: OnboardingWizardStep, direction?: 'forward' | 'back') => {
      const currentIndex = urlList.findIndex((item) => item.step === step)
      let path = ''
      if (currentIndex !== -1) {
        if (direction === 'forward') {
          if (currentIndex < urlList.length - 1) {
            path = `${baseUrl}/${urlList[currentIndex + 1].url}`
          } else {
            path = endPath
          }
        }
        if (direction === 'back') {
          if (currentIndex > 0) {
            path = `${baseUrl}/${urlList[currentIndex - 1].url}`
          } else {
            path = cancelPath
          }
        }
        if (!direction) {
          path = `${baseUrl}/${urlList[currentIndex].url}`
        }
      }
      return generatePath(path, params)
    },
    [baseUrl, endPath, params, cancelPath, urlList],
  )

  return { getUrl }
}
