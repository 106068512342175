import React, { ChangeEventHandler, FC, useState } from 'react'
import { setPreferredLang } from '../i18n/i18n'
import { translations } from '../i18n/translations'
import useI18n from '../i18n/useI18n'
import { useAuth } from '../services/authentication/AuthContext'
import { UserCultureInfo } from '../types/UserDetails'
import Button from './Button'
import { PhonewatchLogo } from './PhonewatchLogo'
import { SectorLogo } from './SectorLogo'
import { brand } from '../configs'

const HeaderTemplate: FC = () => {
  const { t, i18n } = useI18n()
  const [language, setLanguage] = useState(i18n.language)
  const { isAuthenticated, logout } = useAuth()

  const onChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
    const newLanguage = event.target.value as UserCultureInfo
    setLanguage(newLanguage)
    setPreferredLang(newLanguage)
    i18n.changeLanguage(newLanguage)
  }

  return (
    <header className="navbar relative">
      <div className="navbar__container">
        {brand === 'phonewatch' ? <PhonewatchLogo></PhonewatchLogo> : <SectorLogo></SectorLogo>}
        {isAuthenticated ? (
          <Button size="small" buttonType="light" onClick={logout}>
            {t('Log out')}
          </Button>
        ) : (
          <div className="select select--transparent">
            <select
              value={language}
              onChange={onChange}
              onBlur={onChange}
              aria-label={t('Language')}
            >
              {Object.values(translations).map(({ culture, name }) => {
                return (
                  <option key={culture} value={culture} style={{ color: 'initial' }}>
                    {t(name)}
                  </option>
                )
              })}
            </select>
          </div>
        )}
      </div>
    </header>
  )
}

export default HeaderTemplate
