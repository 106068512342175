import React from 'react'
import classNames from 'classnames'

const ChevronRight = ({ className }: { className?: string }) => {
  return (
    <div className={classNames('chevron self-center', className)}>
      <div className="status-list__arrow status-list__arrow--forward" />
    </div>
  )
}

export default ChevronRight
