import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type SecurityQuestionState = {
  questionState: Record<string, { hasCodeWord: boolean }>
  lastPhoneDigits: string
}

const initialState: SecurityQuestionState = {
  questionState: {},
  lastPhoneDigits: '',
}

type CheckSecurityQuestionPayload = {
  hasCodeWord: boolean
}

type LastPhoneDigitsPayload = {
  lastPhoneDigits: string
}

const securityQuestionSlice = createSlice({
  name: 'securityQuestion',
  initialState,
  reducers: {
    CHECK_SECURITY_QUESTION(
      state,
      action: PayloadAction<{ data: CheckSecurityQuestionPayload; panelId: string }>,
    ) {
      const { data, panelId } = action.payload
      state.questionState[panelId] = data
    },
    GET_LAST_PHONE_DIGITS(state, action: PayloadAction<LastPhoneDigitsPayload>) {
      state.lastPhoneDigits = action.payload.lastPhoneDigits
    },
    SET_SECURITY_QUESTION(state, action: PayloadAction<{ panelId: string }>) {},
  },
})

// eslint-disable-next-line import/no-unused-modules
export const {
  CHECK_SECURITY_QUESTION,
  GET_LAST_PHONE_DIGITS,
  SET_SECURITY_QUESTION,
} = securityQuestionSlice.actions

export const securityQuestionReducer = securityQuestionSlice.reducer
