import BackgroundImagePage from '../../components/pages/BackgroundImagePage'
import { Redirect } from 'react-router-dom'
import useI18n from '../../i18n/useI18n'
import { useAuth } from '../../services/authentication/AuthContext'

const ForgotPassword = () => {
  const { isLoading, isAuthenticated, login } = useAuth()
  const { t } = useI18n()

  if (isLoading) {
    return null
  }

  if (isAuthenticated) {
    return <Redirect to="/" />
  }

  if (!isAuthenticated) {
    login()
  }

  return (
    <BackgroundImagePage>
      <div>
        <h1>{t('Welcome to a more secure home')} </h1>
      </div>
    </BackgroundImagePage>
  )
}

export default ForgotPassword
