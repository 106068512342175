import React, { FC } from 'react'
import * as yup from 'yup'
import { TranslationKey } from '../../../types/generated/TranslationKey'
import SimpleForm from '../../../components/forms/SimpleForm'

interface Props {
  onSubmit: (formValues: Record<keyof typeof resolver.fields, string>) => void
  isLoading: boolean
}

interface Field {
  name: keyof typeof resolver.fields
  label: TranslationKey
  type: 'text' | 'password'
  placeholder: TranslationKey
}

const fields: Field[] = [
  // TODO: Placeholder is "Code" in production but could not find a translation
  { name: 'password', label: 'Password', type: 'password', placeholder: 'Password' },
]

const resolver = yup.object({
  password: yup.string(),
})

const ExistingUserForm: FC<Props> = ({ onSubmit, isLoading, children }) => {
  return (
    <SimpleForm isLoading={isLoading} resolver={resolver} onSubmit={onSubmit} fields={fields}>
      {children}
    </SimpleForm>
  )
}

export default ExistingUserForm
