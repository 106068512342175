import './button.scss'
import React, { ComponentProps, FC } from 'react'
import cx from 'classnames'

type Props = ComponentProps<'button'> & {
  buttonType?: 'primary' | 'secondary' | 'third' | 'link' | 'light' | 'brand'
  size?: 'small' | 'xsmall' | 'large'
}

const Button: FC<Props> = ({ buttonType, children, disabled, className, size, ...rest }) => {
  return (
    <button
      className={cx(
        buttonType !== 'link' && 'button',
        buttonType && `button--${buttonType}`,
        size && `button--${size}`,
        className,
      )}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  )
}

export default Button
