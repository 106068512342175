import React, { useState } from 'react'
import Button from '../../../../components/Button'
import Modal from '../../../../components/Modal'
import { ModalPromptProps } from '../../../../components/Modal/Modal'
import useI18n from '../../../../i18n/useI18n'

const VideoControlModal = ({ show, onSubmit, onCancel }: ModalPromptProps<true>) => {
  const { t } = useI18n()
  const [hasAgreed, setHasAgreed] = useState(false)

  const handleSave = () => {
    onSubmit?.(true)
  }

  const handleCancel = () => {
    onCancel?.()
  }

  return (
    <>
      {show && (
        <Modal>
          <h1 className="text-2xl">{t('VIDEO_CONTROL_TITLE')}</h1>
          <p style={{ color: '#ae060e' }} className="font-bold mt-8">
            {t('VIDEO_CONTROL_SUBTITLE')}
          </p>
          <p className="mt-8">{t('VIDEO_CONTROL_DESCRIPTION')}</p>
          <label className="form">
            <div className="flex items-center">
              <input
                type="checkbox"
                className="w-4 h-4"
                checked={hasAgreed}
                onChange={(event) => {
                  setHasAgreed(event.target.checked)
                }}
              />
              {t('I have read and accept the Terms of Service')}
            </div>
          </label>
          <div>
            <Button buttonType="primary" disabled={!hasAgreed} onClick={handleSave}>
              {t('Save')}
            </Button>
            <Button buttonType="link" onClick={handleCancel}>
              {t('Cancel')}
            </Button>
          </div>
        </Modal>
      )}
    </>
  )
}

export default VideoControlModal
