import React, { FC } from 'react'

import { useAuth } from '../../../services/authentication/AuthContext'
import useI18n from '../../../i18n/useI18n'

const RegistrationFinished: FC = () => {
  const { isLoading, login } = useAuth()
  const { t } = useI18n()

  if (isLoading) {
    return null
  }

  return (
    <>
      <h1>{t('account_ready_header')}</h1>
      <button
        className="button button--third"
        onClick={() => {
          login('/')
        }}
      >
        {t('Go to login')}
      </button>
    </>
  )
}

export default RegistrationFinished
