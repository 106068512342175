import React, { useEffect, useState } from 'react'
import CustomerNrAndPhoneForm, { CustomerNrAndPhoneFormValues } from './CustomerNrAndPhoneForm'
import OneTimeCodeForm, { OneTimeCodeFormValues } from './OneTimeCodeForm'
import EmailAndPasswordForm, { EmailAndPasswordFormValues } from './EmailAndPasswordForm'
import SplitPage from '../../../components/pages/SplitPage'
import FormError from './FormError'
import useI18n from '../../../i18n/useI18n'
import { TranslationKey } from '../../../types/generated/TranslationKey'
import {
  useValidateCustomerPhone,
  useValidateNewCustomerSMSCode,
  useCreateAccount,
  useSendUserRegistrationEmail,
} from '../../../services/requestHooks/registration'
import Button from '../../../components/Button'
import { Loader } from '../../../components/Elements/Loaders'
import styled from 'styled-components'
import Toast, { addToast } from '../../../components/Crow/Toast/Toast'
import { Container } from './AccountRegistrationWizardStyle'
import { colors } from '../../../components/Crow/Style/theme'
import { Link } from 'react-router-dom'

const getHeadingText = (step: number): TranslationKey => {
  switch (step) {
    case 1:
      return 'You should have received an SMS'
    case 4:
      return 'registered'
    default:
      return 'Registrer your account'
  }
}

const AccountsRegistrationWizard = () => {
  const { t } = useI18n()
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [step, setStep] = useState<number>(0)
  const [isSas, setIsSas] = useState<boolean>()

  const [countryCode, setCountryCode] = useState<string | undefined>()
  const [customerNumber, setCustomerNumber] = useState<string | undefined>()
  const [email, setEmail] = useState<string | undefined>()
  const [accountName, setAccountName] = useState<string | undefined>()
  const [oneTimeCode, setOneTimeCode] = useState<string | undefined>()
  const [phone, setPhone] = useState<string | undefined>()

  const {
    run: validatePhone,
    error: phoneError,
    isLoading: isLoadingValidatePhone,
  } = useValidateCustomerPhone({
    onSuccess: ({ response }) => {
      if (!response?.countryCode) {
        setErrorMessage('Country code not found')
      } else {
        setIsSas(response.IsSas)
        setEmail(response.Email)
        setCountryCode(response.countryCode)
        setStep(1)
      }
    },
  })
  const {
    run: validateSMSCode,
    error: validationCodeError,
    isLoading: isValidationOTC,
  } = useValidateNewCustomerSMSCode({
    onSuccess: ({ response }) => {
      setAccountName(response.accountName)
      if (isSas) {
        setStep(3)
      } else {
        setStep(2)
      }
    },
    onFailure: ({ statusCode, message }: any) => {
      if (isSas && statusCode === 403 && message === 'customer_exists') {
        setStep(4)
      }
    },
  })

  const {
    run: createAccount,
    error: accountError,
    isLoading: isCreatingAccount,
  } = useCreateAccount({
    onSuccess: () => setStep(3),
  })

  const {
    run: sendUserRegistrationEmail,
    isLoading: isSendingUserRegistrationEmail,
  } = useSendUserRegistrationEmail({
    onFailure: () =>
      addToast({
        title: t('failed_to_resend_confimation_email'),
        type: 'error',
        time: 4000,
      }),
  })

  const handleSubmitCustomerNr = async (values: CustomerNrAndPhoneFormValues) => {
    setCustomerNumber(values.customerNumber)
    setPhone(values.mobileNumber)
    setErrorMessage(undefined)
    const { customerNumber, mobileNumber } = values
    validatePhone({
      CustomerNumber: customerNumber,
      phone: mobileNumber,
    })
  }

  const handleSubmitOneTimeCode = async ({ pinCode }: OneTimeCodeFormValues) => {
    setOneTimeCode(pinCode)
    setErrorMessage(undefined)
    if (!!customerNumber && !!countryCode) {
      validateSMSCode({
        oneTimeCode: pinCode,
        CustomerNumber: customerNumber,
        countryCode,
      })
    }
    return
  }

  const handleSubmitEmailAndPassword = async (values: EmailAndPasswordFormValues) => {
    setEmail(values.email)
    setErrorMessage(undefined)
    createAccount({
      AccountName: accountName!!,
      Email: values.email,
      Password: values.password,
      CustomerNumber: customerNumber!!,
      countryCode: countryCode!!,
      oneTimeCode: oneTimeCode!!,
      phone: phone!!,
    })
  }

  useEffect(() => {
    if (!accountError && !validationCodeError && !phoneError) return
    setErrorMessage(accountError || validationCodeError || phoneError || undefined)
  }, [accountError, validationCodeError, phoneError])

  const handleSendUserRegistationEmail = () => {
    sendUserRegistrationEmail({
      CustomerNumber: customerNumber!!,
      countryCode: countryCode!!,
      oneTimeCode: oneTimeCode!!,
    })
  }

  return (
    <StyledSplitPage contentPadding="40px" large heading={t(getHeadingText(step))}>
      {step === 0 && (
        <>
          <CustomerNrAndPhoneForm
            isLoading={isLoadingValidatePhone}
            onSubmit={handleSubmitCustomerNr}
          />
          <FormError errorMessage={errorMessage} />
        </>
      )}

      {step === 1 && (
        <>
          <OneTimeCodeForm isLoading={isValidationOTC} onSubmit={handleSubmitOneTimeCode} />
          <FormError errorMessage={errorMessage} />
        </>
      )}

      {step === 2 && (
        <>
          <StyledEmailAndPasswordForm
            isLoading={isCreatingAccount}
            onSubmit={handleSubmitEmailAndPassword}
          />
          <FormError errorMessage={errorMessage} />
        </>
      )}

      {step === 3 && (
        <>
          <Container>
            <p className="pl-8 pr-8 mb-5 flex ">
              {t('Almost done')}!
              <br />
              <>{t('A confirmation e-mail has been sendt to')}</>
              <br />
              {t('Please verify to start using you account.')}
            </p>

            <Button buttonType="primary" size="small" onClick={handleSendUserRegistationEmail}>
              {isSendingUserRegistrationEmail ? <Loader /> : <>{t('resend_confimation')}</>}
            </Button>
            <p className="mt-6">
              {t('or___')} click{' '}
              <Link to="/">
                <StyledLinkButton buttonType="link"> here </StyledLinkButton>
              </Link>{' '}
              {t('to_login')}
            </p>
          </Container>
          <Toast />
        </>
      )}

      {step === 4 && (
        <>
          <Container>
            <p className="pl-8 pr-8 mb-5">
              <span>{t('registered_account')}</span>
              <br />
              <>
                {email ? (
                  <span>
                    {t('login_using_email')} <strong> {email}. </strong>
                  </span>
                ) : (
                  <span>{t('login_already_registered')}</span>
                )}
              </>
            </p>
            <Link to="/">
              <Button buttonType="primary" size="small">
                Login
              </Button>
            </Link>
          </Container>
        </>
      )}
    </StyledSplitPage>
  )
}

const StyledSplitPage = styled(SplitPage)`
  height: 350px;
`

const StyledEmailAndPasswordForm = styled(EmailAndPasswordForm)`
  position: relative;
  top: -150px;
  padding-right: 10px;
  padding-left: 10px;
`

const StyledLinkButton = styled(Button)`
  color: ${colors.blue};
`

export default AccountsRegistrationWizard
