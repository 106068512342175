import React from 'react'
import BackgroundImagePage from '../../components/pages/BackgroundImagePage'
import useI18n from '../../i18n/useI18n'
import { useActivatePanel } from '../../services/requestHooks/panelInfo'
import { useOnMount } from '../../utils/commonHooks'
import { usePanelId } from './hooks/usePanelId'
import { useHistory } from 'react-router-dom'
import Button from '../../components/Button'

const ActivatePanelPage = () => {
  const { t } = useI18n()
  const history = useHistory()
  const panelId = usePanelId()
  const { run: activatePanel, isLoading, error } = useActivatePanel()

  useOnMount(() => {
    activatePanel({ PanelId: panelId })
  })

  return (
    <BackgroundImagePage
      isLoading={isLoading}
      loaderProps={{ loadingText: t('Activating system') }}
    >
      {!!error ? (
        <div>
          <p>
            {t(
              'The system is not ready for activation. Please go back and try again when the system i ready.',
            )}
          </p>
          <Button
            onClick={() => {
              history.replace('/systems')
            }}
            buttonType="primary"
          >
            {t('Go to alarm system list')}
          </Button>
        </div>
      ) : (
        <div>
          <h1>{t('Congratulations')}</h1>
          <p>{t('You are now ready to use your system.')}</p>
          <Button
            buttonType="primary"
            onClick={() => {
              history.replace(`/systems/${panelId}`)
            }}
          >
            {t('Go to you system')}
          </Button>
        </div>
      )}
    </BackgroundImagePage>
  )
}

export default ActivatePanelPage
