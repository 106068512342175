import { createSlice } from '@reduxjs/toolkit'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { ContactPerson } from '../types/ContactPerson'
import { usePanelId } from '../pages/AlarmSystems/hooks/usePanelId'
import { PanelPayloadAction } from '../services/requestHooks/PanelAction'

interface ContactsState {
  [panelId: string]:
    | {
        Contacts: ContactPerson[]
      }
    | undefined
}

const initialState: ContactsState = {}

type UpdateContactsPayload = ContactPerson[]

const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    UPDATE_CONTACTS(
      state,
      { payload: { panelId, data } }: PanelPayloadAction<UpdateContactsPayload>,
    ) {
      state[panelId] = { Contacts: data }
    },
  },
})

// eslint-disable-next-line import/no-unused-modules
export const { UPDATE_CONTACTS } = contactsSlice.actions

export const contactsReducer = contactsSlice.reducer

export const useUpdateContacts = () => {
  const dispatch = useDispatch()
  const panelId = usePanelId()
  return useCallback(
    (contacts: ContactPerson[]) => {
      dispatch(UPDATE_CONTACTS({ panelId, data: contacts }))
    },
    [dispatch, panelId],
  )
}
