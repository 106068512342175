import React from 'react'
import useI18n from '../../../../i18n/useI18n'

const SmartPlugNameForm = ({
  onChangeLabel,
  label,
}: {
  onChangeLabel: (label: string) => void
  label: string
}) => {
  const { t } = useI18n()
  return (
    <form>
      <label className="text-xl mt-4 form">
        {t('SMART_PLUG_NAME_HELPTEXT')}
        <input
          onChange={(e) => onChangeLabel(e.target.value)}
          value={label}
          className="form__input mt-2"
          type="text"
          placeholder={t('SMART_PLUG_NAME_PLACEHOLDER')}
        />
      </label>
    </form>
  )
}

export default SmartPlugNameForm
