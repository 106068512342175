import React, { useContext, FC } from 'react'
import SettingsSubPage from '../components/SettingsSubPage'
import { TwofactorContext } from '../../../../components/TwoFactorPrompt/TwoFactorPrompt'
import { useOnMount } from '../../../../utils/commonHooks'
import { usePanelId } from '../../hooks/usePanelId'
import PasswordPrompt, { usePasswordPrompt } from '../../Panel/PasswordPromt'
import history from '../../../../utils/history'
import DirectionsForm, { DirectionsFormValues } from './DirectionsForm'
import PageHeader from '../../../../components/PageHeader'
import { useGetDirections, useSetDirections } from '../../../../services/requestHooks/directions'
import useI18n, { useIrishCheck } from '../../../../i18n/useI18n'

export const DirectionsHeader: FC = () => {
  const { checkIrish } = useIrishCheck()

  return (
    <PageHeader
      title="Location information"
      text={checkIrish(
        'Having the following information makes it easier to assist in case of an alarm. Remember to add information such as main entrance code and key box code and place.',
        'PW_LOCATION_INFO',
      )}
    />
  )
}

const DirectionsScreen: FC = () => {
  const { t } = useI18n()
  const panelId = usePanelId()
  const { promptForTwoFactor } = useContext(TwofactorContext)
  const { promptForPassword, promptState } = usePasswordPrompt()
  const returnUrl = '/systems/' + panelId + '/settings'

  const {
    run: getDirections,
    data: directions,
    error: errorFetching,
    isLoading: fetchLoading,
  } = useGetDirections()
  const { run: saveDirections, error: errorSaving, isLoading: saveLoading } = useSetDirections({
    onSuccess: () => {
      history.push('/systems/' + panelId + '/settings')
    },
  })

  useOnMount(() => {
    promptForTwoFactor(returnUrl).then((code) => {
      if (!code) return
      getDirections({ PanelId: panelId, ValidationCode: code })
    })
  })

  const onSubmit = async (values: DirectionsFormValues) => {
    const { directions = '' } = values
    const validationCode = await promptForTwoFactor()
    if (validationCode) {
      const password = await promptForPassword()
      if (password) {
        let payload = {
          PanelId: panelId,
          ValidationCode: validationCode,
          Directions: directions,
          Password: password,
        }
        saveDirections(payload)
      }
    }
  }

  return (
    <>
      <SettingsSubPage
        isLoading={fetchLoading}
        loaderProps={{
          loadingText: { text: 'Loading' },
          errorHandling: {
            loadingFailed: !!errorFetching,
            errorHeaderText: 'Location information',
            errorText:
              'Could not load location information, please try again or contact customer service.',
            returnUrl,
          },
        }}
      >
        <DirectionsHeader />
        <br />
        <DirectionsForm
          onSubmit={onSubmit}
          defaultValues={directions !== undefined ? { directions } : undefined}
          isLoading={saveLoading}
          cancelUrl={returnUrl}
          required
        />
        {errorSaving && (
          <div className="notification notification--error">
            {t('Something went wrong. Please try again')}
          </div>
        )}
        <br />
      </SettingsSubPage>
      <PasswordPrompt promptState={promptState} />
    </>
  )
}
export default DirectionsScreen
