import './contactperson-list.scss'
import React, { FC, useMemo } from 'react'
import { TranslationKey } from '../../../../../types/generated/TranslationKey'
import useI18n from '../../../../../i18n/useI18n'
import { ContactPerson, ContactPersonType } from '../../../../../types/ContactPerson'
import ContactFormPrompt, { useContactFormPrompt } from './ContactFormPrompt'
import ContactPersonListItem from './ContactPersonListItem'
import AddButton from '../../../../../components/Button/AddButton'

type Props = {
  contacts: ContactPerson[]
  personType: ContactPersonType
  title: TranslationKey
  formTitle: TranslationKey
  description: TranslationKey
  maxContacts: number
  maxContactsKey: TranslationKey
  actions: {
    updatePerson: (updatedPerson: ContactPerson, originalPerson?: ContactPerson) => void
    changePriority: (chosenPerson: ContactPerson, priority: 'up' | 'down') => void
    deletePerson: (chosenPerson: ContactPerson) => void
  }
  requireMobile?: boolean
}

const ContactPersonList: FC<Props> = ({
  title,
  formTitle,
  description,
  maxContacts,
  maxContactsKey,
  contacts,
  personType,
  actions: { updatePerson, changePriority, deletePerson },
  requireMobile = false,
}) => {
  const { t } = useI18n()
  const people = useMemo(() => contacts.filter((contact) => contact.Type === personType), [
    contacts,
    personType,
  ])

  const { promptState, promptForContact } = useContactFormPrompt()

  const onClick = async (existingPerson?: ContactPerson) => {
    const updatedPerson = await promptForContact(existingPerson)
    if (updatedPerson) {
      updatePerson({ ...updatedPerson, Type: personType }, existingPerson)
    }
  }

  return (
    <div className="mt-12">
      <h2 className="mb-2">{t(title)}</h2>
      <p>{t(description)}</p>
      {requireMobile && <p>{t('MOBILE_REQUIRED')}</p>}
      <hr />
      <ul className="pl-0">
        {people.map((person, index) => {
          return (
            <ContactPersonListItem
              key={person.FirstName + person.LastName + person.Type}
              person={person}
              onClick={onClick}
              changePriority={changePriority}
              deletePerson={deletePerson}
              isFirst={index === 0}
              isLast={index === people.length - 1}
            />
          )
        })}
      </ul>
      {people.length < maxContacts ? (
        <AddButton
          onClick={() => {
            onClick()
          }}
        >
          {t('Add')}
        </AddButton>
      ) : (
        <p className="notification notification--error">{t(maxContactsKey)}</p>
      )}
      <ContactFormPrompt title={t(formTitle)} {...promptState} />
    </div>
  )
}

export default ContactPersonList
