import './pages.scss'
import React, { FC, ReactNode } from 'react'
import HeaderTemplate from '../HeaderTemplate'
import DefaultFooter from '../Footer'
import LoadPage from '../LoadPage'
import { LoaderProps } from '../Elements/Loaders'

type Props = {
  isLoading?: boolean
  loaderProps?: LoaderProps
  footer?: ReactNode
}

const BackgroundImagePage: FC<Props> = ({
  children,
  isLoading,
  loaderProps,
  footer = <DefaultFooter />,
}) => {
  return (
    <div className="flex-1 background-container">
      <div className="background-image" />
      <div className="background-overlay">
        <HeaderTemplate />
        <main className="flex flex-1 justify-center items-center">
          <div className="background-image__content">
            <LoadPage isLoading={isLoading} {...loaderProps}>
              {children}
            </LoadPage>
          </div>
        </main>
        {footer}
      </div>
    </div>
  )
}

export default BackgroundImagePage
