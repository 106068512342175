import React, { ComponentProps, FC } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import ChevronRight from './ChevronRight'

interface Props {
  className?: string
}

const Card: FC<Props> = ({ children, className }) => {
  return <div className={classNames('paper', className)}>{children}</div>
}

const CardLink: FC<Props & ComponentProps<Link>> = ({ children, className, ...rest }) => {
  return (
    <Link className={classNames('paper paper--link no-underline', className)} {...rest}>
      {children}
      <ChevronRight className="paper__chevron" />
    </Link>
  )
}

const CardButton: FC<Props & ComponentProps<'button'>> = ({ children, className, ...rest }) => {
  return (
    <button className={classNames('paper paper--button', className)} {...rest}>
      {children}
      <ChevronRight className="paper__chevron" />
    </button>
  )
}

// eslint-disable-next-line import/no-unused-modules
export { Card, CardLink, CardButton }
