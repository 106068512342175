import './build-tag.scss'
import React, { FC } from 'react'
import ReactDOM from 'react-dom'
import { getPortalContainer } from '../../utils/getContainer'

const BuildTag: FC = () => {
  const environment = process.env.REACT_APP_BUILDVERSION
  return ReactDOM.createPortal(<div id="build-tag">{environment}</div>, getPortalContainer())
}

export default BuildTag
