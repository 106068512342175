export interface DoorsAndWindows {
  Floors: Floor[]
}

export interface Floor {
  Name: string
  Key: string
  Rooms: {
    Name: string
    Key: string
    Index?: number
    Devices: DevicesType[]
  }[]
}

export type DevicesType = {
  Id: string | null
  Name: string | null
  Type: DeviceKind
  Closed: boolean
  LowBattery: boolean
  Alarm: boolean
  Serial: string
  LastCharged: Date | null
  MountType: MountType
  MountIndex: number
}

enum DeviceKind {
  UNKNOWN = 0,
  MAGNET = 1,
  LOCK = 2,
}

export enum MountType {
  DOOR = 0,
  WINDOW = 1,
}

export const isDoorOrWindow = (device: DevicesType) => {
  return device.MountType === MountType.DOOR || device.MountType === MountType.WINDOW
}
