import React, { FC, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { getPreferredLang } from '../i18n/i18n'
import { getCultureDataFromUrl, translations } from '../i18n/translations'
import useI18n from '../i18n/useI18n'
import { useGetUserInfo, useUserInfo } from '../services/requestHooks/user'
import { UserCultureInfo } from '../types/UserDetails'

const UnAuthenticatedTermsLink: FC = ({ children }) => {
  const { i18n } = useI18n()
  const { nation, culture } = useMemo(() => {
    const preferredLang = getPreferredLang()
    const { nation } = getCultureDataFromUrl() ||
      (preferredLang && translations[preferredLang]) || { nation: -1 }
    const culture = i18n.language as UserCultureInfo
    return {
      nation,
      culture,
    }
  }, [i18n.language])
  return <Link to={`/user/terms/${nation}/${culture}`}>{children}</Link>
}

const AuthenticatedTermsLink: FC = ({ children }) => {
  const { NationId: nation, UserCultureInfo: culture } = useUserInfo()

  return <Link to={`/user/terms/${nation}/${culture}`}>{children}</Link>
}

const TermsLink: FC = ({ children }) => {
  const { hasLoaded } = useGetUserInfo()
  if (hasLoaded) {
    return <AuthenticatedTermsLink>{children}</AuthenticatedTermsLink>
  }
  return <UnAuthenticatedTermsLink>{children}</UnAuthenticatedTermsLink>
}

export default TermsLink
