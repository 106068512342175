import React, { ComponentType, FC, useState } from 'react'
import { useOnMount } from '../../utils/commonHooks'
import { getImpersonationId, setGetToken, setImpersonationId } from './AuthContext'
import { IMPERSONATE_KEY } from './constants'

const Auth0Provider = React.lazy(() => import('./Auth0Provider'))
const ActiveDirectoryProvider = React.lazy(() => import('./ActiveDirectoryProvider'))

const AuthProvider: FC = ({ children }) => {
  const [Provider, setProvider] = useState<ComponentType>()

  useOnMount(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const impersonationId = urlParams.get(IMPERSONATE_KEY) || getImpersonationId()
    if (!!impersonationId) {
      setImpersonationId(impersonationId)
      import('./ActiveDirectoryProvider').then(({ getActiveDirectoryToken }) => {
        setGetToken(getActiveDirectoryToken)
        setProvider(ActiveDirectoryProvider)
      })
    } else {
      import('./Auth0Provider').then(({ getAuth0Token }) => {
        setGetToken(getAuth0Token)
        setProvider(Auth0Provider)
      })
    }
  })

  if (!Provider) {
    return null
  }

  return <Provider>{children}</Provider>
}

export default AuthProvider
