import React, { useState } from 'react'
import useI18n from '../i18n/useI18n'
import { TranslationKey } from '../types/generated/TranslationKey'

interface Props {
  title: TranslationKey
  text: TranslationKey
  className?: string
}

const ExpandableHelpTag = ({ title, text, className }: Props) => {
  const { t } = useI18n()
  const [isShowing, setIsShowing] = useState(false)

  return (
    <div className={className}>
      <button
        type="button"
        onClick={() => setIsShowing(!isShowing)}
        className="help__icon marginTop10px text-2xl"
      >
        ?
      </button>
      {isShowing && (
        <div className="help__text ng-animate ng-hide ng-hide-remove">
          <div className="help__text-title">{t(title)}</div>
          <button
            type="button"
            onClick={(event) => {
              event.preventDefault()
              setIsShowing(!isShowing)
            }}
            className="help__close"
          >
            x
          </button>
          {t(text)}
        </div>
      )}
    </div>
  )
}

export default ExpandableHelpTag
