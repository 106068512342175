import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type RequestState = Record<
  string,
  { isLoading: boolean; error: string | undefined; hasLoaded: boolean }
>

const initialState: RequestState = {}

interface DefaultPayload {
  requestType: string
}

interface ErrorPayload extends DefaultPayload {
  error: string
}

const requestSlice = createSlice({
  name: 'requests',
  initialState,
  reducers: {
    REQUEST_INIT(state, action: PayloadAction<DefaultPayload>) {
      const {
        payload: { requestType },
      } = action
      state[requestType] = state[requestType] || {}
      state[requestType] = {
        isLoading: true,
        error: undefined,
        hasLoaded: false,
      }
    },
    REQUEST_SUCCESS(state, action: PayloadAction<DefaultPayload>) {
      const {
        payload: { requestType },
      } = action
      const requestState = state[requestType]
      if (requestState) {
        requestState.isLoading = false
        requestState.hasLoaded = true
      }
    },
    REQUEST_ERROR(state, action: PayloadAction<ErrorPayload>) {
      const {
        payload: { requestType, error },
      } = action
      const loadingState = state[requestType]
      if (loadingState) {
        loadingState.isLoading = false
        loadingState.error = error
      }
    },
    REQUEST_ERROR_RESET(state) {
      for (const key in state) {
        state[key] = {
          ...state[key],
          error: undefined,
        }
      }
    },
  },
})

// eslint-disable-next-line import/no-unused-modules
export const {
  REQUEST_INIT,
  REQUEST_SUCCESS,
  REQUEST_ERROR,
  REQUEST_ERROR_RESET,
} = requestSlice.actions

export const requestReducer = requestSlice.reducer
