import React, { FC } from 'react'
import RequestLoader, { LoaderProps } from './Elements/Loaders'

interface Props {
  isLoading?: boolean
  loaderProps?: LoaderProps
  className?: string
}

const LoadPage: FC<Props> = ({ children, isLoading, loaderProps, className }) => {
  if (isLoading || loaderProps?.errorHandling?.loadingFailed) {
    return (
      <RequestLoader
        className={className}
        {...loaderProps}
        types={loaderProps?.types || ['large', 'fly-in']}
      />
    )
  }
  return <>{children}</>
}

export default LoadPage
