import React, { ComponentType, ForwardRefExoticComponent, ReactNode, RefAttributes } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { AnyObjectSchema } from 'yup'
import Button from '../Button'
import { TranslationKey } from '../../types/generated/TranslationKey'
import useI18n from '../../i18n/useI18n'
import TextField from './TextField'
import cx from 'classnames'
import CancelButton from '../Button/CancelButton'

export type Field<NameOptions> =
  | {
      name: NameOptions
      label: TranslationKey | ComponentType
      type: 'text' | 'password' | 'checkbox' | 'email'
      placeholder: TranslationKey
      Component?: never
    }
  | {
      name: NameOptions
      Component: ForwardRefExoticComponent<RefAttributes<HTMLInputElement> & { name: string }>
      label?: never
      type?: never
      placeholder?: never
    }

interface Props<
  NameOptions extends string,
  FormValues extends Record<NameOptions, string | number | boolean>
> {
  resolver: AnyObjectSchema
  onSubmit: SubmitHandler<FormValues>
  fields: Field<NameOptions>[]
  title?: TranslationKey
  className?: string
  children?: ReactNode
  isLoading: boolean
}

const SimpleForm = <
  NameOptions extends string,
  FormValues extends Record<NameOptions, string | number | boolean>
>({
  resolver,
  onSubmit,
  fields,
  title,
  className,
  children,
  isLoading,
}: Props<NameOptions, FormValues>) => {
  const { t } = useI18n()
  const {
    register,
    formState: { isValid, errors },
    handleSubmit,
  } = useForm({
    reValidateMode: 'onChange',
    mode: 'all',
    resolver: yupResolver(resolver),
  })

  return (
    <form className={`${cx(className)} mx-4`} onSubmit={handleSubmit(onSubmit)} data-testid="form">
      {title && <h1 className="mb-6 text-2xl">{t(title)}</h1>}
      {fields.map((field) =>
        field.Component ? (
          <field.Component key={field.name} {...register(field.name)} />
        ) : (
          <TextField
            key={field.name}
            {...register(field.name)}
            errorKey={errors[field.name]?.message}
            name={field.name}
            label={field.label}
            type={field.type}
            placeholder={field.placeholder}
          />
        ),
      )}
      {children}
      <Button buttonType="primary" disabled={!isValid || isLoading}>
        {t('Next')}
      </Button>
      <CancelButton cancelUrl="/" isLoading={isLoading} />
    </form>
  )
}

export default SimpleForm
