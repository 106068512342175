import styled from 'styled-components'
import { colors } from '../../../components/Crow/Style/theme'
import { SubTitle } from '../../../components/Crow/Typography'

const StyledSubtitle = styled(SubTitle)`
  margin: 0;
  padding-bottom: 10px;
  padding-left: 10px;
`

const StyledUl = styled.ul`
  padding: 0;
  margin-top: 40px;
`

const GridSmokeDetector = styled.ul`
  max-width: 1200px;
  display: grid;
  grid-gap: 2rem;
  justify-items: center;

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 425px) and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 380px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`

const IconContainer = styled.div<{ bgColor?: string }>`
  border-radius: 8px;
  background-color: ${({ bgColor }) => (bgColor ? bgColor : colors.black100)};
  padding: 10px;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
`

// eslint-disable-next-line import/no-unused-modules
export { StyledSubtitle, StyledUl, GridSmokeDetector, FlexColumn, IconContainer }
