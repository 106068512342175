import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Invoice, InvoicesResponse } from '../types/Invoices'

interface InvoiceState {
  Invoices: Invoice[]
  InvoicesYears: string[]
}

const initialState: InvoiceState = {
  Invoices: [],
  InvoicesYears: [],
}

const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    LOAD_INVOICES_SUCCESS(state: InvoiceState, { payload }: PayloadAction<InvoicesResponse>) {
      const years = Array.from(
        payload.reduce((yearSet, invoice) => yearSet.add(invoice.year), new Set<string>()),
      )
      return {
        ...state,
        Invoices: payload,
        InvoicesYears: years,
      }
    },
    LOAD_INVOICE_PDF_SUCCESS() {},
  },
})

// eslint-disable-next-line import/no-unused-modules
export const { LOAD_INVOICES_SUCCESS, LOAD_INVOICE_PDF_SUCCESS } = invoiceSlice.actions

export default invoiceSlice.reducer
