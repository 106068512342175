import { utcToZonedTime } from 'date-fns-tz'
import { format, formatRelative, isAfter, subDays, subYears } from 'date-fns'

const convertAndFormatTime = (
  timeZoneName: string | undefined,
  locale: Locale,
  utcTime: string | undefined,
  relativeTime?: boolean,
  fallbackTime?: string | undefined,
  fallbackConditional: boolean = true,
) => {
  if (fallbackConditional && utcTime && timeZoneName) {
    const today = new Date()
    const utcDateObject = new Date(utcTime)
    const zonedDate = utcToZonedTime(utcDateObject, timeZoneName)
    if (!relativeTime) {
      return format(zonedDate, 'p', {
        locale,
      })
    }
    const isTooOld = isAfter(subYears(today, 30), zonedDate)
    if (isTooOld) {
      return ''
    }
    const isLessThanAWeekOld = isAfter(zonedDate, subDays(today, 7))
    if (isLessThanAWeekOld) {
      return formatRelative(zonedDate, today, {
        locale,
      })
    }
    return format(zonedDate, 'Pp', {
      locale,
    })
  }
  if (fallbackTime) {
    return format(new Date(fallbackTime), 'Pp', {
      locale,
    })
  } else if (utcTime) {
    return format(new Date(utcTime), 'p', {
      locale,
    })
  } else {
    return ''
  }
}

export default convertAndFormatTime
