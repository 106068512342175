import React, { useState } from 'react'
import { useOnMount } from '../utils/commonHooks'
import FullscreenPage from './pages/FullscreenPage'
import useI18n from '../i18n/useI18n'
import { TranslationKey } from '../types/generated/TranslationKey'
import { useHistory } from 'react-router-dom'

interface Props {
  text: TranslationKey
  customTime?: number
  returnUrl?: string
}

const FullScreenLoader = ({ text, customTime, returnUrl }: Props) => {
  const { t } = useI18n()
  const [count, setCount] = useState(customTime ? customTime : 60)
  const history = useHistory()

  useOnMount(() => {
    let aborted = false
    const decrementCount = (count: number) => {
      if (aborted) return
      if (count === 0) return
      setCount(count - 1)
      setTimeout(() => decrementCount(count - 1), 1000)
    }

    setTimeout(() => decrementCount(count), 1000)
    return () => {
      if (returnUrl) {
        history.push(returnUrl)
      }
      aborted = true
    }
  })

  return (
    <FullscreenPage returnURL={returnUrl}>
      <div>
        <h1 className="center">{t('This process may take some time')}</h1>
        <div className="center margin-top">
          <div className="loader loader--large">
            <div className="circle__textGrey">{count}</div>
          </div>
          <div className="center">{t(text)}</div>
        </div>
      </div>
    </FullscreenPage>
  )
}

export default FullScreenLoader
