import { CREATE_SMS_CODE, VALIDATE_SMS_CODE } from '../../reducers/validateSMSCodeSlice'
import { createSMSCode, validateCode } from '../apiAccount'
import { createUseRequest } from './createUseRequest'

export const useCreateSMSCode = createUseRequest({
  successActionCreator: CREATE_SMS_CODE,
  apiCall: createSMSCode,
})

export const useValidateSMSCode = createUseRequest({
  successActionCreator: VALIDATE_SMS_CODE,
  apiCall: validateCode,
})
