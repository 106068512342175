import React, { FC, useState } from 'react'
import { HomeIcon } from '../../../assets/icons'
import useI18n from '../../../i18n/useI18n'
import Modal from '../../../components/Modal'

export type ArmMode = 'full' | 'partial'

type Props = {
  onSubmit?: (armMode: ArmMode) => void
  onCancel?: () => void
  show: boolean
}

const ArmModeModal: FC<Props> = ({ onSubmit, onCancel, show }) => {
  const { t } = useI18n()

  return (
    <>
      {show && (
        <Modal fullscreen handleClose={() => onCancel?.()}>
          <h1 className="center mb-8">{t('Choose alarm mode')}</h1>
          <div className="flex justify-center">
            <button
              type="button"
              onClick={() => onSubmit?.('full')}
              className="is-clickable container--horizontal-center m-6"
            >
              <div className="system__icon system__icon--armed system__icon--select">
                <HomeIcon />
              </div>
              <div className="button button--third button--arrow-up">
                <span> {t('Fully armed')}</span>
              </div>
              <p className="mt-4">{t("Use this when no-one's home")}</p>
            </button>
            <button
              id="alarmShield"
              type="button"
              onClick={() => onSubmit?.('partial')}
              className="is-clickable container--horizontal-center m-6"
            >
              <div className="system__icon system__icon--shell-armed system__icon--select">
                <HomeIcon />
              </div>
              <div className="button button--third button--arrow-up">
                <span>{t('Home mode')}</span>
              </div>
              <p className="mt-4">
                {t('Disables motion detectors so you can move around indoors')}
              </p>
            </button>
          </div>
        </Modal>
      )}
    </>
  )
}

export const useArmModeModal = () => {
  const [modalState, setModalState] = useState<{
    show: boolean
    onSubmit?: (armMode: ArmMode) => void
    onCancel?: () => void
  }>({ show: false })

  const promptForArmMode = () => {
    return new Promise<ArmMode | undefined>((resolve) => {
      setModalState({
        show: true,
        onSubmit: (armMode: ArmMode) => {
          setModalState({ show: false })
          resolve(armMode)
        },
        onCancel: () => {
          setModalState({ show: false })
          resolve(undefined)
        },
      })
    })
  }
  return {
    promptForArmMode,
    modalState,
  }
}

export default ArmModeModal
