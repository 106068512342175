import { useParams } from 'react-router-dom'

export const usePanelId = (optional?: boolean) => {
  const { panelId } = useParams<{ panelId: string | undefined }>()

  if (panelId === undefined) {
    if (optional) {
      return ''
    }
    throw new Error('Cannot find panelId')
  }
  return panelId
}
