import './radio-button-field.scss'

import React, {
  ChangeEventHandler,
  ElementRef,
  FC,
  FocusEventHandler,
  forwardRef,
  ForwardRefRenderFunction,
  ReactNode,
} from 'react'
import classNames from 'classnames'

type FieldProps = {
  legend: ReactNode
}

const RadioButtonField: FC<FieldProps> = ({ legend, children }) => {
  return (
    <fieldset className="radio-button-field">
      <legend>{legend}</legend>
      <div className="radio-button-field__items">{children}</div>
    </fieldset>
  )
}

type RadioProps = {
  name: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
  isSelected: boolean
  value: string | number
  children?: ReactNode
}

const Radio: ForwardRefRenderFunction<ElementRef<'input'>, RadioProps> = (
  { name, onChange, onBlur, children, isSelected, value },
  ref,
) => {
  return (
    <label
      className={classNames(
        'radio-button',
        'button',
        isSelected ? 'button--primary' : 'button--secondary',
      )}
    >
      <div>{children}</div>
      <input ref={ref} name={name} onChange={onChange} onBlur={onBlur} type="radio" value={value} />
    </label>
  )
}
export const RadioButton = forwardRef(Radio)

export default RadioButtonField
