import {
  LOCK_COMMAND_SUCCESS,
  UNLOCK_COMMAND_SUCCESS,
  GET_LOCK_STATUS_SUCCESS,
  SET_LOCK_SETTINGS_SUCCESS_CROW,
} from '../../reducers/locksReducer'
import { RootState } from '../../store'
import { lock, unlock, getLockStatus } from '../apiPanel'
import { setLockSettingsCrow } from '../settingsApi'
import { createUseRequest } from './createUseRequest'

export const useLock = createUseRequest({
  apiCall: lock,
  successActionCreator: LOCK_COMMAND_SUCCESS,
  requirePanelId: true,
})

export const useUnlock = createUseRequest({
  apiCall: unlock,
  successActionCreator: UNLOCK_COMMAND_SUCCESS,
  requirePanelId: true,
})

export const useGetLockStatus = createUseRequest({
  apiCall: getLockStatus,
  successActionCreator: GET_LOCK_STATUS_SUCCESS,
  selector: (state: RootState, panelId: string) => state.locks[panelId]?.Locks,
  requirePanelId: true,
})

export const useSetLockSettingsCrow = createUseRequest({
  apiCall: setLockSettingsCrow,
  successActionCreator: SET_LOCK_SETTINGS_SUCCESS_CROW,
  requirePanelId: true,
})
