import React, { FC } from 'react'
import * as yup from 'yup'
import { TranslationKey } from '../../../types/generated/TranslationKey'
import SimpleForm from '../../../components/forms/SimpleForm'
import { passwordConfirmValidator, passwordValidator } from '../../../components/forms/validators'

export type NewUserFormValues = Record<keyof typeof resolver.fields, string>

interface Props {
  onSubmit: (formValues: NewUserFormValues) => void
  isLoading: boolean
}

interface Field {
  name: keyof typeof resolver.fields
  label: TranslationKey
  type: 'text' | 'password'
  placeholder: TranslationKey
}

const fields: Field[] = [
  { name: 'firstName', label: 'First name', type: 'text', placeholder: 'First name' },
  { name: 'lastName', label: 'Last name', type: 'text', placeholder: 'Last name' },
  // TODO: Placeholder is "Code" in production but could not find a translation
  { name: 'password', label: 'Password', type: 'password', placeholder: 'Password' },
  {
    name: 'passwordRepeat',
    label: 'Repeat password',
    type: 'password',
    placeholder: 'Repeat password',
  },
]

const resolver = yup.object({
  firstName: yup.string().min(2, 'Contact person name must be at least 2 characters'),
  lastName: yup.string().min(2, 'Contact person name must be at least 2 characters'),
  password: passwordValidator.required('Required'),
  passwordRepeat: passwordConfirmValidator.required('Required'),
})

const NewUserForm: FC<Props> = ({ onSubmit, isLoading, children }) => {
  return (
    <SimpleForm
      isLoading={isLoading}
      className={'box-content'}
      resolver={resolver}
      onSubmit={onSubmit}
      fields={fields}
      title={'ACCEPT'}
    >
      {children}
    </SimpleForm>
  )
}

export default NewUserForm
