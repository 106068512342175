import './expandable-section.scss'

import React, { createContext, FC, MouseEventHandler, useContext, useState } from 'react'
import cx from 'classnames'

type ExpandableSectionContextType = {
  open: boolean
  toggleOpen: MouseEventHandler
  hideIndicator: boolean
}

const ExpandableSectionContext = createContext<ExpandableSectionContextType>({
  open: false,
  hideIndicator: false,
  toggleOpen: () => {},
})

type SectionProps = {
  open?: boolean
  hideIndicator?: boolean
  onClick?: MouseEventHandler
  className?: string
}

const ExpandableSection: FC<SectionProps> = ({
  className,
  open: controlledOpen,
  onClick,
  children,
  hideIndicator = false,
}) => {
  const [fallBackOpen, setFallbackOpen] = useState(false)

  const contextValue: ExpandableSectionContextType = !!onClick
    ? {
        open: !!controlledOpen,
        toggleOpen: onClick,
        hideIndicator,
      }
    : {
        open: fallBackOpen,
        toggleOpen: () => {
          setFallbackOpen((prevValue) => !prevValue)
        },
        hideIndicator,
      }
  return (
    <details open={contextValue.open} className={cx('expandable-section', className)}>
      <ExpandableSectionContext.Provider value={contextValue}>
        {children}
      </ExpandableSectionContext.Provider>
    </details>
  )
}

type SummaryProps = {
  className?: string
}

export const ExpandableSectionSummary: FC<SummaryProps> = ({ className, children }) => {
  const { open, toggleOpen, hideIndicator } = useContext(ExpandableSectionContext)
  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault()
    toggleOpen(event)
  }

  return (
    <summary onClick={handleClick} className={cx('expandable-section__summary', className)}>
      <div className="flex justify-between flex-auto items-center">
        {children}
        {!hideIndicator && (
          <span
            className={cx(
              'expandable-section__indicator',
              open && 'expandable-section__indicator--active',
            )}
          />
        )}
      </div>
    </summary>
  )
}

export default ExpandableSection
