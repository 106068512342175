import { createAction } from '@reduxjs/toolkit'
import { createSMSCode, validateCode } from '../services/apiAccount'
import { ThenArgs } from '../types/ThenArgs'

// eslint-disable-next-line import/no-unused-modules
export const VALIDATE_SMS_CODE = createAction<ThenArgs<ReturnType<typeof validateCode>>>(
  'VALIDATE_SMS_CODE',
)

export const CREATE_SMS_CODE = createAction<ThenArgs<ReturnType<typeof createSMSCode>>>(
  'VALIDATE_SMS_CODE',
)
