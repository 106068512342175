import { POST_TEMPERATURES, UPDATE_TEMPERATURES } from '../../reducers/temperatureListSlice'
import { RootState } from '../../store'
import { postTemperatures } from '../apiHouseCheck'
import { panelTemperature } from '../apiPanel'
import { createUseRequest } from './createUseRequest'

const selectTemperature = (state: RootState, panelId: string) =>
  state.temperatureList[panelId]?.temperatures

// /panel/gettemperatures
export const useTemperature = createUseRequest({
  successActionCreator: UPDATE_TEMPERATURES,
  apiCall: panelTemperature,
  selector: selectTemperature,
  requirePanelId: true,
})

const selectHousecheckTemperature = (state: RootState, panelId: string) =>
  state.temperatureHousecheckReducer[panelId]?.temperatures

export const usePostTemperatures = createUseRequest({
  successActionCreator: POST_TEMPERATURES,
  apiCall: postTemperatures,
  selector: selectHousecheckTemperature,
  requirePanelId: true,
})
