import { LOAD_ORDER_SUCCESS } from '../../reducers/orderSlice'
import { RootState } from '../../store'
import { getPanelOrder } from '../apiPanel'
import { createUseRequest } from './createUseRequest'

export const useLoadPanelOrder = createUseRequest({
  successActionCreator: LOAD_ORDER_SUCCESS,
  apiCall: getPanelOrder,
  selector: (state: RootState) => state.order.order,
})
