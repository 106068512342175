import React, { FC } from 'react'
import useI18n from '../i18n/useI18n'
import { TranslationKey } from '../types/generated/TranslationKey'

type Props = {
  title: TranslationKey
  text: TranslationKey
}

const PageHeader: FC<Props> = ({ title, text }) => {
  const { t } = useI18n()

  return (
    <>
      <h1>{t(title)}</h1>
      <p className="large">{t(text)}</p>
    </>
  )
}

export default PageHeader
