import React, {
  ChangeEventHandler,
  ElementRef,
  FocusEventHandler,
  forwardRef,
  ForwardRefRenderFunction,
} from 'react'
import * as yup from 'yup'
import useI18n from '../../i18n/useI18n'
import TermsLink from '../TermsLink'

export const acceptTermsValidator = yup.boolean().oneOf([true])

type Props = {
  name: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
}

const AcceptTermsCheckbox: ForwardRefRenderFunction<ElementRef<'input'>, Props> = (
  { name, onChange, onBlur },
  ref,
) => {
  const { t } = useI18n()

  return (
    <>
      <label className="form">
        <div className="flex justify-start items-center">
          <input
            ref={ref}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            type="checkbox"
            className="w-4 h-4"
            data-testid="accept-terms"
          />
          {t('I accept')}
          &nbsp;
          <TermsLink>{t('the licende of agreement')}</TermsLink>.
        </div>
        <div>
          <span>
            <strong>{t('You have to accept the agreement to register.')}</strong>
          </span>
        </div>
      </label>
    </>
  )
}

export default forwardRef(AcceptTermsCheckbox)
