import React from 'react'
import useI18n from '../../../../i18n/useI18n'
import { useNotificationSettings } from '../../../../services/requestHooks/notifications'
import { useOnMount } from '../../../../utils/commonHooks'
import { usePanelId } from '../../hooks/usePanelId'
import SettingsSubPage from '../components/SettingsSubPage'
import NotificationSettingList from './components/NotificationSettingList'

const NotificationPage = () => {
  const { t } = useI18n()
  const panelId = usePanelId()
  const { run: getNotificationSettings, data: notificationSettings } = useNotificationSettings()

  useOnMount(() => {
    getNotificationSettings(panelId)
  })

  return (
    <SettingsSubPage isLoading={!notificationSettings}>
      <div className="status-list padding-bottom">
        <h1 className="margin-bottom">{t('NOTIFICATIONS_SETTINGS_ACCESSIBILITY_HINT')}</h1>
        {notificationSettings &&
          notificationSettings.map((notificationSetting) => (
            <NotificationSettingList
              key={notificationSetting.TranslationKey}
              {...notificationSetting}
            />
          ))}
      </div>
    </SettingsSubPage>
  )
}

export default NotificationPage
