import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DoorsAndWindows } from '../types/DoorsAndWindows'

type DoorsandWindowsState = Record<string, { doorsandwindows?: DoorsAndWindows } | undefined>

const initialState: DoorsandWindowsState = {}

type UpdateDoorsAndWindowsPayload = DoorsAndWindows

const doorsandwindowsSlice = createSlice({
  name: 'doorsandwindowsList',
  initialState,
  reducers: {
    UPDATE_DOORSANDWINDOWS(
      state,
      action: PayloadAction<{ data: UpdateDoorsAndWindowsPayload; panelId: string }>,
    ) {
      const { data, panelId } = action.payload
      state[panelId] = { doorsandwindows: data }
    },
  },
})

export const UPDATE_DOORSANDWINDOWS = doorsandwindowsSlice.actions.UPDATE_DOORSANDWINDOWS

export const doorsandwindowsListReducer = doorsandwindowsSlice.reducer
