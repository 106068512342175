import React, { FC, useContext, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import FormButtons from '../../../components/forms/FormButtons'
import useI18n from '../../../i18n/useI18n'
import { useUpdateContacts } from '../../../reducers/contactsSlice'
import { useContacts, useSetContacts } from '../../../services/requestHooks/contacts'
import { useOnMount } from '../../../utils/commonHooks'
import { usePanelId } from '../../AlarmSystems/hooks/usePanelId'
import Ice from '../../AlarmSystems/Settings/Contacts/components/Ice'

import Neighbors from '../../AlarmSystems/Settings/Contacts/components/Neighbors'
import Residents from '../../AlarmSystems/Settings/Contacts/components/Residents'
import {
  updatePerson,
  changePriority,
  deletePerson,
} from '../../AlarmSystems/Settings/Contacts/ContactsPage'
import FailedRequest from '../components/FailedRequest'

import OnboardingWizardSubPage from '../components/OnboardingWizardSubPage'
import { OnboardingWizardStep } from '../constants'
import { WizardContext } from '../WizardContext'

const createSubPage = (
  step: OnboardingWizardStep,
  Form: typeof Residents | typeof Neighbors | typeof Ice,
) => {
  const SupPage: FC = () => {
    const { t } = useI18n()
    const panelId = usePanelId()
    const history = useHistory()
    const { run: getContacts, isLoading, data: contacts, error: getContactsError } = useContacts()
    const updateContacts = useUpdateContacts()
    const { loadData, saveData, getUrl } = useContext(WizardContext)
    const {
      run: setContactPersons,
      isLoading: isSettingContacts,
      error: setContactsError,
    } = useSetContacts({
      onSuccess: () => {
        history.push(getUrl(step, 'forward'))
      },
    })

    const actions = useMemo(
      () => ({
        updatePerson: updatePerson(contacts, updateContacts),
        changePriority: changePriority(contacts, updateContacts),
        deletePerson: deletePerson(contacts, updateContacts),
      }),
      [contacts, updateContacts],
    )

    const onSave = async () => {
      if (contacts) {
        const body = { ContactPersons: contacts, PanelId: panelId }
        saveData(setContactPersons, body)
      }
    }

    useOnMount(() => {
      loadData(getContacts, { PanelId: panelId }, getUrl(step, 'back'))
    })

    const requireMobile = Form === Residents
    const hasMobile = !!contacts?.some((contact) => !!contact.Mobile)

    const isValid = requireMobile ? hasMobile && !!contacts?.length : !!contacts?.length

    return (
      <OnboardingWizardSubPage activeStep={step} isLoading={isLoading}>
        <h1 className="mb-8">{t('Contact persons')}</h1>
        {getContactsError ? (
          <FailedRequest text="Could not load contact persons, please try again or contact customer service." />
        ) : (
          <>
            <Form actions={actions} contacts={contacts} />
            <div className="mt-12">
              <FormButtons
                isLoading={isSettingContacts}
                isValid={isValid}
                onClick={onSave}
                cancelUrl={getUrl(step, 'back')}
                submitText="Next"
                cancelText="Back"
              />
            </div>
          </>
        )}

        {!!setContactsError && <FailedRequest text={setContactsError} />}
      </OnboardingWizardSubPage>
    )
  }
  return SupPage
}

export const AddResidents = createSubPage(OnboardingWizardStep.CONTACTS_RESIDENTS, Residents)
export const AddNeighbors = createSubPage(OnboardingWizardStep.CONTACTS_NEIGHBORS, Neighbors)
export const AddOther = createSubPage(OnboardingWizardStep.CONTACTS_OTHER, Ice)
