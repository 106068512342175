import { useMemo } from 'react'
import { useOnMount } from '../utils/commonHooks'
import classNames from 'classnames'
import { UserCultureInfo } from '../types/UserDetails'
import { ParagraphType, TermsType, TextType } from '../types/Terms'
import { useGetTerms } from '../services/requestHooks/terms'
import useI18n from '../i18n/useI18n'
import FailedRequest from './onboardingWizard/components/FailedRequest'
import LoadPage from '../components/LoadPage'
import { useParams } from 'react-router'
import styled from 'styled-components'

const getParagraphClass = ({
  MarginAfter,
  MarginBefore,
  Indent,
  PageBreaksBefore,
}: ParagraphType) => {
  return classNames({
    terms__MARGINAFTER: MarginAfter === 1,
    terms__MARGINAFTER_LARGE: MarginAfter === 2,
    terms__MARGINBEFORE: MarginBefore === 1,
    terms__MARGINBEFORE_LARGE: MarginAfter === 2,
    terms__INDENT1: Indent === 0,
    terms__INDENT2: Indent === 1,
    terms__PAGEBREAKEBEFORE: PageBreaksBefore,
  })
}

const getTextClass = ({ IsBold, IsItalic }: TextType) => {
  return classNames({
    'font-italic': IsItalic,
    'font-bold': IsBold,
  })
}

const Text = ({ text }: { text: TextType }) => {
  const { IsHyperLink, TextValue } = text
  return IsHyperLink ? (
    <a href={TextValue}>{TextValue}</a>
  ) : (
    <span className={getTextClass(text)}>{TextValue}</span>
  )
}

const Terms = ({ terms }: { terms: TermsType }) => {
  return terms.Paragraphs ? (
    <>
      {terms.Paragraphs.map((paragraph, index) => {
        if (paragraph.Texts) {
          return (
            <p className={getParagraphClass(paragraph)} key={index}>
              {paragraph.Texts.map((text) => (
                <Text text={text} key={text.TextValue} />
              ))}
            </p>
          )
        } else {
          return null
        }
      })}
    </>
  ) : null
}

const TermsPage = () => {
  const { t } = useI18n()
  const { nation, culture } = useParams<{ nation: string; culture: UserCultureInfo }>()

  const {
    run: getTerms,
    data: termsList,
    isLoading: loadingTerms,
    error: loadTermsError,
  } = useGetTerms()

  const terms = useMemo(() => termsList?.[parseInt(nation)]?.[culture], [
    termsList,
    nation,
    culture,
  ])

  useOnMount(() => {
    if (!terms) {
      getTerms(parseInt(nation), culture)
    }
  })

  return (
    <LoadPage isLoading={loadingTerms}>
      {!!terms && (
        <div className="grid">
          <div className="grid__two-third">
            <>
              <TermsContainer>
                <Terms terms={terms} />
              </TermsContainer>

              <button
                style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                className="button button--secondary"
                onClick={() => window.print()}
              >
                <span style={{ paddingRight: '2 rem' }}>{t('PRINT')}</span>
              </button>
            </>
          </div>
        </div>
      )}
      {!!loadTermsError && <FailedRequest text={loadTermsError} />}
    </LoadPage>
  )
}

const TermsContainer = styled.div`
  margin: 90px;
  @media (max-width: 768px) {
    margin: 45px;
  }
`

export default TermsPage
