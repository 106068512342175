import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserType } from '../constants/panelUserTypes'

export interface User {
  IsActive: boolean
  HasTag?: boolean
  HasCode?: boolean
  Name: string
  Type: UserType
  Index?: number
}

type PanelUserState = Record<
  string,
  | {
      usersInfo?: {
        CodeLength: undefined | number
        Users: Required<User>[]
      }
      selectedUser?: User
      selectedUserEdit?: boolean
    }
  | undefined
>

const initialState: PanelUserState = {}

const panelUsersSlice = createSlice({
  name: 'PANEL_USERS',
  initialState,
  reducers: {
    SET_PANEL_USERS(state, action: PayloadAction<{ data: any; panelId: string }>) {
      const { data, panelId } = action.payload
      state[panelId] = { ...(state[panelId] || {}), usersInfo: data }
    },
    SET_PANEL_USER_EDIT_MODE: (
      state,
      action: PayloadAction<{
        data: { user: User; field: string; value: string | number | boolean }
        panelId: string
      }>,
    ) => {
      const { data, panelId } = action.payload
      state[panelId]?.usersInfo?.Users.forEach((user: any) => {
        if (user.Index === data.user.Index) {
          user[data.field] = data.value
        } else {
          user[data.field] = false
        }
      })
    },
    SET_SELECTED_USER: (
      state,
      action: PayloadAction<{ data: User | undefined; panelId: string }>,
    ) => {
      const { data, panelId } = action.payload
      state[panelId] = { ...(state[panelId] || {}), selectedUser: data }
    },
    EDIT_SELECTED_USER: (state, { payload: selectedUserEdit }) => {
      state.selectedUserEdit = selectedUserEdit
    },
    SET_SELECTED_USER_FIELD_VALUE: (
      state,
      { payload }: PayloadAction<{ field: keyof User; fieldValue: string }>,
    ) => {
      const user: any = state.selectedUser
      user[payload.field] = payload.fieldValue
    },
    SAVED_PANEL_USER(state, _action: PayloadAction<{ panelId: string }>) {
      state.selectedUser = undefined
    },
  },
})

// eslint-disable-next-line import/no-unused-modules
export const {
  SET_PANEL_USER_EDIT_MODE,
  SET_SELECTED_USER,
  EDIT_SELECTED_USER,
  SET_SELECTED_USER_FIELD_VALUE,
  SAVED_PANEL_USER,
  SET_PANEL_USERS,
} = panelUsersSlice.actions

export default panelUsersSlice.reducer
