import { createUseRequest } from './createUseRequest'
import { addDeviceContinue, addDeviceFinish, addDeviceStart } from '../settingsApi'
import {
  ADD_DEVICE_START,
  ADD_DEVICE_CONTINUE,
  ADD_DEVICE_FINISH,
} from '../../reducers/addDeviceSlice'

export const useAddDeviceStart = createUseRequest({
  apiCall: addDeviceStart,
  successActionCreator: ADD_DEVICE_START,
  requirePanelId: true,
})

export const useAddDeviceContinue = createUseRequest({
  apiCall: addDeviceContinue,
  successActionCreator: ADD_DEVICE_CONTINUE,
  requirePanelId: true,
})

export const useAddDeviceFinish = createUseRequest({
  apiCall: addDeviceFinish,
  successActionCreator: ADD_DEVICE_FINISH,
  requirePanelId: true,
})
