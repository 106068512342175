import { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  GET_NOTIFICATION_SETTINGS,
  TOGGLE_NOTIFICATION_SETTING,
} from '../../reducers/notificationSlice'
import { RootState } from '../../store'
import { getNotificationSettings, toggleNotifcationSetting } from '../settingsApi'
import { createUseRequest } from './createUseRequest'

const selectSettings = (state: RootState) => state.notificationSettings.settings

export const useToggleNotificationSetting = () => {
  const [isLoading, setIsloading] = useState(false)
  const [error, setError] = useState<string>()
  const dispatch = useDispatch()
  const run = async (...body: Parameters<typeof toggleNotifcationSetting>) => {
    setIsloading(true)
    try {
      await toggleNotifcationSetting(...body)
      dispatch(TOGGLE_NOTIFICATION_SETTING(...body))
      setIsloading(false)
    } catch (e) {
      setIsloading(false)
      let msg = (e as Error).message
      setError(msg) // setError(e)
    }
  }
  return {
    isLoading,
    error,
    run,
  }
}

export const useNotificationSettings = createUseRequest({
  successActionCreator: GET_NOTIFICATION_SETTINGS,
  apiCall: getNotificationSettings,
  selector: selectSettings,
})
