import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppUser } from '../types/AppUser'

type PropertyContactsState = Record<string, { contacts: AppUser[] } | undefined>

const initialState: PropertyContactsState = {}

type GetContactsPayload = AppUser[]

const propertyContacstSlice = createSlice({
  name: 'propertyContacts',
  initialState,
  reducers: {
    GET_PROPERTY_CONTACTS(
      state,
      action: PayloadAction<{ data: GetContactsPayload; panelId: string }>,
    ) {
      const { panelId, data } = action.payload
      state[panelId] = { contacts: data }
    },
    INVITE_PROPERTY_CONTACT(state, action: PayloadAction<{ panelId: string }>) {},
    SET_PROPERTY_CONTACT(state, action: PayloadAction<{ panelId: string }>) {},
  },
})

// eslint-disable-next-line import/no-unused-modules
export const {
  GET_PROPERTY_CONTACTS,
  INVITE_PROPERTY_CONTACT,
  SET_PROPERTY_CONTACT,
} = propertyContacstSlice.actions

export const propertyContactsReducer = propertyContacstSlice.reducer
