import {
  GET_SMARTPLUGS_SUCCESS,
  TURN_OFF_SUCCESS,
  TURN_ON_SUCCESS,
  TURN_OFF_TC_SUCCESS,
  TURN_ON_TC_SUCCESS,
  SET_SCENARIO_TRIGGERS_SUCCESS,
  SET_TIME_TRIGGERS_SUCCESS,
  GET_TRIGGERS_SUCCESS,
  SET_LABEL_SUCCESS,
} from '../../reducers/plugsReducer'
import { RootState } from '../../store'
import {
  getSmartplugsStatus,
  turnOffSmartplug,
  turnOffTCSmartplug,
  turnOnSmartplug,
  turnOnTCSmartplug,
} from '../apiPanel'
import {
  setSmartplugScenarioTrigger,
  setSmartplugTimeTriggers,
  getSmartPlug,
  setSmartPlugLabel,
} from '../settingsApi'
import { createUseRequest } from './createUseRequest'

export const useGetSmartplugsStatus = createUseRequest({
  apiCall: getSmartplugsStatus,
  successActionCreator: GET_SMARTPLUGS_SUCCESS,
  selector: (state: RootState, panelId: string) => state.plugs[panelId]?.plugs,
  requirePanelId: true,
})

export const useTurnOffTCSmartplug = createUseRequest({
  apiCall: turnOffTCSmartplug,
  successActionCreator: TURN_OFF_TC_SUCCESS,
  requirePanelId: true,
})

export const useTurnOnTCSmartplug = createUseRequest({
  apiCall: turnOnTCSmartplug,
  successActionCreator: TURN_ON_TC_SUCCESS,
  requirePanelId: true,
})

export const useTurnOffSmartplug = createUseRequest({
  apiCall: turnOffSmartplug,
  successActionCreator: TURN_OFF_SUCCESS,
  selector: (state: RootState, panelId: string) => state.plugs[panelId]?.plugs,
  requirePanelId: true,
})

export const useTurnOnSmartplug = createUseRequest({
  apiCall: turnOnSmartplug,
  successActionCreator: TURN_ON_SUCCESS,
  selector: (state: RootState, panelId: string) => state.plugs[panelId]?.plugs,
  requirePanelId: true,
})

export const useSetPlugScenarioTrigger = createUseRequest({
  apiCall: setSmartplugScenarioTrigger,
  successActionCreator: SET_SCENARIO_TRIGGERS_SUCCESS,
  requirePanelId: true,
})

export const useSetPlugTimeTrigger = createUseRequest({
  apiCall: setSmartplugTimeTriggers,
  successActionCreator: SET_TIME_TRIGGERS_SUCCESS,
  requirePanelId: true,
})

export const useGetSmartplug = createUseRequest({
  apiCall: getSmartPlug,
  successActionCreator: GET_TRIGGERS_SUCCESS,
  selector: (state: RootState, panelId: string) => state.plugs[panelId]?.currentPlug,
  requirePanelId: true,
})

export const useSetSmartplugLabel = createUseRequest({
  apiCall: setSmartPlugLabel,
  successActionCreator: SET_LABEL_SUCCESS,
  requirePanelId: true,
})
