import React, { FC } from 'react'
import useI18n from '../../../../../i18n/useI18n'
import { NotificationSetting } from '../../../../../types/NotificationSetting'
import NotificationSettingItem from './NotificationSettingItem'

type Props = NotificationSetting

const NotificationSettingList: FC<Props> = ({ Channel, TranslationKey, Events }) => {
  const { t } = useI18n()

  return (
    <>
      <h2 className="gray margin-top--large">{t(TranslationKey)}</h2>
      <hr />
      {Events.map((eventSetting) => (
        <NotificationSettingItem
          key={eventSetting.TranslationKey}
          Channel={Channel}
          {...eventSetting}
        />
      ))}
    </>
  )
}

export default NotificationSettingList
