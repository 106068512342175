export enum AccessGroupType {
  LEGAL_OWNER = 1,
  ADMINISTRATOR = 2,
  USER = 3,
}

export interface AppUser {
  AppUserId: string
  AccessGroup: AccessGroupType
  PhoneNumber: string
  LastName: string
  FirstName: string
  IsInvite: boolean
}
