import Modal from '../../../../../components/Modal'
import useI18n from '../../../../../i18n/useI18n'
import { TranslationKey } from '../../../../../types/generated/TranslationKey'
import Button from '../../../../../components/Button'
import { useState } from 'react'

interface Props {
  promptState: Partial<Handlers>
}

const WarningPrompt = ({ promptState }: Props) => {
  const { t } = useI18n()
  const { warning, onClose, onSubmit } = promptState
  if (!onSubmit || !onClose || !warning) return null
  return (
    <Modal>
      <div className="grid__half">
        <h1 className="text-2xl">{t('Warning')}</h1>
        <p>
          {t(`The change you're about to do will cause`)}
          {t(warning)}
          {t('to be reset')}
        </p>
        <div>
          <Button onClick={onSubmit} buttonType="primary">
            {t('Confirm')}
          </Button>
          <Button onClick={onClose} buttonType="secondary">
            {t('Cancel')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

interface Handlers {
  onSubmit: () => void
  onClose: () => void
  warning: TranslationKey | undefined
}

export const useWarningPrompt = () => {
  const [handlers, setHandlers] = useState<Partial<Handlers>>({})
  const prompt = (warning: TranslationKey) => {
    return new Promise((resolve) => {
      setHandlers({
        warning,
        onSubmit: () => {
          setHandlers({})
          resolve(true)
        },
        onClose: () => {
          setHandlers({})
          resolve(false)
        },
      })
    })
  }
  return { prompt, promptState: handlers }
}

export default WarningPrompt
