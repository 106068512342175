import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CheckInviteResponse } from '../types/AppUserInvite'

export enum FormStep {
  Email,
  RequestCode,
  AcceptCode,
  NewUser,
  ExistingUser,
  Finished,
}

interface AppUserRegistrationState {
  firstName?: string
  lastName?: string
  userExists: boolean
  validInvite?: boolean
}

const initialState: AppUserRegistrationState = {
  userExists: false,
}

const appUserRegistrationSlice = createSlice({
  name: 'app_user_registration',
  initialState,
  reducers: {
    ACCEPT_INVITE_SUCCESS() {},
    SEND_SMS_SUCCESS() {},
    CHECK_INVITE_SUCCESS(state, action: PayloadAction<CheckInviteResponse>) {},
    ACCEPT_NEW_USER() {},
    ACCEPT_EXISTING_USER() {},
    VALIDATE_SMS_SUCCESS() {},
  },
})

// eslint-disable-next-line import/no-unused-modules
export const {
  ACCEPT_INVITE_SUCCESS,
  SEND_SMS_SUCCESS,
  CHECK_INVITE_SUCCESS,
  ACCEPT_NEW_USER,
  ACCEPT_EXISTING_USER,
  VALIDATE_SMS_SUCCESS,
} = appUserRegistrationSlice.actions

export default appUserRegistrationSlice.reducer
