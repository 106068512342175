import React, { ComponentType, FC } from 'react'
import { generatePath, useParams } from 'react-router-dom'
import { useOnMount } from '../../utils/commonHooks'
import TwoFactorPrompt, {
  TwofactorContext,
  useTwoFactorPrompt,
} from '../TwoFactorPrompt/TwoFactorPrompt'
import CRMRoute from './CRMRoute'
import PrivateRoute from './PrivateRoute'

type Props = { cancelUrl: string; component?: ComponentType; path: string; exact?: boolean }

const TwoFactorRoute: FC<Props> = ({ cancelUrl, component: Component, children, ...rest }) => {
  return (
    <PrivateRoute {...rest}>
      <CRMRoute>
        <TwoFactorContextProvider cancelUrl={cancelUrl}>
          {children || (Component && <Component />)}
        </TwoFactorContextProvider>
      </CRMRoute>
    </PrivateRoute>
  )
}

type TwoFactorContextProviderProps = {
  cancelUrl: string
}

export const TwoFactorContextProvider: FC<TwoFactorContextProviderProps> = ({
  cancelUrl,
  children,
}) => {
  const params = useParams()
  const returnUrl = generatePath(cancelUrl, params)

  const { promptForTwoFactor, promptState } = useTwoFactorPrompt()
  useOnMount(() => {
    promptForTwoFactor(returnUrl)
  })

  return (
    <TwofactorContext.Provider value={{ promptForTwoFactor, cancelUrl: returnUrl }}>
      {children}
      <TwoFactorPrompt promptState={promptState} cancelUrl={returnUrl} />
    </TwofactorContext.Provider>
  )
}

export default TwoFactorRoute
