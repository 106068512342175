import './loading-overlay.scss'

import React, { FC } from 'react'
import classNames from 'classnames'
import { Loader, LoadingText } from '../Elements/Loaders'

type Props = {
  isLoading: boolean
  inline?: boolean
  className?: string
  loadingText?: LoadingText
}

const LoadingOverlay: FC<Props> = ({ isLoading, children, inline, className, loadingText }) => {
  return (
    <div className={classNames('loading-overlay', inline && 'loading-overlay--inline', className)}>
      <div className="loading-overlay__content">{children}</div>
      {isLoading && (
        <div className="loading-overlay__loader">
          <Loader loadingText={loadingText} />
        </div>
      )}
    </div>
  )
}

export default LoadingOverlay
