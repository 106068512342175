import React from 'react'

export function SectorLogo() {
  return (
    <div className="navbar__logo">
      <svg role="img" aria-label="title" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240.9 110">
        <path fill="#1D1D1B" d="M.2 0h240.6v110H.2z" />
        <path
          fill="#DB0D16"
          d="M38.3 79.4l-3.1-7.8-3.1 7.8h6.2zm-6.7-16.8h7.3l11.7 27.6h-8.1l-2-4.9H29.9l-2 4.9h-8l11.7-27.6zM55.1 62.8h7.6v20.7H76v6.7H55.1M98.1 79.4L95 71.6l-3 7.8h6.1zm-6.7-16.8h7.3l11.7 27.6h-8.1l-2-4.9H89.7l-2 4.9h-8l11.7-27.6zM127.6 75.9c2.6 0 4.1-1.3 4.1-3.2v-.1c0-2.2-1.6-3.2-4.1-3.2h-5V76h5zM115 62.8h13c4.2 0 7.1 1.1 8.9 3 1.6 1.6 2.4 3.7 2.4 6.4v.1c0 4.2-2.2 7-5.6 8.4l6.5 9.6h-8.8l-5.5-8.3h-3.4v8.3H115V62.8zM145.4 62.8h8l6.5 10.6 6.6-10.6h8v27.4H167V74.4l-7.1 10.8h-.1l-7-10.7v15.7h-7.4M188 70.5c-3.3 0-6 2.7-6 5.9 0 3.3 2.7 6 6 6s6-2.7 6-6-2.7-5.9-6-5.9M217.7 76.4c0 5.1-1.3 10-3.7 14.4l3.4 1c2.5-4.7 3.8-10 3.8-15.4s-1.3-10.7-3.8-15.4l-3.4 1c2.4 4.4 3.7 9.3 3.7 14.4M205 88.2l3.5 1c2.4-3.8 3.7-8.3 3.7-12.8 0-4.6-1.3-9-3.7-12.8l-3.5 1c2.4 3.5 3.7 7.6 3.7 11.8 0 4.3-1.3 8.4-3.7 11.8M203.2 76.4c0-3.8-1.4-7.4-3.9-10.2l-3.8 1.1c2.7 2.2 4.3 5.5 4.3 9 0 3.6-1.6 6.8-4.3 9l3.8 1.1c2.5-2.6 3.9-6.1 3.9-10"
        />
        <path
          fill="#FFF"
          d="M19.1 47.1l5.1-6.1c3.3 2.6 6.9 4 10.7 4 2.5 0 3.8-.8 3.8-2.3v-.1c0-1.4-1.1-2.1-5.6-3.2-7-1.6-12.5-3.6-12.5-10.4 0-6.1 4.9-10.6 12.8-10.6 5.6 0 10 1.5 13.6 4.4l-4.6 6.5c-3-2.1-6.3-3.3-9.2-3.3-2.2 0-3.3.9-3.3 2.1v.1c0 1.5 1.1 2.2 5.7 3.2 7.6 1.7 12.3 4.1 12.3 10.3v.1c0 6.7-5.3 10.8-13.3 10.8-5.9 0-11.5-1.8-15.5-5.5M53.2 19h26.5v7.8H62.2v5h15.9V39H62.2v5.3H80V52H53.2M83.9 35.6c0-9.7 7.4-17.3 17.4-17.3 6.7 0 11.1 2.8 14 6.9l-6.9 5.3c-1.9-2.4-4.1-3.9-7.2-3.9-4.6 0-7.9 3.9-7.9 8.7v.1c0 5 3.3 8.8 7.9 8.8 3.4 0 5.5-1.6 7.5-4l6.9 4.9c-3.1 4.3-7.3 7.5-14.6 7.5-9.5.1-17.1-7.1-17.1-17M128.4 27h-9.9v-8h29v8h-9.9v25h-9.2M176.1 35.6c0-4.9-3.4-9-8.5-9-5 0-8.3 4.1-8.3 8.8v.1c0 4.8 3.4 8.9 8.4 8.9 5 .1 8.4-4 8.4-8.8m-26.3 0c0-9.6 7.6-17.3 17.8-17.3 10.2 0 17.7 7.6 17.7 17.1v.1c0 9.5-7.6 17.2-17.8 17.2-10.1 0-17.7-7.6-17.7-17.1M205.9 34.9c3.1 0 4.9-1.5 4.9-3.9v-.1c0-2.6-1.9-3.9-5-3.9h-6v7.9h6.1zM190.7 19h15.6c5 0 8.5 1.3 10.8 3.6 1.9 1.9 2.9 4.4 2.9 7.7v.1c0 5-2.7 8.4-6.8 10.1l7.9 11.5h-10.6l-6.6-10h-4v10h-9.1V19z"
        />
        <title>Sector Alarm</title>
      </svg>
    </div>
  )
}
