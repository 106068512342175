import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import * as yup from 'yup'
import Button from '../../../../components/Crow/Button/Button'
import { colors } from '../../../../components/Crow/Style/theme'
import { Paragraph } from '../../../../components/Crow/Typography'
import TextAreaField from '../../../../components/forms/TextAreaField'
import useI18n, { useIrishCheck } from '../../../../i18n/useI18n'
import { RootState } from '../../../../store'

export type DirectionsFormValues = {
  directions: string | undefined
}

const baseValidator = yup
  .string()
  .test(
    'min-length',
    'At least 2 characters is required',
    (value: string | undefined) => !value || value.length >= 2,
  )
  .matches(
    /^[\x20-\x7E\xA0-\xA3\xA5\xA7\xA9-\xB3\xB5-\xB7\xB9-\xBB\xBF-\xFF\u20AC\u0160\u0161\u017D\u017E\u0152\u0153\u0178\r\n]*$/,
    'Special characters are not allowed',
  )

const requiredValidator = baseValidator.required('Required')

const resolver = (required?: boolean) =>
  yup.object({ directions: required ? requiredValidator : baseValidator })

type Props = {
  onSubmit: (values: DirectionsFormValues) => void
  defaultValues?: DirectionsFormValues
  required?: boolean
}

const LocationAndDirectionsForm: FC<Props> = ({ onSubmit, defaultValues, required }) => {
  const { t } = useI18n()
  const { checkIrish } = useIrishCheck()
  const directions = useSelector((state: RootState) => state.directions.directions)

  const { handleSubmit, register, setValue, getValues } = useForm<DirectionsFormValues>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues,
    resolver: yupResolver(resolver(required)),
  })

  useEffect(() => {
    setValue('directions', directions)
  }, [directions, setValue])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StyledTextAreaField
        {...register('directions')}
        crow
        rows={4}
        cols={50}
        placeholder="E.g. After the church, it's the second house on your right hand side. Around the back there is a veranda entrance accessible from the stairs."
        name="textarea"
        label="Add a direction"
      />
      <StyledLocationAndDirectionsParagraph light grey>
        {checkIrish(
          'Having the following information makes it easier to assist in case of an alarm. Remember to add information such as main entrance code and key box code and place.',
          'PW_LOCATION_INFO',
        )}
      </StyledLocationAndDirectionsParagraph>
      <Button
        type="submit"
        disabled={!!!getValues('directions')}
        level="secondary"
        className="ml-auto"
      >
        {t('Save')}
      </Button>
    </form>
  )
}

const StyledTextAreaField = styled(TextAreaField)`
  max-width: 492px;
  height: 170px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 12px;
  color: ${colors.black500};
  margin: 0;
`
const StyledLocationAndDirectionsParagraph = styled(Paragraph)`
  max-width: 492px;
  margin-left: 26px;
  padding-bottom: 40px;
`

export default LocationAndDirectionsForm
