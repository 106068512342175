import { useSelector } from 'react-redux'
import {
  LOAD_USER_INFO,
  CHANGE_PASSWORD_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  SET_CULTURE,
  ACTIVATE_ACCOUNT,
} from '../../reducers/userSlice'
import { RootState } from '../../store'
import { Resource, UserDetails } from '../../types/UserDetails'
import { changePassword, setCulture } from '../apiAccount'
import { activateAccount, getAccountInfo, resetPassword } from '../apiLogin'
import { createUseRequest } from './createUseRequest'

const selectUserInfo = (state: RootState) => state.user

export const useUserInfo = (): UserDetails => {
  const { User } = useSelector(selectUserInfo)
  if (User === undefined) {
    throw new Error('User is not defined')
  }
  return User
}

export const useResourceInfo = (): Resource => {
  const { Resources } = useSelector(selectUserInfo)
  if (Resources === undefined) {
    throw new Error('User is not defined')
  }
  return Resources
}

export const useGetUserInfo = createUseRequest({
  successActionCreator: LOAD_USER_INFO,
  apiCall: getAccountInfo,
})

export const useChangePassword = createUseRequest({
  successActionCreator: CHANGE_PASSWORD_SUCCESS,
  apiCall: changePassword,
})

export const useResetPassword = createUseRequest({
  successActionCreator: RESET_PASSWORD_SUCCESS,
  apiCall: resetPassword,
})

export const useSetCulture = createUseRequest({
  successActionCreator: SET_CULTURE,
  apiCall: setCulture,
})

export const useChallengeAccount = createUseRequest({
  successActionCreator: ACTIVATE_ACCOUNT,
  apiCall: activateAccount,
})
