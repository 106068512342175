import React, { FC, MouseEventHandler } from 'react'
import CloseButton from '../Button/CloseButton'

type Props = {
  onClick: MouseEventHandler<HTMLButtonElement>
}

const CloseModalButton: FC<Props> = ({ onClick }) => {
  return (
    <div className="modal__close">
      <CloseButton onClick={onClick} />
    </div>
  )
}

export default CloseModalButton
