import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OrderResponse } from '../types/Order'

interface OrderState {
  order?: OrderResponse
}

const initialState: OrderState = {
  order: undefined,
}

const orderSlice = createSlice({
  initialState,
  name: 'order',
  reducers: {
    LOAD_ORDER_SUCCESS(state, action: PayloadAction<OrderResponse>) {
      state.order = action.payload
    },
  },
})

// eslint-disable-next-line import/no-unused-modules
export const { LOAD_ORDER_SUCCESS } = orderSlice.actions

export default orderSlice.reducer
