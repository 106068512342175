import React, {
  ChangeEventHandler,
  ElementRef,
  FocusEventHandler,
  forwardRef,
  ForwardRefRenderFunction,
} from 'react'
import { format } from 'date-fns'
import { TimeSlot } from '../../../../../types/TimeSlot'
import { RadioButton } from '../components/RadioButtonField'
import { useDateFnsLocale } from '../../../../../utils/useDateFnsLocale'

type Props = {
  timeSlot: TimeSlot
  name: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
  value: number
  isSelected: boolean
}

const TimeSlotItem: ForwardRefRenderFunction<ElementRef<'input'>, Props> = (
  { name, onChange, onBlur, timeSlot, value, isSelected },
  ref,
) => {
  const locale = useDateFnsLocale()
  const dateString = format(new Date(timeSlot.startField), 'PPP', { locale })
  const timeString = `${format(new Date(timeSlot.startField), 'p', { locale })} - ${format(
    new Date(timeSlot.endField),
    'p',
    { locale },
  )}`
  return (
    <RadioButton
      ref={ref}
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      isSelected={isSelected}
      value={value}
    >
      <div>{dateString}</div>
      <div>{timeString}</div>
    </RadioButton>
  )
}

export default forwardRef(TimeSlotItem)
