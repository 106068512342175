import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TermsType } from '../types/Terms'
import { UserCultureInfo } from '../types/UserDetails'

type TermsState = {
  [nationId: number]: {
    [culture in UserCultureInfo]?: TermsType
  }
}

const initialState: TermsState = {}

const termsSlice = createSlice({
  name: 'terms',
  initialState,
  reducers: {
    SET_TERMS(
      state,
      { payload }: PayloadAction<{ nationId: number; culture: UserCultureInfo; terms: TermsType }>,
    ) {
      if (!state[payload.nationId]) {
        state[payload.nationId] = {}
      }
      state[payload.nationId][payload.culture] = payload.terms
    },
  },
})

// eslint-disable-next-line import/no-unused-modules
export const { SET_TERMS } = termsSlice.actions

export default termsSlice.reducer
