import React from 'react'
import { formatHour, formatMinutes } from '../../../../../utils/formatNumber'

export interface Timestamp {
  hour: string
  minute: string
}
const TimeInput = ({
  onChange,
  value,
}: {
  onChange: (timestamp: Timestamp) => void
  value: Timestamp
}) => {
  const { hour: hourString, minute: minuteString } = value
  const hour = parseInt(hourString)
  const minute = parseInt(minuteString)
  const adjust = (hour: number, minute: number) => () => {
    onChange({
      hour: hour === -1 ? '23' : (hour % 24).toString(),
      minute: minute === -15 ? '45' : (minute % 60).toString(),
    })
  }
  return (
    <div className="flex">
      <span className="flex flex-col items-center">
        <button
          type="button"
          onClick={adjust(hour + 1, minute)}
          className="timer2__timeSelectorKeyUpDown"
        >
          <div className="timer2__triangle-up" />
        </button>
        <span className="timer2__tText text-2xl">{formatHour(hourString)}</span>
        <button
          type="button"
          onClick={adjust(hour - 1, minute)}
          className="timer2__timeSelectorKeyUpDown"
        >
          <div className="timer2__triangle-down" />
        </button>
      </span>
      <span className="flex flex-col items-center">
        <button
          type="button"
          onClick={adjust(hour, minute + 15)}
          className="timer2__timeSelectorKeyUpDown"
        >
          <div className="timer2__triangle-up" />
        </button>
        <span className="timer2__tText text-2xl">{formatMinutes(minuteString)}</span>
        <button
          type="button"
          onClick={adjust(hour, minute - 15)}
          className="timer2__timeSelectorKeyUpDown"
        >
          <div className="timer2__triangle-down" />
        </button>
      </span>
    </div>
  )
}

export default TimeInput
