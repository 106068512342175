import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  Accesses,
  Panel,
  PermanentUser,
  UpdateUserInformationResponseBody,
} from '../types/PermanentUser'

type UsersState = Record<string, { users?: PermanentUser[]; info?: Panel } | undefined>

const initialState: UsersState = {}

type UpdateUsersPayload = PermanentUser[]

type MyPanelsPayload = { PanelSummariesForPerson: Panel[] }

const usersSlice = createSlice({
  initialState,
  name: 'users',
  reducers: {
    RESEND_EMAIL_VERIFICATION() {},
    GET_USERS_SUCCESS(state, action: PayloadAction<{ data: UpdateUsersPayload; panelId: string }>) {
      const { data, panelId } = action.payload
      state[panelId] = { ...state[panelId], users: data }
    },
    UPDATE_USER_INFORMATION_SUCCESS(
      state,
      action: PayloadAction<{
        panelId: string
        data: UpdateUserInformationResponseBody
      }>,
    ) {
      const { data, panelId } = action.payload
      state[panelId] = {
        ...state[panelId],
        users: state[panelId]?.users?.map((user) => {
          return user.PersonId === data.PersonId
            ? {
                ...user,
                FirstName: data.FirstName,
                LastName: data.LastName,
                Initials: data.Initials,
                UpdatedAt: data.UpdatedAt,
              }
            : user
        }),
      }
    },
    UPDATE_USER_PRIVILEGES_SUCCESS(
      state,
      action: PayloadAction<{
        panelId: string
        data: { accesses: Accesses; personId: string }
      }>,
    ) {
      const { data, panelId } = action.payload
      state[panelId] = {
        ...state[panelId],
        users: state[panelId]?.users?.map((user) => {
          return user.PersonId === data.personId ? { ...user, Accesses: data.accesses } : user
        }),
      }
    },
    CHANGE_USER_PIN_CODE_SUCCESS() {},
    CREATE_NEW_USER_FOR_PANEL_SUCCESS(
      state,
      action: PayloadAction<{ panelId: string; data: PermanentUser }>,
    ) {
      const { data, panelId } = action.payload

      let oldUsers = state[panelId]?.users?.slice()
      if (oldUsers === undefined) {
        oldUsers = []
      }
      state[panelId] = {
        ...state[panelId],
        users: [...oldUsers, data],
      }
    },
    DELETE_USER_FOR_PANEL_SUCCESS(state, action: PayloadAction<{ panelId: string; data: string }>) {
      const { data, panelId } = action.payload

      state[panelId] = {
        ...state[panelId],
        users: state[panelId]?.users?.filter((user) => {
          return user.PersonId !== data
        }),
      }
    },
    GET_PANEL_PERMISSIONS_SUCCESS(
      state,
      action: PayloadAction<{ panelId: string; data: MyPanelsPayload }>,
    ) {
      const { data, panelId } = action.payload

      state[panelId] = {
        ...state[panelId],
        info: data.PanelSummariesForPerson.find((e) => e.PanelId === panelId),
      }
    },
  },
})

// eslint-disable-next-line import/no-unused-modules
export const {
  RESEND_EMAIL_VERIFICATION,
  GET_USERS_SUCCESS,
  UPDATE_USER_INFORMATION_SUCCESS,
  UPDATE_USER_PRIVILEGES_SUCCESS,
  CHANGE_USER_PIN_CODE_SUCCESS,
  CREATE_NEW_USER_FOR_PANEL_SUCCESS,
  DELETE_USER_FOR_PANEL_SUCCESS,
  GET_PANEL_PERMISSIONS_SUCCESS,
} = usersSlice.actions

export default usersSlice.reducer
