import React, { FC } from 'react'
import * as yup from 'yup'
import { TranslationKey } from '../../../types/generated/TranslationKey'
import SimpleForm from '../../../components/forms/SimpleForm'
import { emailValidator, repeatEmailValidator } from '../../../components/forms/validators'
import useI18n from '../../../i18n/useI18n'

interface Props {
  onSubmit: (formValues: Record<keyof typeof resolver.fields, string>) => void
  isLoading: boolean
}

interface Field {
  name: keyof typeof resolver.fields
  label: TranslationKey
  type: 'text' | 'password' | 'email'
  placeholder: TranslationKey
}

const fields: Field[] = [
  { name: 'email', label: 'E-mail', type: 'email', placeholder: 'E-mail' },
  {
    name: 'repeatEmail',
    label: 'Repeat the email',
    type: 'email',
    placeholder: 'Repeat the email',
  },
]

const resolver = yup.object({
  email: emailValidator,
  repeatEmail: repeatEmailValidator,
})

const EmailForm: FC<Props> = ({ onSubmit, isLoading, children }) => {
  const { t } = useI18n()
  return (
    <SimpleForm
      isLoading={isLoading}
      className={'box-content'}
      resolver={resolver}
      onSubmit={onSubmit}
      fields={fields}
      title={t('enter_accept_invite_email')}
    >
      {children}
    </SimpleForm>
  )
}

export default EmailForm
