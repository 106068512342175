import React, { useState } from 'react'
import { LockIcon } from '../../../../assets/icons'
import { useHistory, useParams } from 'react-router'
import { useOnMount } from '../../../../utils/commonHooks'
import SettingsSubPage from '../components/SettingsSubPage'
import FormButtons from '../../../../components/forms/FormButtons'
import PasswordPrompt, { usePasswordPrompt } from '../../Panel/PasswordPromt'
import useI18n from '../../../../i18n/useI18n'
import FailedRequest from '../../../onboardingWizard/components/FailedRequest'
import { usePanelInfo } from '../../../../services/requestHooks/panelInfo'
import { VALIDATION_CODE } from '../../../../components/TwoFactorPrompt/constants'
import FullScreenLoader from '../../../../components/FullScreenLoader'
import { useChangeLockName } from '../../../../services/requestHooks/lockSettings'

const LockLabelForm = () => {
  const { t } = useI18n()
  const [name, setName] = useState('')
  const { panelId, lockSerial } = useParams<{ panelId: string; lockSerial: string }>()
  const { run: getPanelInfo, isLoading, data: panelInfo } = usePanelInfo()
  const { run: changeLockName, error, isLoading: saveLockLoading } = useChangeLockName({
    onSuccess: () => {
      history.push(`/systems/${panelId}`)
    },
  })
  const { promptState, promptForPassword } = usePasswordPrompt()

  const history = useHistory()

  useOnMount(() => {
    if (!isLoading && panelId !== panelInfo?.PanelId) getPanelInfo(panelId)
  })

  const lock = panelInfo?.Locks.find((lock) => lock.Serial === lockSerial)
  const placeholder = lock?.Label
  const validationCode = sessionStorage.getItem(VALIDATION_CODE)

  const handleSubmit = async () => {
    const Password = await promptForPassword()
    if (!Password || !lock) return
    changeLockName({
      Label: name,
      Password,
      AutoLock: lock.AutoLockEnabled!,
      Language: 'English',
      SoundLevel: lock.SoundLevel!,
      Serial: lock.Serial,
      PanelId: panelId,
      ValidationCode: validationCode!,
    })
  }

  if (saveLockLoading) {
    return <FullScreenLoader text="Updating" customTime={75} returnUrl={`/systems/${panelId}`} />
  }

  return (
    <SettingsSubPage isLoading={isLoading}>
      <div className="flex flex-col center">
        <LockIcon className="icon status-list__icon-svg self-center mb-6" />
        <label className="form">
          {t('Name')}
          <input
            onChange={(e) => setName(e.target.value)}
            className="form__input"
            type="text"
            placeholder={placeholder}
          />
        </label>
        <div>
          <FormButtons
            onClick={handleSubmit}
            submitText={'Save new name'}
            cancelUrl={`/systems/${panelId}/locks/${lockSerial}`}
          />
        </div>
      </div>
      {error && <FailedRequest text={error} />}
      <PasswordPrompt promptState={promptState} />
    </SettingsSubPage>
  )
}

export default LockLabelForm
