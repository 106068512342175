import React, { FC, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useSetSecurityQuestion } from '../../../services/requestHooks/securityQuestion'
import { usePanelId } from '../../AlarmSystems/hooks/usePanelId'
import EditQuestionForm, {
  EditQuestionFormValues,
} from '../../AlarmSystems/Settings/SecurityQuestion/components/EditQuestionForm'
import { SecurityQuestionHeader } from '../../AlarmSystems/Settings/SecurityQuestion/SecurityQuestionPage'
import FailedRequest from '../components/FailedRequest'
import OnboardingWizardSubPage from '../components/OnboardingWizardSubPage'
import { OnboardingWizardStep } from '../constants'
import { WizardContext } from '../WizardContext'

const step = OnboardingWizardStep.SECURITY_QUESTION

const SecurityQuestion: FC = () => {
  const history = useHistory()
  const panelId = usePanelId()
  const { saveData, getUrl } = useContext(WizardContext)
  const { run: setQuestion, isLoading, error } = useSetSecurityQuestion({
    onSuccess: () => {
      history.push(getUrl(step, 'forward'))
    },
  })

  const onSubmit = async (formValues: EditQuestionFormValues) => {
    const body = { ...formValues, PanelId: panelId }
    saveData(setQuestion, body)
  }

  return (
    <OnboardingWizardSubPage activeStep={step}>
      <SecurityQuestionHeader />
      <EditQuestionForm
        isLoading={isLoading}
        onSubmit={onSubmit}
        cancelText="Back"
        cancelUrl={getUrl(step, 'back')}
      />
      {!!error && <FailedRequest text={error} />}
    </OnboardingWizardSubPage>
  )
}

export default SecurityQuestion
