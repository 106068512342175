import styled, { css } from 'styled-components'
import { colors } from '../../../../components/Crow/Style/theme'
import { Paragraph } from '../../../../components/Crow/Typography'

export const BenefitsItemContainer = styled.div`
  display: flex;
  padding: 15px;
  border-radius: 12px;
  border-style: solid;
  border-width: 1px;
  width: 95%;

  & > *:first-child {
    flex: 1;
  }

  & > *:nth-child(2) {
    flex: 3;
    align-items: flex-start;
    justify-content: center;
  }

  & > *:last-child {
    flex: 2;
    align-items: flex-end;
    justify-content: center;
  }
`

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const TitleCSS = css`
  font-weight: 400;
  line-height: 24px;
  font-style: normal;
  color: ${colors.darkerGray};
`

export const Title = styled(Paragraph)`
  ${TitleCSS};
  font-weight: 600;
  font-size: 22px;
`
/* eslint-disable import/no-unused-modules */
export const Subtitle = styled(Paragraph)`
  ${TitleCSS};
  font-size: 18px;
`

export const Description = styled(Paragraph)`
  font-size: 16px;
  line-height: 22px;
  line-height: normal;
  font-weight: 400;
  color: ${colors.grayShade};
`

export const Image = styled.img`
  height: 60px;
  width: 60px;
`
