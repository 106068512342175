import {
  LOAD_DIRECTIONS_SUCCESS,
  SAVE_DIRECTIONS_SUCCESS,
} from '../../pages/AlarmSystems/Settings/Directions/state/directionsReducer'
import { RootState } from '../../store'
import { getLocationDetails, setLocationDetails } from '../settingsApi'
import { createUseRequest } from './createUseRequest'

export const useGetDirections = createUseRequest({
  apiCall: getLocationDetails,
  successActionCreator: LOAD_DIRECTIONS_SUCCESS,
  selector: (state: RootState) => state.directions.directions,
})

export const useSetDirections = createUseRequest({
  apiCall: setLocationDetails,
  successActionCreator: SAVE_DIRECTIONS_SUCCESS,
})
