import { createSlice } from '@reduxjs/toolkit'

interface DirectionsState {
  directions: string
  savingFailedMessage: string
  password: string
}

const initialState: DirectionsState = {
  directions: '',
  savingFailedMessage: '',
  password: '',
}

const directionsSlice = createSlice({
  name: 'directions',
  initialState,
  reducers: {
    LOAD_DIRECTIONS_SUCCESS(state, action) {
      return {
        ...state,
        directions: action.payload.Note,
      }
    },
    LOAD_DIRECTIONS_EXCEPTION(state, action) {
      return {
        ...state,
      }
    },
    SAVE_DIRECTIONS_SUCCESS(state, action) {
      return {
        ...state,
        savingFailedMessage: '',
      }
    },
    SAVE_DIRECTIONS_EXCEPTION(state, action) {
      return {
        ...state,
        savingFailedMessage: action.payload.errorMessage,
      }
    },
    START_SAVING_DIRECTIONS(state) {
      return {
        ...state,
        savingFailedMessage: '',
      }
    },
    SET_DIRECTIONS(state, action) {
      return {
        ...state,
        directions: action.payload,
      }
    },
    CONFIRM_PASSWORD(state, action) {
      return {
        ...state,
      }
    },
    SET_PASSWORD(state, action) {
      return {
        ...state,
        password: action.payload,
      }
    },
    SAVE_DIRECTIONS_EXCEPTION_PIN_EXPIRED(state, action) {
      return {
        ...state,
      }
    },
    VERIFY_PIN_CODE_SUCCESS_CONTINUE_SAVE(state, action) {
      return {
        ...state,
        savingFailedMessage: '',
      }
    },
    VERIFY_PIN_CODE_SUCCESS_CONTINUE_LOADING(state, action) {
      return {
        ...state,
        savingFailedMessage: '',
      }
    },
  },
})

// eslint-disable-next-line import/no-unused-modules
export const {
  SET_DIRECTIONS,
  LOAD_DIRECTIONS_SUCCESS,
  LOAD_DIRECTIONS_EXCEPTION,
  START_SAVING_DIRECTIONS,
  SAVE_DIRECTIONS_SUCCESS,
  SAVE_DIRECTIONS_EXCEPTION,
} = directionsSlice.actions

export default directionsSlice.reducer
