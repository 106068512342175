import { FC, useEffect } from 'react'
import { Redirect } from 'react-router'
import BackgroundImagePage from '../../components/pages/BackgroundImagePage'
import { useAuth } from '../../services/authentication/AuthContext'
import { useSystemList } from '../../services/requestHooks/systemList'
import EmailConfirmationPage from '../errors/EmailConfirmationPage'
import FailedRequest from '../onboardingWizard/components/FailedRequest'

const MainPage: FC = () => {
  const { isAuthenticated, isLoading: loadingAuth } = useAuth()
  const {
    run: loadSystemList,
    data: systemList,
    isLoading: loadingSystemList,
    hasLoaded,
    error,
  } = useSystemList()

  useEffect(() => {
    if (isAuthenticated && !hasLoaded && !loadingSystemList) {
      loadSystemList()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  if (!loadingAuth && !isAuthenticated) {
    return <Redirect to="/login" />
  }

  if (hasLoaded && !error) {
    const defaultSystem =
      systemList?.length === 1
        ? systemList?.[0]
        : systemList?.find((system) => system.IsDefaultPanel)
    if (defaultSystem) {
      return <Redirect to={`/systems/${defaultSystem.PanelId}`} />
    }
    return <Redirect to="/systems" />
  }

  const errorPage = (error: any) => {
    switch (error) {
      case 'email_not_verified':
        return <EmailConfirmationPage />
      default:
        return <FailedRequest text={error} />
    }
  }

  return (
    <BackgroundImagePage
      isLoading={loadingAuth || loadingSystemList}
      loaderProps={{ loadingText: { text: 'Loading account' } }}
    >
      {errorPage(error)}
    </BackgroundImagePage>
  )
}

export default MainPage
