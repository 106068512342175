import React, { FC } from 'react'
import NavBarPage from '../../../../components/pages/NavBarPage'
import { useOnMount } from '../../../../utils/commonHooks'
import { usePanelId } from '../../hooks/usePanelId'
import useI18n from '../../../../i18n/useI18n'
import { OrderItem } from '../../../../types/Order'
import { Link } from 'react-router-dom'
import { useLoadPanelOrder } from '../../../../services/requestHooks/order'
import { Loader } from '../../../../components/Elements/Loaders'

const OrderPage = () => {
  const { t } = useI18n()
  const { run: getPanelOrder, data, isLoading } = useLoadPanelOrder()
  const panelId = usePanelId()

  useOnMount(() => {
    getPanelOrder(panelId)
  })

  if (isLoading) {
    return (
      <div className="grid__half">
        <div className="center">
          <Loader types={['large']} />
        </div>
        <h1 className="center">
          <div>{t('Loading')}</div>
        </h1>
      </div>
    )
  }

  if (!data) {
    return <div>{t('Something went wrong. Please try again')}</div>
  }

  const { MonthlyCost, InstallationFee, SumFirstPayment } = data

  return (
    <div className="grid__half px-6">
      <span className="flex border-t border-gray-300 justify-end">
        <Link to={`/systems/${panelId}`} className="fullscreen__back m-4 text-md">
          {t('Back')}
        </Link>
      </span>
      <h1 className="flex-1">{t('your_order')}</h1>
      <p className="mt-8">{t('your_order_review')}</p>
      <h2 className="mt-6">{t('kits')}</h2>
      <ul className="pl-0">
        {data.Kits.map((item) => (
          <OrderListItem key={item.Name} item={item} />
        ))}
      </ul>
      <h2 className="mt-6">{t('extras')}</h2>
      <ul className="pl-0">
        {data.Extras.map((item) => (
          <OrderListItem key={item.Name} item={item} />
        ))}
      </ul>
      <h2 className="mt-6">{t('total')}</h2>
      <div className="history__action border-b border-gray-900">
        <span className="flex justify-between">
          <p>{t('sum_monthly_cost')}</p>
          <p className="red bold">{MonthlyCost}</p>
        </span>
        <span className="flex justify-between">
          <p>{t('sum_installation_fee')}</p>
          <p className="red bold">{InstallationFee}</p>
        </span>
      </div>
      <div className="history__action border-b border-gray-900">
        <span className="flex justify-between">
          <p>{t('sum_first_payment')}</p>
          <p className="red bold">{SumFirstPayment}</p>
        </span>
      </div>
    </div>
  )
}

interface OrderItemProps {
  item: OrderItem
}

const OrderListItem = ({ item: { Name, MonthlyCost, InstallationFee } }: OrderItemProps) => {
  const { t } = useI18n()
  return (
    <li className="history__action border-b border-gray-900 mt-0">
      <span className="bold">{Name}</span>
      <div className="mt-4 flex flex-col">
        <span className="flex justify-between">
          <span>{t('monthly_cost')}</span>
          <span>{MonthlyCost}</span>
        </span>
        <span className="flex justify-between">
          <span>{t('installation_fee')}</span>
          <span>{InstallationFee}</span>
        </span>
      </div>
    </li>
  )
}

const Wrapper: FC = () => (
  <NavBarPage>
    <div className="background-gray grid">
      <OrderPage />
    </div>
  </NavBarPage>
)

export default Wrapper
