import { UPDATE_SMOKEDETECTORS } from '../../reducers/smokeDetectorsSlice'
import { RootState } from '../../store'
import { housecheckSmokedetectors } from '../apiHouseCheck'
import { createUseRequest } from './createUseRequest'

const selectSmokeDetectors = (state: RootState, panelId: string) =>
  state.smokeDetectors[panelId]?.smokeDetectors

export const useSmokeDetectors = createUseRequest({
  successActionCreator: UPDATE_SMOKEDETECTORS,
  apiCall: housecheckSmokedetectors,
  selector: selectSmokeDetectors,
  requirePanelId: true,
})
