import React, { FC } from 'react'
import { Redirect } from 'react-router-dom'
import { useUserInfo } from '../../services/requestHooks/user'

const CRMRoute: FC = ({ children }) => {
  const User = useUserInfo()

  if (User.NationHasCRM) {
    return <>{children}</>
  }

  return <Redirect to="/404" />
}

export default CRMRoute
