import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import { colors } from '../Style/theme'

interface ExpandableProps {
  isOpen: boolean
  handleClose: () => void
}

const Expandable: FC<ExpandableProps> = ({ isOpen, children, handleClose }) => {
  return (
    <S.Anchor>
      <S.Expandable isOpen={isOpen}>{children}</S.Expandable>
    </S.Anchor>
  )
}

const S = {
  Anchor: styled.div`
    position: relative;
    width: 100%;
  `,
  Expandable: styled.div<{ isOpen: boolean }>`
    width: 100%;
    z-index: 5;
    overflow: hidden;
    max-height: 0;
    border-radius: 8px;
    position: absolute;
    background-color: ${colors.black900};
    transition: max-height 0.25s ease-in-out;

    ${({ isOpen }) =>
      isOpen &&
      css`
        border: 2px solid ${colors.black800};
        max-height: 2000px;
      `}
  `,
}

export default Expandable
