import React from 'react'

export function PhonewatchLogo() {
  return (
    <div className="navbar__logo">
      <svg role="img" aria-label="title" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240.9 70">
        <defs>
          <style>{'.cls-2{fill:#ee2b2a}.cls-3{fill:#fff}'}</style>
        </defs>
        <title>{'logo-pw-header'}</title>
        <path
          style={{
            fill: '#010101',
          }}
          d="M0 0h155v75H0z"
        />
        <path
          className="cls-2"
          d="M13.48 44.63h5l3 10 3.31-10h4.1l3.32 10 3-10h4.93L34.42 62h-4.15l-3.46-9.92L23.37 62h-4.14ZM47.46 44.5h4.59l7.32 17.35h-5.11L53 58.78h-6.62l-1.23 3.07h-5Zm4.17 10.56-1.91-4.9-1.94 4.9ZM64.09 48.81h-5.16v-4.18H74v4.18h-5.15v13h-4.76ZM76.23 53.29a8.84 8.84 0 0 1 9.06-9 8.44 8.44 0 0 1 7.3 3.59L89 50.66a4.6 4.6 0 0 0-3.76-2 4.25 4.25 0 0 0-4.09 4.55v.05c0 2.58 1.69 4.6 4.09 4.6a4.7 4.7 0 0 0 3.89-2.09l3.58 2.56a9 9 0 0 1-16.48-5ZM96 44.63h4.76v6.44h6.11v-6.44h4.76v17.22h-4.76v-6.54h-6.11v6.54H96ZM120.17 49.46a3.74 3.74 0 1 0 3.73 3.74 3.74 3.74 0 0 0-3.73-3.74ZM138.8 53.2a18.63 18.63 0 0 1-2.35 9.07l2.13.62a20.84 20.84 0 0 0 0-19.38l-2.14.62a18.51 18.51 0 0 1 2.36 9.07ZM130.82 60.63l2.18.64a15.17 15.17 0 0 0 0-16.14l-2.19.64a13 13 0 0 1 0 14.86ZM129.69 53.2a9.48 9.48 0 0 0-2.46-6.39l-2.41.7a7.34 7.34 0 0 1 0 11.38l2.41.7a9.52 9.52 0 0 0 2.46-6.39Z"
        />
        <path
          className="cls-3"
          d="M13.53 13.46H24c6.17 0 10.14 3.18 10.14 8.59v.07c0 5.76-4.42 8.8-10.48 8.8h-3.37v7h-6.76Zm10 12.15c2.4 0 3.87-1.25 3.87-3.17v-.07c0-2.1-1.47-3.22-3.91-3.22h-3.2v6.46ZM37.28 13.46h6.77v9.15h8.67v-9.15h6.76v24.45h-6.76v-9.29h-8.67v9.29h-6.77ZM63.37 25.75v-.07A12.79 12.79 0 0 1 76.54 13c7.53 0 13.1 5.62 13.1 12.64v.07A12.79 12.79 0 0 1 76.47 38.4c-7.47 0-13.1-5.63-13.1-12.65Zm19.37 0v-.07a6.35 6.35 0 0 0-6.27-6.6 6.23 6.23 0 0 0-6.17 6.53v.07a6.34 6.34 0 0 0 6.24 6.6 6.23 6.23 0 0 0 6.2-6.53ZM93.57 13.46h6.3l10 12.92V13.46h6.69v24.45h-5.92L100.26 24.5v13.41h-6.69ZM121.73 13.46h19.62v5.76h-12.93v3.71h11.71v5.34h-11.71v3.87h13.1v5.77h-19.79Z"
        />
        <title>Phonewatch</title>
      </svg>
    </div>
  )
}
