import { getDefaultMiddleware, configureStore, Action, ThunkAction } from '@reduxjs/toolkit'
import reducer from '../reducers'

const middleware = [...getDefaultMiddleware()]

const store = configureStore({ reducer, middleware })

export type RootState = ReturnType<typeof store.getState>

export type AppThunk = ThunkAction<void, RootState, any, Action<string>>

export default store
