import { SmartPlugTimeTrigger } from '../../../../../types/SmartPlug'

export type Day = 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday'
export const days: Day[] = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

export const selectedDay = (trigger: SmartPlugTimeTrigger, day: string): boolean => {
  return (
    Object.entries(trigger).filter(([dayName, isSelected]) => dayName === day && isSelected)
      .length > 0
  )
}
