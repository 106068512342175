import React, { FC } from 'react'
import { Redirect } from 'react-router'
import { usePanelId } from '../../hooks/usePanelId'

const WizardPage: FC = () => {
  const panelId = usePanelId()

  return <Redirect to={`/getting-started/${panelId}`} />
}

export default WizardPage
