/* eslint-disable import/no-unused-modules */
export const colors = {
  red: '#DB0812',
  lightRed: '#E4464E',
  red200: '#ED8388',
  red100: '#F6C1C4',

  green: '#3CA07A',
  lightGreen: '#6DB89B',
  lightestGreen: '#CEE7DE',
  green200: '#9DCFBC',
  green100: '#CEE7DE',

  yellow: '#F6A608',
  lightYellow: '#F8BC46',
  yellow200: '#FAD283',
  yellow100: '#FDE9C1',

  blue: '#66A8D8',
  lightBlue: '#8CBEE2',
  blue200: '#B2D3EB',
  blue100: '#D9E9F5',

  black: '#1A1919',
  black900: '#333131',
  black800: '#4D4A4A',
  black700: '#666363',
  black600: '#807C7C',
  black500: '#A6A1A1',
  black400: '#C9C8C8',
  black300: '#D6D4D4',
  black200: '#E3E1E1',
  black100: '#F0EEEE',

  darkGray: '#3F3F3F',
  darkerGray: '#4D4A4A',

  gray: '#7C7C7C',
  grayShade: '#666363',
  lightGray: '#F5F5F5',

  white: '#FFFFFF',
}

// https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
export const pickIconColor = (bgColor: string) => {
  const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor
  const r = parseInt(color.substring(0, 2), 16) // hexToR
  const g = parseInt(color.substring(2, 4), 16) // hexToG
  const b = parseInt(color.substring(4, 6), 16) // hexToB
  const uicolors = [r / 255, g / 255, b / 255]
  const c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92
    }
    return Math.pow((col + 0.055) / 1.055, 2.4)
  })
  const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2]
  return L > 0.179 ? colors.black900 : colors.white
}
