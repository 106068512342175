import React, { FC, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import FormButtons from '../../../components/forms/FormButtons'
import PageHeader from '../../../components/PageHeader'
import { useActivatePanel, usePanelInfo } from '../../../services/requestHooks/panelInfo'
import { usePanelId } from '../../AlarmSystems/hooks/usePanelId'
import FailedRequest from '../components/FailedRequest'
import OnboardingWizardSubPage from '../components/OnboardingWizardSubPage'
import { OnboardingWizardStep } from '../constants'
import { WizardContext } from '../WizardContext'

const step = OnboardingWizardStep.DONE

const Done: FC = () => {
  const [hasFailed, setHasFailed] = useState(false)
  const panelId = usePanelId()
  const history = useHistory()
  const { getUrl } = useContext(WizardContext)
  const { run: loadPanelInfo, isLoading: isLoadingPanelInfo } = usePanelInfo({
    onSuccess: () => {
      history.push(getUrl(step, 'forward'))
    },
    onFailure: () => {
      setHasFailed(true)
    },
  })
  const { run: activatePanel, isLoading: isActivating } = useActivatePanel({
    onSuccess: () => {
      loadPanelInfo(panelId)
    },
    onFailure: () => {
      setHasFailed(true)
    },
  })

  const onClick = () => {
    activatePanel({ PanelId: panelId })
  }

  return (
    <OnboardingWizardSubPage activeStep={step} backgroundImage>
      {!hasFailed ? (
        <>
          <PageHeader title="Congratulations" text="You are now ready to use your system." />
          <div className="mt-12 flex justify-center items-center">
            <FormButtons
              isLoading={isActivating || isLoadingPanelInfo}
              onClick={onClick}
              submitText="Go to you system"
              cancelUrl={getUrl(step, 'back')}
              cancelText="Back"
            />
          </div>
        </>
      ) : (
        <>
          <FailedRequest text="The system is not ready for activation. Please go back and try again when the system i ready." />
          <div className="mt-12 flex justify-center items-center">
            <FormButtons
              isLoading={isActivating || isLoadingPanelInfo}
              onClick={onClick}
              submitText="Retry"
              cancelUrl="/systems"
              cancelText="Go to alarm system list"
            />
          </div>
        </>
      )}
    </OnboardingWizardSubPage>
  )
}

export default Done
