import React, { FC, MouseEventHandler } from 'react'
import { Link } from 'react-router-dom'
import useI18n from '../../i18n/useI18n'
import { TranslationKey } from '../../types/generated/TranslationKey'
import Button from '../Button'
import CancelButton from '../Button/CancelButton'

type Props = {
  isValid?: boolean
  isLoading?: boolean
  submitText?: TranslationKey
  cancelText?: TranslationKey
  onClick?: MouseEventHandler<HTMLButtonElement>
  cancelUrl: string
  submitUrl?: string
}

const FormButtons: FC<Props> = ({
  isLoading,
  isValid = true,
  submitText = 'Save',
  cancelUrl,
  submitUrl,
  cancelText = 'Cancel',
  onClick,
}) => {
  const { t } = useI18n()

  const isPartOfForm = !onClick

  return (
    <>
      {submitUrl ? (
        <Link className="button button--primary" to={submitUrl}>
          {t(submitText)}
        </Link>
      ) : (
        <Button
          type={isPartOfForm ? 'submit' : 'button'}
          disabled={!isValid || isLoading}
          buttonType="primary"
          onClick={onClick}
        >
          {t(submitText)}
        </Button>
      )}
      <CancelButton cancelUrl={cancelUrl} isLoading={isLoading}>
        {t(cancelText)}
      </CancelButton>
    </>
  )
}

export default FormButtons
