import React, { ComponentType, FC, useMemo, useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Contacts from './Contacts'
import { AddNeighbors, AddOther, AddResidents } from './Contacts/SubPages'
import Directions from './Directions'
import Done from './Done'
import { useWizardUrl } from './useWizardUrl'
import SecurityQuestion from './SecurityQuestion'
import Start from './Start'
import { usePanelInfo } from '../../services/requestHooks/panelInfo'
import { useOnMount } from '../../utils/commonHooks'
import { usePanelId } from '../AlarmSystems/hooks/usePanelId'
import { InstallationStatus } from '../../types/PanelModel'
import LoadPage from '../../components/LoadPage'
import TwoFactorPrompt, {
  useTwoFactorPrompt,
} from '../../components/TwoFactorPrompt/TwoFactorPrompt'
import PasswordPrompt, { usePasswordPrompt } from '../AlarmSystems/Panel/PasswordPromt'
import { WizardContext, WizardContextType } from './WizardContext'
import { OnboardingWizardStep } from './constants'

const wizardBaseUrl = '/getting-started/:panelId'

type Step = {
  step: OnboardingWizardStep
  url: string
  component: ComponentType
  exact?: boolean
}

const coreSteps: Array<Step> = [
  {
    step: OnboardingWizardStep.DIRECTIONS,
    url: 'directions',
    component: Directions,
  },
  {
    step: OnboardingWizardStep.SECURITY_QUESTION,
    url: 'security-question',
    component: SecurityQuestion,
  },
  {
    step: OnboardingWizardStep.CONTACTS,
    url: 'contacts',
    exact: true,
    component: Contacts,
  },
  {
    step: OnboardingWizardStep.CONTACTS_RESIDENTS,
    url: 'contacts/residents',
    component: AddResidents,
  },
  {
    step: OnboardingWizardStep.CONTACTS_NEIGHBORS,
    url: 'contacts/neighbors',
    component: AddNeighbors,
  },
  {
    step: OnboardingWizardStep.CONTACTS_OTHER,
    url: 'contacts/other',
    component: AddOther,
  },
]

const startStep: Step = {
  step: OnboardingWizardStep.START,
  url: 'start',
  exact: true,
  component: Start,
}

const endStep: Step = {
  step: OnboardingWizardStep.DONE,
  url: 'done',
  component: Done,
}

const OnboardingWizardPage: FC = () => {
  const panelId = usePanelId()
  const { isLoading, run: loadPanelInfo, data: panelInfo } = usePanelInfo()
  const { promptForTwoFactor, promptState: twoFactorState } = useTwoFactorPrompt()
  const { promptForPassword, promptState: passwordState } = usePasswordPrompt()

  const [password, setPassword] = useState<string>()

  useOnMount(() => {
    if (!isLoading && panelId !== panelInfo?.PanelId) {
      loadPanelInfo(panelId)
    }
  })

  const remotleyTriggered = panelInfo?.InstallationStatus === InstallationStatus.ACTIVE
  const steps = useMemo(
    () => (remotleyTriggered ? coreSteps : [startStep, ...coreSteps, endStep]),
    [remotleyTriggered],
  )
  const { getUrl } = useWizardUrl(steps, wizardBaseUrl, '/systems/:panelId', '/systems')

  if (isLoading) {
    return <LoadPage isLoading />
  }

  if (!!panelInfo) {
    if (
      !panelInfo.DisplayWizard &&
      panelInfo.InstallationStatus !== InstallationStatus.INSTALLING
    ) {
      return <Redirect to="/404" />
    }
  }

  const loadData: WizardContextType['loadData'] = async (loadData, body, cancelUrl) => {
    if (remotleyTriggered) {
      const ValidationCode = await promptForTwoFactor(cancelUrl)
      if (ValidationCode) {
        loadData({ ...body, ValidationCode })
      }
    } else {
      loadData(body)
    }
  }

  const saveData: WizardContextType['saveData'] = async (saveAction, body) => {
    if (remotleyTriggered) {
      const ValidationCode = await promptForTwoFactor()
      if (ValidationCode) {
        const Password = password || (await promptForPassword())
        if (Password) {
          setPassword(Password)
          saveAction({ ...body, ValidationCode, Password })
        }
      }
    } else {
      saveAction(body)
    }
  }

  return (
    <>
      <WizardContext.Provider value={{ loadData, saveData, getUrl }}>
        <Switch>
          {steps.map(({ step, url, exact, component: Component }) => (
            <Route key={step} path={`${wizardBaseUrl}/${url}`} exact={exact}>
              <Component />
            </Route>
          ))}
          <Redirect to={getUrl(steps[0].step)} />
        </Switch>
        <PasswordPrompt promptState={passwordState} />
        <TwoFactorPrompt promptState={twoFactorState} />
      </WizardContext.Provider>
    </>
  )
}

export default OnboardingWizardPage
