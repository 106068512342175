export enum OnboardingWizardStep {
  START = 'Start',
  DIRECTIONS = 'Directions',
  SECURITY_QUESTION = 'Security question',
  CONTACTS = 'Contact persons',
  CONTACTS_RESIDENTS = 'Residents',
  CONTACTS_NEIGHBORS = 'Neighbours',
  CONTACTS_OTHER = 'ICE',
  DONE = 'Done',
}
