import React, { Fragment, MouseEventHandler, useContext } from 'react'
import classNames from 'classnames'
import { UserType } from '../constants/panelUserTypes'
import { User } from '../state/panelUsersSlice'
import Button from '../../../../../components/Button'
import PasswordPrompt, { usePasswordPrompt } from '../../../Panel/PasswordPromt'
import { TwofactorContext } from '../../../../../components/TwoFactorPrompt/TwoFactorPrompt'
import { PanelUsersIcon, TagIcon, UserIcon } from '../../../../../assets/icons'
import ExpandableSection, {
  ExpandableSectionSummary,
} from '../../../../../components/ExpandableSection/ExpandableSection'
import { SavePanelUserPayload } from '../../../../../services/settingsApi'
import useI18n from '../../../../../i18n/useI18n'
import { usePanelId } from '../../../hooks/usePanelId'
import { PanelUserChangeOperation } from '../../../../../types/UserDetails'

interface Props {
  user: User
  selected: boolean
  onSelect: () => void
  onEdit: (operation: PanelUserChangeOperation) => void
  onDelete: () => void
  saveUser: (arg: { operation: PanelUserChangeOperation; payload: SavePanelUserPayload }) => void
  disabled: boolean
}

const PanelUsersListItem = (props: Props) => {
  const { t } = useI18n()
  const panelId = usePanelId()
  const {
    selected,
    onSelect,
    onEdit,
    onDelete,
    saveUser,
    user: { HasCode, HasTag, Type, Name, IsActive },
    disabled,
  } = props
  const { promptForTwoFactor } = useContext(TwofactorContext)
  const { promptState, promptForPassword } = usePasswordPrompt()

  const collectUserPayload = async (): Promise<SavePanelUserPayload | undefined> => {
    const ValidationCode = await promptForTwoFactor()
    if (!ValidationCode) return
    const password = await promptForPassword()
    if (!password) return
    return {
      Index: props.user.Index,
      Name: props.user.Name,
      Password: password,
      ValidationCode,
      PanelCode: '',
      PanelId: panelId,
      UserType: Type,
    }
  }

  const toggleUserActivation: MouseEventHandler = async (event) => {
    event.preventDefault()
    const payload = await collectUserPayload().catch()
    if (payload) {
      const operation = props.user.IsActive
        ? PanelUserChangeOperation.DEACTIVATE
        : PanelUserChangeOperation.ACTIVATE
      saveUser({ payload, operation })
    }
  }

  const isTempUser = Type !== UserType.PERMANENT_USER

  return (
    <Fragment>
      <ExpandableSection
        className={classNames('status-list__item fadeIn', { 'user-active': selected })}
        open={selected}
        onClick={onSelect}
        hideIndicator
      >
        <ExpandableSectionSummary className="status-list__divSelector flex flex-auto justify-between">
          <div className="flex">
            <UserIcon
              className={classNames(
                'icon icon--tiny icon--left margin-top',
                `icon--user-${isTempUser ? 'temp' : 'permanent'}`,
              )}
            />
            <h3 className="margin-padding">{Name}</h3>
          </div>
          <div className="flex">
            {HasCode && !isTempUser && (
              <PanelUsersIcon className="status-list__icon-right svg-margin" />
            )}
            {HasTag && !isTempUser && <TagIcon className="status-list__icon-right svg-margin" />}
            {isTempUser && (
              <div className="gray margin-right">
                {IsActive ? (
                  <strong className="active-color">{t('ACTIVE')}</strong>
                ) : (
                  <span>{t('Not active')}</span>
                )}
              </div>
            )}
          </div>
        </ExpandableSectionSummary>
        {selected && (
          <div className="user-info">
            <Button
              buttonType="secondary"
              size="small"
              onClick={() => onEdit(PanelUserChangeOperation.CHANGE_NAME)}
              disabled={disabled}
            >
              {t('Edit name')}
            </Button>
            <Button
              buttonType="secondary"
              size="small"
              onClick={() => onEdit(PanelUserChangeOperation.CHANGE_CODE)}
              disabled={disabled}
            >
              {t('Edit panel code')}
            </Button>
            <Button buttonType="secondary" size="small" onClick={onDelete} disabled={disabled}>
              {t('Delete')}
            </Button>
            {isTempUser && (
              <Button
                size="small"
                onClick={toggleUserActivation}
                buttonType={IsActive ? 'third' : 'primary'}
                disabled={disabled}
              >
                {t(IsActive ? 'Deactivate' : 'ACTIVATE')}
              </Button>
            )}
          </div>
        )}
      </ExpandableSection>
      <PasswordPrompt promptState={promptState} />
    </Fragment>
  )
}
export default PanelUsersListItem
