import { UPDATE_DOORSANDWINDOWS } from '../../reducers/doorsandwindowsSlice'
import { RootState } from '../../store'
import { housecheckDoorsAndWindows } from '../apiHouseCheck'
import { createUseRequest } from './createUseRequest'

const selectDoorsAndWindows = (state: RootState, panelId: string) =>
  state.doorsAndWindowsList[panelId]?.doorsandwindows

export const useDoorsAndWindows = createUseRequest({
  successActionCreator: UPDATE_DOORSANDWINDOWS,
  apiCall: housecheckDoorsAndWindows,
  selector: selectDoorsAndWindows,
  requirePanelId: true,
})
