import {
  CREATE_USER_SUCCESS,
  REQUEST_SMS,
  VALIDATE_SMS_SUCCESS,
} from '../../reducers/simpleRegistrationSlice'
import { RootState } from '../../store'
import {
  createUserVerifyPin,
  createUserCommit,
  requestCustomerRegistrationSMS,
} from '../apiAccount'
import { createUseRequest } from './createUseRequest'

const selectPinCode = (state: RootState) => state.simpleRegistration.PinCode
const selectHasCreatedUser = (state: RootState) => state.simpleRegistration.hasCreatedUser

export const useCreateUserVerifyPin = createUseRequest({
  successActionCreator: VALIDATE_SMS_SUCCESS,
  apiCall: createUserVerifyPin,
  selector: selectPinCode,
})

export const useCreateUser = createUseRequest({
  successActionCreator: CREATE_USER_SUCCESS,
  apiCall: createUserCommit,
  selector: selectHasCreatedUser,
})

export const useRequestCustomerRegistrationSMS = createUseRequest({
  successActionCreator: REQUEST_SMS,
  apiCall: requestCustomerRegistrationSMS,
})
