import {
  CHECK_SECURITY_QUESTION,
  GET_LAST_PHONE_DIGITS,
  SET_SECURITY_QUESTION,
} from '../../reducers/securityQuestionSlice'
import { RootState } from '../../store'
import { getLastPhoneDigits } from '../apiAccount'
import { getCodeWordStatus, setSecurityQuestion } from '../settingsApi'
import { createUseRequest } from './createUseRequest'

const selectHasSecurityQuestion = (state: RootState, panelId: string) =>
  state.securityQuestion.questionState[panelId]?.hasCodeWord
const selectLastPhoneDigits = (state: RootState) => state.securityQuestion.lastPhoneDigits

export const useHasSecurityQuestion = createUseRequest({
  successActionCreator: CHECK_SECURITY_QUESTION,
  apiCall: getCodeWordStatus,
  selector: selectHasSecurityQuestion,
  requirePanelId: true,
})

export const useLastPhoneDigits = createUseRequest({
  successActionCreator: GET_LAST_PHONE_DIGITS,
  apiCall: getLastPhoneDigits,
  selector: selectLastPhoneDigits,
})

export const useSetSecurityQuestion = createUseRequest({
  successActionCreator: SET_SECURITY_QUESTION,
  apiCall: setSecurityQuestion,
  requirePanelId: true,
})
