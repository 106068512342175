import React from 'react'
import { TranslationKey } from '../../../types/generated/TranslationKey'
import classNames from 'classnames'
import useI18n from '../../../i18n/useI18n'

const formErrorMapping: Record<string, TranslationKey> = {
  existing_email: 'The email is already registered',
  customer_exists: 'The customer number is already registered in the system',
  to_many_attempts:
    'The customer number has been blocked for registration throug mypage. Please contact customer service.',
  default: 'Something went wrong. Please try again',
}

const FormError = ({ errorMessage, size }: { errorMessage?: string; size?: 'small' | 'big' }) => {
  const { t } = useI18n()
  if (!errorMessage) return null

  return (
    <div>
      <div
        className={classNames(
          'notification--error',
          size === 'big' && 'text-2xl',
          size === 'small' && 'text-sm',
        )}
      >
        {t(formErrorMapping[errorMessage] || formErrorMapping['default'])}
      </div>
    </div>
  )
}

export default FormError
