import React, { FormEventHandler, MouseEventHandler, useState } from 'react'
import useI18n from '../../../i18n/useI18n'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import Modal from '../../../components/Modal'

interface Props {
  promptState: {
    onSubmit?: (password: string) => void
    onCancel?: MouseEventHandler
    panelId: string
  }
}

const PinCodePrompt = ({ promptState: { onSubmit, onCancel, panelId } }: Props) => {
  const { t } = useI18n()
  const codeLength = useSelector((state: RootState) => state.panel[panelId]?.Info?.PanelCodeLength)
  const [pinCode, setPinCode] = useState('')

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault()
    if (!onSubmit) throw Error('Form was submitted with invalid values, this not should not happen')
    onSubmit(pinCode)
    setPinCode('')
  }

  if (!onSubmit || !onCancel) return null
  return (
    <Modal fullscreen handleClose={onCancel}>
      <div className="password__area">
        <form autoComplete="new-password" onSubmit={handleSubmit}>
          <label className="password__label">
            {t('Enter alarm code')}
            <input
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              value={pinCode}
              type="password"
              className="password__field"
              maxLength={codeLength}
              onChange={(e) => setPinCode(e.target.value)}
            />
          </label>
          <input
            type="submit"
            className="button button--third"
            value={t('Confirm')}
            disabled={pinCode.length !== codeLength}
          />
        </form>
      </div>
    </Modal>
  )
}

interface PromtHandlers {
  onSubmit: (pinCode: string) => void
  onCancel: () => void
}

export const usePinCodePrompt = (
  panelId: string,
): {
  promptForPinCode: () => Promise<string | undefined>
} & Props => {
  const [handlers, setHandlers] = useState<PromtHandlers | undefined>()
  const promptForPinCode = () => {
    return new Promise<string | undefined>((resolve) => {
      setHandlers({
        onSubmit: (password) => {
          setHandlers(undefined)
          resolve(password)
        },
        onCancel: () => {
          setHandlers(undefined)
          resolve(undefined)
        },
      })
    })
  }

  return {
    promptForPinCode,
    promptState: {
      onSubmit: handlers?.onSubmit,
      onCancel: handlers?.onCancel,
      panelId,
    },
  }
}

export default PinCodePrompt
