import {
  SAVED_PANEL_USER,
  SET_PANEL_USERS,
} from '../../pages/AlarmSystems/Settings/PanelUsers/state/panelUsersSlice'
import { RootState } from '../../store'
import { getPanelUsers, panelUserChange } from '../settingsApi'
import { createUseRequest } from './createUseRequest'

export const useGetPanelUsers = createUseRequest({
  successActionCreator: SET_PANEL_USERS,
  apiCall: getPanelUsers,
  selector: (state: RootState, panelId: string) => state.panelUsers[panelId]?.usersInfo,
  requirePanelId: true,
})

export const useSavePanelUser = createUseRequest({
  successActionCreator: SAVED_PANEL_USER,
  apiCall: panelUserChange,
  requirePanelId: true,
})
