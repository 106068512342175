import { LOAD_INVOICES_SUCCESS, LOAD_INVOICE_PDF_SUCCESS } from '../../reducers/invoiceSlice'
import { RootState } from '../../store'
import { getInvoices, getInvoicePDF } from '../apiAccount'
import { createUseRequest } from './createUseRequest'

export const useLoadInvoices = createUseRequest({
  apiCall: getInvoices,
  successActionCreator: LOAD_INVOICES_SUCCESS,
  selector: (state: RootState) => state.invoices.Invoices,
})

export const useLoadInvoicePDF = createUseRequest({
  apiCall: getInvoicePDF,
  successActionCreator: LOAD_INVOICE_PDF_SUCCESS,
})
