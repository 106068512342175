import { SET_TERMS } from '../../reducers/termsSlice'
import { RootState } from '../../store'
import { getTerms } from '../apiLogin'
import { createUseRequest } from './createUseRequest'

const selectTerms = (state: RootState) => state.terms

export const useGetTerms = createUseRequest({
  apiCall: getTerms,
  successActionCreator: SET_TERMS,
  selector: selectTerms,
})
