import { createSlice } from '@reduxjs/toolkit'
import { PanelAction, PanelPayloadAction } from '../services/requestHooks/PanelAction'

type AddDeviceState = Record<
  string,
  {
    serial?: string
  }
>

const initialState: AddDeviceState = {}

// TODO: Consider putting all this state into AddPanelDevice, its not used anywhere else(?) so should not be in global store

const addDeviceSlice = createSlice({
  name: 'addSmartplug',
  initialState,
  reducers: {
    ADD_DEVICE_START(state, { payload: { panelId } }: PanelAction) {
      state[panelId] = { serial: undefined }
    },
    ADD_DEVICE_CONTINUE(state, { payload: { panelId, data } }: PanelPayloadAction<string>) {
      if (state[panelId]) {
        state[panelId].serial = data
      }
    },
    ADD_DEVICE_FINISH(state, { payload: { panelId } }: PanelAction) {
      delete state[panelId]
    },
  },
})

// eslint-disable-next-line import/no-unused-modules
export const { ADD_DEVICE_START, ADD_DEVICE_CONTINUE, ADD_DEVICE_FINISH } = addDeviceSlice.actions

export default addDeviceSlice.reducer
