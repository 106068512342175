export enum ContactPersonType {
  RESIDENT = 0,
  NEIGHBOR = 1,
  OTHER = 2,
}

export enum PhoneNumberType {
  MOBILE = 'Mobile',
  HOME = 'Home',
  WORK = 'Work',
}

export type ContactPerson = {
  FirstName: string
  LastName: string
  Home: string
  Mobile: string
  Work: string
  Type: ContactPersonType
}
