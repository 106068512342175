import React, { ComponentType, FC, useContext, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { usePanelId } from '../pages/AlarmSystems/hooks/usePanelId'
import InternalErrorPage from '../pages/errors/InternalErrorPage'
import { usePanelInfo } from '../services/requestHooks/panelInfo'
import { Capability } from '../types/PanelModel'
import { useOnMount } from '../utils/commonHooks'
import { Loader } from './Elements/Loaders'

const NotFound = () => <Redirect to="/404" />

type Props = {
  CrowComponent?: ComponentType
  DomoComponent?: ComponentType
}

const CrowContext = React.createContext({ isCrow: true })
export const useIsCrow = () => useContext(CrowContext).isCrow

const CrowToggle: FC<Props> = ({ CrowComponent = NotFound, DomoComponent = NotFound }) => {
  const panelId = usePanelId()
  const [showLoader, setShowLoader] = useState(true)
  const [requestFailed, setRequestFailed] = useState(false)
  const hideLoader = () => setShowLoader(false)

  const { run: loadPanelInfo, data: panelInfo, isLoading, error: loadPanelError } = usePanelInfo({
    onSuccess: hideLoader,
    onFailure: () => {
      setRequestFailed(true)
      hideLoader()
    },
  })

  useOnMount(() => {
    if (!isLoading && (!panelInfo || panelInfo?.PanelId !== panelId)) {
      loadPanelInfo(panelId)
    } else {
      hideLoader()
    }
  })

  if (showLoader) {
    return (
      <div className="flex flex-1 justify-center items-center">
        <Loader types={['large']} />
      </div>
    )
  }

  if (requestFailed) {
    return <InternalErrorPage text={loadPanelError} />
  }

  if (panelInfo?.Capabilities.includes(Capability.USE_LEGACY_HOMESCREEN)) {
    return <DomoComponent />
  } else {
    return (
      <CrowContext.Provider value={{ isCrow: true }}>
        <CrowComponent />
      </CrowContext.Provider>
    )
  }
}

export default CrowToggle
