import React from 'react'
import Button from '../../../../components/Button'
import Modal from '../../../../components/Modal'
import { ModalPromptProps } from '../../../../components/Modal/Modal'
import useI18n from '../../../../i18n/useI18n'

const RemoveVideoControlModal = ({ show, onSubmit, onCancel }: ModalPromptProps<true>) => {
  const { t } = useI18n()

  const handleSave = () => {
    onSubmit?.(true)
  }

  const handleCancel = () => {
    onCancel?.()
  }

  return (
    <>
      {show && (
        <Modal>
          <h1>{t('VIDEO_CONTROL_TITLE')}</h1>
          <p className="mt-8">{t('VIDEO_CONTROL_REMOVE_DESCRIPTION')}</p>
          <div>
            <Button buttonType="primary" onClick={handleSave}>
              {t('Save')}
            </Button>
            <Button buttonType="link" onClick={handleCancel}>
              {t('Cancel')}
            </Button>
          </div>
        </Modal>
      )}
    </>
  )
}

export default RemoveVideoControlModal
