import './add-button.scss'
import React, { ComponentProps, FC } from 'react'
import cx from 'classnames'

type Props = ComponentProps<'button'>

const AddButton: FC<Props> = ({ className, children, disabled, ...rest }) => {
  return (
    <label className={cx('add-button', disabled && 'add-button--disabled', className)}>
      <button
        className="button button--primary add-button add-button__icon"
        disabled={disabled}
        {...rest}
      >
        +
      </button>
      {children}
    </label>
  )
}

export default AddButton
