import './expandable-list.scss'
import React, { FC, MouseEventHandler, ReactNode } from 'react'
import cx from 'classnames'
import ExpandableSection, {
  ExpandableSectionSummary,
} from '../../ExpandableSection/ExpandableSection'
import { Loader } from '../../Elements/Loaders'
import { TranslationKey } from '../../../types/generated/TranslationKey'
import useI18n from '../../../i18n/useI18n'

type Props = {
  open?: boolean
  onClick?: MouseEventHandler
  icon: ReactNode
  summary: ReactNode
  isLoading?: boolean
  error?: TranslationKey
}

const ExpandableList: FC<Props> = ({
  open,
  onClick,
  icon,
  summary,
  children,
  isLoading,
  error,
}) => {
  const { t } = useI18n()

  const handleClick: MouseEventHandler = (event) => {
    if (onClick) {
      event.preventDefault()
      onClick(event)
    }
  }

  const showIcons = window.screen.availWidth > 320

  return (
    <div className="expandable-list">
      {showIcons && <div className="expandable-list__icon">{icon}</div>}
      <ExpandableSection open={open} onClick={handleClick} className="expandable-list__section">
        <ExpandableSectionSummary className="expandable-list__summary">
          <div className="expandable-list__text">{summary}</div>
          <div>{isLoading && <Loader as="span" />}</div>
        </ExpandableSectionSummary>
        {open &&
          !isLoading &&
          (error ? (
            <p className="small notification notification--error notification--align-left">
              {t(error)}
            </p>
          ) : (
            <div className="overflow-hidden">
              <ul className="expandable-list__list slide-down">{children}</ul>
            </div>
          ))}
      </ExpandableSection>
    </div>
  )
}

export const ExpandableListItem: FC<{ className?: string }> = ({ children, className }) => {
  return <li className={cx('expandable-list__item', className)}>{children}</li>
}
export default ExpandableList
