import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CrowTemperature, Temperature } from '../types/Temperature'

type TemperatureListState = Record<string, { temperatures?: Temperature[] } | undefined>
type TemperatureListHouseCheckState = Record<string, { temperatures?: CrowTemperature } | undefined>

const initialState: TemperatureListState = {}
const initialHouseCheckState: TemperatureListHouseCheckState = {}

type UpdateTemperaturesPayload = Temperature[]
type UpdateTemperaturesPayloadHouseCheck = CrowTemperature

const temperatureListSlice = createSlice({
  name: 'temperatureList',
  initialState,
  reducers: {
    UPDATE_TEMPERATURES(
      state,
      action: PayloadAction<{ data: UpdateTemperaturesPayload; panelId: string }>,
    ) {
      const { data, panelId } = action.payload
      state[panelId] = { temperatures: data }
    },
  },
})

// /api/housecheck/temperatures
const temperatureHousecheckSlice = createSlice({
  name: 'temperatureHousecheck',
  initialState: initialHouseCheckState,
  reducers: {
    POST_TEMPERATURES(
      state,
      action: PayloadAction<{ data: UpdateTemperaturesPayloadHouseCheck; panelId: string }>,
    ) {
      const { data, panelId } = action.payload
      state[panelId] = { temperatures: data }
    },
  },
})

export const UPDATE_TEMPERATURES = temperatureListSlice.actions.UPDATE_TEMPERATURES

export const POST_TEMPERATURES = temperatureHousecheckSlice.actions.POST_TEMPERATURES

export const temperatureListReducer = temperatureListSlice.reducer

export const temperatureHousecheckReducer = temperatureHousecheckSlice.reducer
