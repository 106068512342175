import React, { FC, MouseEventHandler } from 'react'
import { Link } from 'react-router-dom'
import CloseButton from '../../../components/Button/CloseButton'
import useI18n from '../../../i18n/useI18n'
import { usePanelId } from '../hooks/usePanelId'

type Props = {
  closeWizard: MouseEventHandler<HTMLButtonElement>
}

const WizardLink: FC<Props> = ({ closeWizard }) => {
  const { t } = useI18n()
  const panelId = usePanelId()

  return (
    <div className="notification notification--message mb-4">
      <CloseButton className="right-0" onClick={closeWizard} iconOnly />
      <h2 className="text-base px-4 mt-10">
        {t(
          'We have added new features which you have to configure. Would you like to do this now? If you decline, you can configure them later in settings.',
        )}
      </h2>
      <div className="mt-4">
        <Link to={`/getting-started/${panelId}`} className="button button--primary button--small">
          {t('Configure now')}
        </Link>
      </div>
    </div>
  )
}

export default WizardLink
