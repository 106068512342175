import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NotificationSetting } from '../types/NotificationSetting'

type NotificationSettingsState = {
  settings: NotificationSetting[]
}

const initialState: NotificationSettingsState = {
  settings: [],
}

type GetNotificationSettingsPayload = NotificationSetting[]
type ToggleNotificationSettingPayload = {
  Channel: string
  Enabled: boolean
  EventType: string
}

const notificationSettingsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    GET_NOTIFICATION_SETTINGS(state, action: PayloadAction<GetNotificationSettingsPayload>) {
      state.settings = action.payload
    },
    TOGGLE_NOTIFICATION_SETTING(
      state,
      { payload: { Channel, Enabled, EventType } }: PayloadAction<ToggleNotificationSettingPayload>,
    ) {
      const chosenSetting = state.settings.find((setting) => setting.Channel === Channel)
      const chosenEvent = chosenSetting?.Events.find((event) => event.EventType === EventType)
      if (chosenEvent) {
        chosenEvent.Enabled = Enabled
      }
    },
  },
})

// eslint-disable-next-line import/no-unused-modules
export const {
  GET_NOTIFICATION_SETTINGS,
  TOGGLE_NOTIFICATION_SETTING,
} = notificationSettingsSlice.actions

export const notificationSettingsReducer = notificationSettingsSlice.reducer
