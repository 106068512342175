import { createContext, useContext } from 'react'
import { IMPERSONATE_KEY } from './constants'

export const setImpersonationId = (impersonationId: string) =>
  sessionStorage.setItem(IMPERSONATE_KEY, impersonationId)
export const getImpersonationId = () => sessionStorage.getItem(IMPERSONATE_KEY)

export let getToken = (): Promise<string> => {
  throw new Error('getToken not initialized')
}
export const setGetToken = (func: () => Promise<string>) => {
  getToken = func
}

type AuthContextType = {
  isLoading: boolean
  isAuthenticated: boolean
  login: (returnTo?: string) => void
  logout: () => void
}

const initialAuth0Context: AuthContextType = {
  isLoading: true,
  isAuthenticated: false,
  login: () => {},
  logout: () => {},
}

export const AuthContext = createContext(initialAuth0Context)

export const useAuth = () => useContext(AuthContext)
