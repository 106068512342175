import useI18n from '../../i18n/useI18n'

interface FloorNameType {
  floorName: string
}

const FloorName = ({ floorName }: FloorNameType) => {
  const { t } = useI18n()

  switch (floorName) {
    case 'OUTDOOR_PLACE':
      return t('OUTDOOR_PLACE')
    case 'UNPLACED_PLACE':
      return t('UNPLACED_PLACE')
    case 'BASEMENT_PLACE':
      return t('BASEMENT_PLACE')
    case 'GROUND_FLOOR_PLACE':
      return t('GROUND_FLOOR_PLACE')
    case '1ST_FLOOR_PLACE':
      return t('1ST_FLOOR_PLACE')
    case '2RD_FLOOR_PLACE':
      return t('2RD_FLOOR_PLACE')
    case '3RD_FLOOR_PLACE':
      return t('3RD_FLOOR_PLACE')
    case '4TH_FLOOR_PLACE':
      return t('4TH_FLOOR_PLACE')
    case 'ATTIC_PLACE':
      return t('ATTIC_PLACE')
    case 'UNKNOWN':
      return t('Unknown')
    default:
      return t('Unknown')
  }
}

export default FloorName
