import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'core-js/es'
import './index.scss'

import React, { Suspense } from 'react'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n/i18n' // initialized i18next instance
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'

import store from './store'
import { Loader } from './components/Elements/Loaders'
import AuthProvider from './services/authentication/AuthProvider'
import history from './utils/history'
import DomonialApp from './App/App'

render(
  <Suspense
    fallback={
      <div className="flex flex-col justify-center items-center flex-1">
        <Loader types={['large']} />
      </div>
    }
  >
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <AuthProvider>
          <Router history={history}>
            <DomonialApp />
          </Router>
        </AuthProvider>
      </Provider>
    </I18nextProvider>
  </Suspense>,
  document.getElementById('root'),
)
