import { LOAD_MESSAGES_SUCCESS, LOAD_SINGLE_MESSAGE_SUCCESS } from '../../reducers/messagesSlice'
import { RootState } from '../../store'
import { getMessages, getMessageById } from '../apiAccount'
import { createUseRequest } from './createUseRequest'

export const useLoadMessages = createUseRequest({
  apiCall: getMessages,
  successActionCreator: LOAD_MESSAGES_SUCCESS,
  selector: (state: RootState) => state.messages.Messages,
})

export const useLoadMessageById = createUseRequest({
  apiCall: getMessageById,
  successActionCreator: LOAD_SINGLE_MESSAGE_SUCCESS,
  selector: (state: RootState) => state.messages.Message,
})
