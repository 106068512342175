import axios from 'axios'
import { baseUrl } from '../configs'
import i18n from '../i18n/i18n'
import { UserState } from '../reducers/userSlice'
import { TermsType } from '../types/Terms'
import { UserCultureInfo, UserDetails } from '../types/UserDetails'
import { isRunningCypress } from '../utils/tests/isRunningCypress'
import createHTTPClient from './createHTTPClient'

const HTTP = createHTTPClient('/Login')

export const resetPassword = (email: string) => HTTP.post(`/ForgotPassword?email=${email}`, {})

export const getAccountInfo = async () => {
  const data = await HTTP.get<{
    User: UserDetails
    Resources: UserState['Resources']
    DefaultPanelId: string
    redirect?: boolean
  }>('/GetUser')
  if ((i18n.language as UserCultureInfo) !== data.User.UserCultureInfo && !isRunningCypress()) {
    i18n.changeLanguage(data.User.UserCultureInfo)
  }
  return data
}

export const getTerms = async (nationId: number, culture: UserCultureInfo) => {
  const terms = (
    await axios.get<TermsType>(`${baseUrl}/Login/GetTerms?nationId=${nationId}&culture=${culture}`)
  ).data
  return {
    nationId,
    culture,
    terms,
  }
}

export const activateAccount = async (nonce: string) => {
  return (
    await axios.post<void>(`${baseUrl}/Login/ActivateAccount`, undefined, {
      params: { nonce },
    })
  ).data
}
