import { TranslationKey } from '../../../../../types/generated/TranslationKey'
import { ScenarioTrigger } from './ScenarioTrigger'

export const getScenarioConflict = (
  { field }: { field: keyof ScenarioTrigger },
  scenario: ScenarioTrigger,
): [TranslationKey, (keyof ScenarioTrigger)[]] | undefined => {
  const {
    OnAlarmTurnOn,
    OnArmingTurnOff,
    OnArmingTurnOn,
    OnDisarmingTurnOff,
    OnDisarmingTurnOn,
  } = scenario
  switch (field) {
    case 'OnArmingTurnOn':
      if (OnDisarmingTurnOn && !OnAlarmTurnOn)
        return ['Smartplug on when alarm is dissarmed', ['OnDisarmingTurnOn']]
      if (!OnDisarmingTurnOn && OnAlarmTurnOn)
        return ['Smartplug of when alarm goes off', ['OnAlarmTurnOn']]
      if (OnDisarmingTurnOn && OnAlarmTurnOn)
        return [
          'Smartplug on when alarm goes off and smartplug on when alarm is dissarmed',
          ['OnDisarmingTurnOn', 'OnAlarmTurnOn'],
        ]
      return undefined
    case 'OnArmingTurnOff':
      if (OnDisarmingTurnOff)
        return ['Smartplug off when alarm is dissarmed', ['OnDisarmingTurnOff']]
      return undefined
    case 'OnDisarmingTurnOn':
      if (OnArmingTurnOn) return ['Smartplug on when alarm is armed', ['OnArmingTurnOn']]
      return undefined
    case 'OnDisarmingTurnOff':
      if (!OnArmingTurnOff && OnAlarmTurnOn)
        return ['Smartplug on when alarm goes off', ['OnAlarmTurnOn']]
      if (OnArmingTurnOff && !OnAlarmTurnOn)
        return ['Smartplug of when alarm is armed', ['OnArmingTurnOff']]
      if (OnArmingTurnOff && OnAlarmTurnOn)
        return [
          'Smartplug on when alarm goes of and smartplug of when alarm is armed',
          ['OnArmingTurnOff', 'OnAlarmTurnOn'],
        ]
      return undefined
    case 'OnAlarmTurnOn':
      if (!OnArmingTurnOn && OnDisarmingTurnOff)
        return ['Smartplug of when alarm is armed', ['OnArmingTurnOff']]
      if (OnArmingTurnOn && !OnDisarmingTurnOff)
        return ['Smartplug on when alarm is armed', ['OnArmingTurnOn']]
      if (OnArmingTurnOn && OnDisarmingTurnOff)
        return [
          'Smartplug of when alarm is disarmed and smartplug on when alarm is armed',
          ['OnDisarmingTurnOff', 'OnArmingTurnOn'],
        ]
      return undefined
    default:
      return undefined
  }
}
