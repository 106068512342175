import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SmokeDetectors } from '../types/SmokeDetectors'

type SmokeDetectorsState = Record<string, { smokeDetectors?: SmokeDetectors } | undefined>

const initialState: SmokeDetectorsState = {}

type UpdateSmokeDetectorsPayload = SmokeDetectors

const smokeDetectorsSlice = createSlice({
  name: 'smokeDetectors',
  initialState,
  reducers: {
    UPDATE_SMOKEDETECTORS(
      state,
      action: PayloadAction<{ data: UpdateSmokeDetectorsPayload; panelId: string }>,
    ) {
      const { data, panelId } = action.payload
      state[panelId] = { smokeDetectors: data }
    },
  },
})

export const UPDATE_SMOKEDETECTORS = smokeDetectorsSlice.actions.UPDATE_SMOKEDETECTORS

export const smokeDetectorsReducer = smokeDetectorsSlice.reducer
