import React, { FC, useContext } from 'react'
import FormButtons from '../../../components/forms/FormButtons'
import PageHeader from '../../../components/PageHeader'
import OnboardingWizardSubPage from '../components/OnboardingWizardSubPage'
import { OnboardingWizardStep } from '../constants'
import { WizardContext } from '../WizardContext'

const step = OnboardingWizardStep.START

const Start: FC = () => {
  const { getUrl } = useContext(WizardContext)

  return (
    <OnboardingWizardSubPage activeStep={step} backgroundImage>
      <PageHeader
        title="Welcome to your new alarm system"
        text="We just needs a few more details to activate your system."
      />
      <div className="mt-12 flex justify-center items-center">
        <FormButtons
          submitText="Next"
          cancelUrl={getUrl(step, 'back')}
          submitUrl={getUrl(step, 'forward')}
        />
      </div>
    </OnboardingWizardSubPage>
  )
}

export default Start
