import React, { ComponentType, FC } from 'react'
import { Link } from 'react-router-dom'
import useI18n from '../../../../i18n/useI18n'
import { TranslationKey } from '../../../../types/generated/TranslationKey'

type Props = {
  url: string
  title: TranslationKey
  description: TranslationKey
  svg: ComponentType<{ className?: string }>
}

const SettingMenuItem: FC<Props> = ({ url, title, description, svg: SvgIcon }) => {
  const { t } = useI18n()

  return (
    <Link to={'settings/' + url} className="settings__item">
      <div className="settings__side">
        <SvgIcon className="icon icon--small" />
      </div>
      <div className="settings__main">
        <h2 className="settings__title text-xl">{t(title)}</h2>
        <p>{t(description)}</p>
      </div>
    </Link>
  )
}
export default SettingMenuItem
