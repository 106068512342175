import { createContext } from 'react'
import { OnboardingWizardStep } from './constants'

export type WizardContextType = {
  loadData: <Body extends object>(
    loadAction: (body: Body & { ValidationCode?: string }) => void,
    body: Body,
    cancelUrl: string,
  ) => Promise<void>
  saveData: <Body extends object>(
    saveAction: (body: Body & { ValidationCode?: string; Password?: string }) => void,
    body: Body,
  ) => Promise<void>
  getUrl: (step: OnboardingWizardStep, direction?: 'forward' | 'back') => string
}

export const WizardContext = createContext<WizardContextType>({
  loadData: () => Promise.resolve(undefined),
  saveData: () => Promise.resolve(undefined),
  getUrl: () => '/',
})
