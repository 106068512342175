import { createSlice } from '@reduxjs/toolkit'

interface RegisterStore {
  countryCode?: string
  step: number
}

const initialState: RegisterStore = {
  countryCode: undefined,
  step: 0,
}

const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    VALIDATE_CUSTOMER_PHONE_SUCCESS(state, action) {
      state.countryCode = action.payload
      state.step = 1
    },
    VALIDATE_NEW_CUSTOMER_SMS_CODE_SUCCESS(state) {
      state.step = 2
    },
    CREATE_ACCOUNT_SUCCESS(state) {
      state.step = 3
    },
    SEND_USER_REGISTRATION_EMAIL(state) {
      state.step = 3
    },
  },
})

// eslint-disable-next-line import/no-unused-modules
export const {
  VALIDATE_CUSTOMER_PHONE_SUCCESS,
  VALIDATE_NEW_CUSTOMER_SMS_CODE_SUCCESS,
  CREATE_ACCOUNT_SUCCESS,
  SEND_USER_REGISTRATION_EMAIL,
} = registrationSlice.actions

export default registrationSlice.reducer
