import React, { FC, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import FormButtons from '../../../components/forms/FormButtons'
import { ContactsHeader } from '../../AlarmSystems/Settings/Contacts/ContactsPage'

import OnboardingWizardSubPage from '../components/OnboardingWizardSubPage'
import { OnboardingWizardStep } from '../constants'
import { WizardContext } from '../WizardContext'

const step = OnboardingWizardStep.CONTACTS

const Contacts: FC = () => {
  const { getUrl } = useContext(WizardContext)
  const history = useHistory()

  const onClick = () => {
    const nextUrl = getUrl(step, 'forward')
    history.push(nextUrl)
  }

  return (
    <OnboardingWizardSubPage activeStep={step}>
      <ContactsHeader />
      <div className="mt-12">
        <FormButtons
          submitText="Next"
          cancelUrl={getUrl(step, 'back')}
          cancelText="Back"
          onClick={onClick}
        />
      </div>
    </OnboardingWizardSubPage>
  )
}

export default Contacts
