import React, { FC, useEffect } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import * as yup from 'yup'
import FormButtons from '../../../../components/forms/FormButtons'
import TextAreaField from '../../../../components/forms/TextAreaField'
import { RootState } from '../../../../store'
import { TranslationKey } from '../../../../types/generated/TranslationKey'
import useI18n from '../../../../i18n/useI18n'

export type DirectionsFormValues = {
  directions: string | undefined
}

type Props = {
  onSubmit: (values: DirectionsFormValues) => void
  defaultValues?: DirectionsFormValues
  cancelUrl: string
  submitText?: TranslationKey
  cancelText?: TranslationKey
  isLoading?: boolean
  required?: boolean
}

const DirectionsForm: FC<Props> = ({
  onSubmit,
  defaultValues,
  cancelUrl,
  submitText = 'Save description',
  cancelText = 'Cancel',
  isLoading,
  required,
}) => {
  const directions = useSelector((state: RootState) => state.directions.directions)
  const { t } = useI18n()

  const baseValidator = yup
    .string()
    .test(
      'min-length',
      t('At least 2 characters is required'),
      (value: string | undefined) => !value || value.length >= 2,
    )
    .test(
      'must-differ',
      t('Must_differ'),
      (value: string | undefined) => value !== defaultValues?.directions,
    )
    .matches(
      /^[\x20-\x7E\xA0-\xA3\xA5\xA7\xA9-\xB3\xB5-\xB7\xB9-\xBB\xBF-\xFF\u20AC\u0160\u0161\u017D\u017E\u0152\u0153\u0178\r\n]*$/,
      t('Special characters are not allowed'),
    )

  const requiredValidator = baseValidator.required('Required')

  const resolver = (required?: boolean) =>
    yup.object({ directions: required ? requiredValidator : baseValidator })

  const {
    handleSubmit,
    register,
    formState: { isValid, errors },
    setValue,
  } = useForm<DirectionsFormValues>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues,
    resolver: yupResolver(resolver(required)),
  })

  useEffect(() => {
    setValue('directions', directions)
  }, [directions, setValue])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextAreaField
        label="Add a description"
        {...register('directions')}
        rows={4}
        cols={50}
        placeholder="E.g. After the church, it's the second house on your right hand side. Around the back there is a veranda entrance accessible from the stairs."
        errorKey={errors.directions?.message as TranslationKey}
      />
      <FormButtons
        isLoading={isLoading}
        isValid={isValid}
        submitText={submitText}
        cancelUrl={cancelUrl}
        cancelText={cancelText}
      />
    </form>
  )
}

export default DirectionsForm
