import React from 'react'
import { Redirect } from 'react-router'
import { usePanelId } from '../AlarmSystems/hooks/usePanelId'

const CrowVideoPage = () => {
  const panelId = usePanelId()
  return <Redirect to={`/systems/${panelId}`} />
}

export default CrowVideoPage
