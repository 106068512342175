import React, { FC } from 'react'
import * as yup from 'yup'
import AcceptTermsCheckbox, {
  acceptTermsValidator,
} from '../../../components/forms/AcceptTermsCheckbox'
import SimpleForm, { Field } from '../../../components/forms/SimpleForm'
import { passwordConfirmValidator, passwordValidator } from '../../../components/forms/validators'

type FormValues = {
  password: string
  passwordRepeat: string
  acceptTerms: boolean
}

const resolver = yup.object({
  password: passwordValidator.required('Required'),
  passwordRepeat: passwordConfirmValidator.required('Required'),
  acceptTerms: acceptTermsValidator,
})

const fields: Field<keyof FormValues>[] = [
  { name: 'password', label: 'Password', type: 'password', placeholder: 'Password' },
  {
    name: 'passwordRepeat',
    label: 'Repeat password',
    type: 'password',
    placeholder: 'Repeat password',
  },
  {
    name: 'acceptTerms',
    Component: AcceptTermsCheckbox,
  },
]

type Props = {
  onSubmit: (values: FormValues) => void
  isLoading: boolean
}

const PasswordForm: FC<Props> = ({ onSubmit, isLoading }) => {
  return (
    <SimpleForm
      className="box-content"
      resolver={resolver}
      onSubmit={onSubmit}
      fields={fields}
      title="Set password"
      isLoading={isLoading}
    />
  )
}

export default PasswordForm
