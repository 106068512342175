import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { InstallationStatus, PanelStatus } from '../types/PanelModel'

export type System = {
  PanelId: string
  DisplayName: string
  AccessGroup: number
  Status: PanelStatus
  InstallationStatus: InstallationStatus
  IsDefaultPanel: boolean
  PanelTime: string
  LegalOwnerName: string
}

type SystemListState = {
  systems: System[]
}

const initialState: SystemListState = {
  systems: [],
}

type UpdateSystemInfoPayload = System[]

const systemListSlice = createSlice({
  name: 'systemList',
  initialState,
  reducers: {
    UPDATE_SYSTEM_LIST(state, action: PayloadAction<UpdateSystemInfoPayload>) {
      state.systems = action.payload
    },
  },
})

export const UPDATE_SYSTEM_LIST = systemListSlice.actions.UPDATE_SYSTEM_LIST

export const systemListReducer = systemListSlice.reducer
