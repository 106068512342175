import React, { FC, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import PageHeader from '../../../../components/PageHeader'
import { TwofactorContext } from '../../../../components/TwoFactorPrompt/TwoFactorPrompt'
import { useIrishCheck } from '../../../../i18n/useI18n'
import {
  useHasSecurityQuestion,
  useSetSecurityQuestion,
} from '../../../../services/requestHooks/securityQuestion'
import { useOnMount } from '../../../../utils/commonHooks'
import { usePanelId } from '../../hooks/usePanelId'
import PasswordPrompt, { usePasswordPrompt } from '../../Panel/PasswordPromt'
import SettingsSubPage from '../components/SettingsSubPage'
import EditQuestionForm, { EditQuestionFormValues } from './components/EditQuestionForm'
import QuestionFormPreview from './components/QuestionFormPreview'

export const SecurityQuestionHeader: FC = () => {
  const { checkIrish } = useIrishCheck()

  return (
    <PageHeader
      title={checkIrish('Security question', 'PW_SECURITY_QUESTION')}
      text={checkIrish(
        'In case of alarm, we will call you to verify. Create a security question, so we are able to identify you. Due to security reasons you',
        'PW_SECURITY_QUESTION_INFO',
      )}
    />
  )
}

const SecurityQuestionPage: FC = () => {
  const history = useHistory()
  const [showForm, setShowForm] = useState(false)

  const panelId = usePanelId()

  const { data: hasCodeWord, run, isLoading: isLoadingHasCodeWord } = useHasSecurityQuestion()
  const { promptForTwoFactor, cancelUrl } = useContext(TwofactorContext)
  const { run: setQuestion, isLoading: isSettingQuestion } = useSetSecurityQuestion({
    onSuccess: () => {
      history.push(`/systems/${panelId}/settings`)
    },
  })
  const { promptForPassword, promptState: passwordState } = usePasswordPrompt()

  useOnMount(() => {
    const onLoad = async () => {
      const ValidationCode = await promptForTwoFactor(cancelUrl)
      if (ValidationCode) {
        run(ValidationCode, panelId)
      }
    }
    onLoad()
  })

  const openForm = () => {
    setShowForm(true)
  }

  const onSubmit = async ({ alarmCenterCodeword, customerCodeword }: EditQuestionFormValues) => {
    const validationCode = await promptForTwoFactor()
    if (validationCode) {
      const Password = await promptForPassword()
      if (Password) {
        setQuestion({
          validationCode,
          Password,
          alarmCenterCodeword,
          customerCodeword,
          PanelId: panelId,
        })
      }
    }
  }

  return (
    <>
      <SettingsSubPage
        isLoading={
          hasCodeWord === undefined && isLoadingHasCodeWord /* || loadingPanelPermissions*/
        }
      >
        <SecurityQuestionHeader />
        {!showForm && !hasCodeWord ? (
          <QuestionFormPreview openForm={openForm} />
        ) : (
          <EditQuestionForm
            isLoading={isSettingQuestion}
            onSubmit={onSubmit}
            cancelUrl={`/systems/${panelId}/settings`}
          />
        )}
      </SettingsSubPage>
      <PasswordPrompt promptState={passwordState} />
    </>
  )
}

export default SecurityQuestionPage
