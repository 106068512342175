export enum MessageTemplate {
  PriceChange = 1,
  Newsletter = 2,
}

export interface Message {
  CreatedDate: string
  Id: string
  IsRead: boolean
  Subject: string
  TemplateId: MessageTemplate
}

export type MessageDetail = Message & {
  Body: MessageContent
  ExternalId: string
}

export interface MessageContent {
  Paragraphs: string[]
  Url: string
  UrlTxt: string
  greetingL1: string
  greetingL2: string
}
