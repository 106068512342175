/* eslint-disable import/no-unused-modules */
import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { LabelWrapper } from '../../../pages/crow/Locks/LockStyle'
import { SoundLevel } from '../../../types/Lock'
import { colors } from '../Style/theme'

export interface RadioButtonProps {
  checked: boolean
}

const RadioButton: FC<RadioButtonProps> = ({ checked }) => {
  return (
    <S.Outer role="radio" aria-checked={checked}>
      {checked && <S.Inner />}
    </S.Outer>
  )
}

interface CheckBoxType {
  value: number
  checked: SoundLevel | number
  setter: (value: number) => void
  className?: string
  label?: ReactNode
}

export const RadioButtonBox = ({ label, value, checked, setter, className }: CheckBoxType) => {
  return (
    <S.Label htmlFor={String(value)} className={className}>
      <S.Input
        type="radio"
        id={String(value)}
        checked={checked === value}
        onChange={() => setter(value)}
        className="input"
      />
      <LabelWrapper>{label}</LabelWrapper>
    </S.Label>
  )
}

const S = {
  Outer: styled.div`
    height: 24px;
    width: 24px;
    border-radius: 100%;
    border: 2px solid ${colors.white};
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  Inner: styled.div`
    height: 12px;
    width: 12px;
    border-radius: 100%;
    background-color: ${colors.white};
  `,
  Label: styled.label`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0px;
    padding: 16px;
    border: 1px solid #d6d4d4;
    border-radius: 12px;
  `,
  Input: styled.input`
    margin-bottom: 0px;
    flex: none;
    width: auto;
  `,
}

export default RadioButton
