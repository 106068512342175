import {
  CHECK_INVITE_SUCCESS,
  ACCEPT_INVITE_SUCCESS,
  SEND_SMS_SUCCESS,
  VALIDATE_SMS_SUCCESS,
} from '../../reducers/appUserRegistrationSlice'
import { RootState } from '../../store'
import {
  checkIfInviteExists,
  acceptAppUserInviteAsNewUser,
  acceptAppUserInviteAsExistingUser,
  acceptAppUserSendSMSCode,
  validateAppInviteSMSCode,
} from '../apiAccount'
import { createUseRequest } from './createUseRequest'

export const useCheckInviteExists = createUseRequest({
  apiCall: checkIfInviteExists,
  successActionCreator: CHECK_INVITE_SUCCESS,
})

export const useAcceptInviteNewUser = createUseRequest({
  successActionCreator: ACCEPT_INVITE_SUCCESS,
  apiCall: acceptAppUserInviteAsNewUser,
  selector: (state: RootState) => state.appUserRegistration,
})

export const useAcceptInviteExistingUser = createUseRequest({
  successActionCreator: ACCEPT_INVITE_SUCCESS,
  apiCall: acceptAppUserInviteAsExistingUser,
  selector: (state: RootState) => state.appUserRegistration,
})

export const useSendSMSCode = createUseRequest({
  successActionCreator: SEND_SMS_SUCCESS,
  apiCall: acceptAppUserSendSMSCode,
})

export const useValidateSMSCode = createUseRequest({
  successActionCreator: VALIDATE_SMS_SUCCESS,
  apiCall: validateAppInviteSMSCode,
})
