import axios from 'axios'
import { baseUrl } from '../configs'
import { System } from '../reducers/systemListSlice'
import { ChangePasswordPayload } from '../types/Account'
import {
  AppUserInvitePayload,
  CheckInvitePayload,
  CheckInviteResponse,
  ExistingAppUserInvitePayload,
  ValidateInviteSmsCodePayload,
} from '../types/AppUserInvite'
import { Benefit } from '../types/Benefit'
import { InvoicesResponse } from '../types/Invoices'
import { Partner } from '../types/Partner'
import { CreateAccountPayload } from '../types/Registration'
import { UserCultureInfo } from '../types/UserDetails'
import { getHeaders } from './api'

export const validateCode = async ({
  ValidationCode,
  PanelId,
}: {
  ValidationCode: string
  PanelId: string
}) => {
  await axios.post<string>(
    `${baseUrl}/account/AuthenticateSMSCode`,
    { ValidationCode, PanelId },
    await getHeaders(),
  )
  return {
    ValidationCode,
  }
}

export const createSMSCode = async () => {
  await axios.get(`${baseUrl}/account/RequestSMSValidationCode/`, await getHeaders())
}

export const getLastPhoneDigits = async () => {
  const response = await axios.get<{ Digits: string }>(
    `${baseUrl}/account/GetCustomerPhoneNumberLastDigits`,
    await getHeaders(),
  )
  return { lastPhoneDigits: response.data.Digits }
}

export const checkIfInviteExists = async ({
  email,
  inviteId,
}: CheckInvitePayload): Promise<CheckInviteResponse> => {
  return (
    await axios.get(
      `${baseUrl}/Settings/CheckIfInviteUserExists?inviteId=${inviteId}&email=${email}`,
    )
  ).data
}

export const acceptAppUserInviteAsNewUser = async (payload: AppUserInvitePayload) => {
  return (await axios.post(`${baseUrl}/Settings/AcceptInviteAsNewUser`, payload)).data
}

export const acceptAppUserInviteAsExistingUser = async (payload: ExistingAppUserInvitePayload) => {
  return (await axios.post(`${baseUrl}/Settings/AcceptInviteAsExistingUser`, payload)).data
}

export const acceptAppUserSendSMSCode = async (inviteId: string) => {
  return (await axios.get(`${baseUrl}/Settings/RequestOtpForInvite?inviteId=${inviteId}`)).data
}

export const validateAppInviteSMSCode = async (payload: ValidateInviteSmsCodePayload) => {
  return (await axios.post(`${baseUrl}/Settings/ValidateOtpForInvite`, payload)).data
}

export const validateCustomerPhone = async (payload: { CustomerNumber: string; phone: string }) => {
  return (await axios.post(`${baseUrl}/account/ValidateCustomerNrandPhone`, payload)).data
}

export const sendUserRegistrationEmail = async (payload: {
  CustomerNumber: string
  oneTimeCode: string
  countryCode: string
}) => {
  return await (await axios.post(`${baseUrl}/account/SendUserRegistrationEmail`, payload)).data
}

export const validateNewCustomerSMSCode = async (payload: {
  CustomerNumber: string
  oneTimeCode: string
  countryCode: string
}) => {
  return (await axios.post(`${baseUrl}/account/ValidateSMSCode`, payload)).data
}

export const createAccount = async (payload: CreateAccountPayload) => {
  return (await axios.post(`${baseUrl}/account/Register`, payload)).data
}

export const changePassword = async (payload: ChangePasswordPayload) => {
  return (await axios.post(`${baseUrl}/account/ChangePassword`, payload, await getHeaders())).data
}

export const getMessages = async () => {
  return (await axios.get(`${baseUrl}/account/GetUserMessages`, await getHeaders())).data
}

export const getMessageById = async (id: string) => {
  return (await axios.get(`${baseUrl}/account/GetUserMessage/${id}`, await getHeaders())).data
}

export const getInvoices = async (): Promise<InvoicesResponse> => {
  return (await axios.get(`${baseUrl}/account/GetInvoices`, await getHeaders())).data
}

export const getInvoicePDF = async (invoiceNumber: string) => {
  return axios
    .get(`${baseUrl}/account/GetInvoicePdf?id=${invoiceNumber}`, {
      ...(await getHeaders()),
      responseType: 'blob',
    })
    .then((response) => {
      const file = new Blob([response.data], { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(file)
      window.open(fileURL)
    })
}

export const getAlarmSystems = async () =>
  (await axios.get<System[]>(baseUrl + '/account/GetPanelList', await getHeaders())).data

export const requestCustomerRegistrationSMS = async (RegistrationId: string) => {
  await axios.get<void>(
    `${baseUrl}/account/RequestCustomerRegistrationSms?RegistrationId=${RegistrationId}`,
  )
}

export const createUserVerifyPin = async (body: {
  PinCode: string
  RegistrationId: string
  Password?: string
  Email?: string
}) => {
  await axios.post<{ PinCode: string }>(`${baseUrl}/account/createUserVerifyPin`, body)
  return { PinCode: body.PinCode }
}

export const createUserCommit = async (body: {
  PinCode: string
  RegistrationId: string
  Password?: string
  Email?: string
}) => {
  await axios.post<void>(`${baseUrl}/account/CreateUserCommit`, body)
}

export const setCulture = async (culture: UserCultureInfo) => {
  await axios.post(`${baseUrl}/account/SetCulture?culture=${culture}`, {}, await getHeaders())
}

export const getPartners = async () => {
  return (await axios.get<Partner[]>(`${baseUrl}/account/GetPartners`, await getHeaders())).data
}

export const getBenefits = async () => {
  return (await axios.get<Benefit[]>(`${baseUrl}/account/GetBenefits`, await getHeaders())).data
}

export const linkToPartner = async (body: { PartnerId: string; ExternalId: string }) => {
  return await axios.post(`${baseUrl}/account/LinkToPartner`, body, await getHeaders())
}

export const getBonusPointProducts = async (partnerId: string) => {
  return (
    await axios.get(
      `${baseUrl}/account/GetPartnerProducts?partnerId=${partnerId}`,
      await getHeaders(),
    )
  ).data
}

export const buyBonusProduct = async (body: { PartnerId: string; ProductId: string }) => {
  await axios.post(`${baseUrl}/account/BuyPartnerProduct`, body, await getHeaders())
}
