import {
  GET_PROPERTY_CONTACTS,
  SET_PROPERTY_CONTACT,
  INVITE_PROPERTY_CONTACT,
} from '../../reducers/propertyContactSlice'
import { RootState } from '../../store'
import { getPropertyContacts, setPropertyContact, invitePropertyContact } from '../settingsApi'
import { createUseRequest } from './createUseRequest'

const selectPropertyContacts = (state: RootState, panelId: string) =>
  state.propertyContacts[panelId]?.contacts

export const useGetPropertyContacts = createUseRequest({
  successActionCreator: GET_PROPERTY_CONTACTS,
  apiCall: getPropertyContacts,
  selector: selectPropertyContacts,
  requirePanelId: true,
})
export const useSetPropertyContact = createUseRequest({
  successActionCreator: SET_PROPERTY_CONTACT,
  apiCall: setPropertyContact,
  requirePanelId: true,
})
export const useInvitePropertyContact = createUseRequest({
  successActionCreator: INVITE_PROPERTY_CONTACT,
  apiCall: invitePropertyContact,
  requirePanelId: true,
})
