import React, { FC, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import OnboardingWizardSubPage from '../components/OnboardingWizardSubPage'
import { OnboardingWizardStep } from '../constants'
import { DirectionsHeader } from '../../AlarmSystems/Settings/Directions/DirectionsPage'
import DirectionsForm, {
  DirectionsFormValues,
} from '../../AlarmSystems/Settings/Directions/DirectionsForm'
import { useOnMount } from '../../../utils/commonHooks'
import { usePanelId } from '../../AlarmSystems/hooks/usePanelId'
import FailedRequest from '../components/FailedRequest'
import { useGetDirections, useSetDirections } from '../../../services/requestHooks/directions'
import { WizardContext } from '../WizardContext'

const step = OnboardingWizardStep.DIRECTIONS

const Directions: FC = () => {
  const history = useHistory()
  const PanelId = usePanelId()
  const { loadData, saveData, getUrl } = useContext(WizardContext)
  const {
    run: getDirections,
    data: directions,
    isLoading: fetchLoading,
    error: getDirectionsError,
  } = useGetDirections()
  const {
    run: saveDirections,
    isLoading: saveLoading,
    error: setDirectionsError,
  } = useSetDirections({
    onSuccess: () => {
      history.push(getUrl(step, 'forward'))
    },
  })

  const onSubmit = async ({ directions = '' }: DirectionsFormValues) => {
    const body = { PanelId, Directions: directions }
    saveData(saveDirections, body)
  }

  useOnMount(() => {
    loadData(getDirections, { PanelId }, getUrl(step, 'back'))
  })

  return (
    <OnboardingWizardSubPage activeStep={step} isLoading={fetchLoading}>
      <DirectionsHeader />
      {getDirectionsError ? (
        <FailedRequest text="Could not load location information, please try again or contact customer service." />
      ) : (
        <>
          <DirectionsForm
            onSubmit={onSubmit}
            defaultValues={directions !== undefined ? { directions } : undefined}
            cancelUrl={getUrl(step, 'back')}
            submitText="Next"
            cancelText="Back"
            isLoading={saveLoading}
          />
        </>
      )}

      {!!setDirectionsError && <FailedRequest text={setDirectionsError} />}
    </OnboardingWizardSubPage>
  )
}

export default Directions
