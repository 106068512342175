import React, { useState } from 'react'
import * as yup from 'yup'
import {
  emailValidator,
  passwordConfirmValidator,
  passwordValidator,
} from '../../../components/forms/validators'
import { TranslationKey } from '../../../types/generated/TranslationKey'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import TextField from '../../../components/forms/TextField'
import Button from '../../../components/Button'
import { Link } from 'react-router-dom'
import useI18n from '../../../i18n/useI18n'
import { Loader } from '../../../components/Elements/Loaders'
import { TranslationKeyOverride } from '../../../types/TranslationKeyOverride'
import TermsLink from '../../../components/TermsLink'
import styled from 'styled-components'

export type EmailAndPasswordFormValues = Record<keyof typeof resolver.fields, string> & {
  acceptedTerms: boolean
}

interface Field {
  name: keyof typeof resolver.fields
  label: TranslationKey
  type: 'text' | 'password' | 'checkbox'
  placeholder: TranslationKey
}

const fields: Field[] = [
  { name: 'email', type: 'text', label: 'E-mail', placeholder: 'E-mail' },
  { name: 'emailRepeat', type: 'text', label: 'Repeat the email', placeholder: 'Repeat the email' },
  { name: 'password', type: 'password', label: 'Password', placeholder: 'Password' },
  {
    name: 'passwordRepeat',
    type: 'password',
    label: 'Repeat password',
    placeholder: 'Repeat password',
  },
]

const resolver = yup.object({
  email: emailValidator.required('Required'),
  emailRepeat: emailValidator
    .test('equal', 'Repeat the email', (value, context) => value === context.parent.email)
    .required('Required'),
  password: passwordValidator.required('Required'),
  passwordRepeat: passwordConfirmValidator.required('Required'),
})

interface Props {
  onSubmit: (formValues: EmailAndPasswordFormValues) => void
  isLoading: boolean
  className?: string
}

const EmailAndPasswordForm = ({ onSubmit, isLoading, className }: Props) => {
  const [acceptedTerms, setAcceptedTerms] = useState(false)
  const { t } = useI18n()
  const {
    handleSubmit,
    register,
    formState: { isValid, errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(resolver),
  })

  const handleTermsAccept = () => {
    setAcceptedTerms(!acceptedTerms)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={className}>
      {fields.map((field) => (
        <TextField
          key={field.name}
          {...register(field.name)}
          errorKey={errors[field.name]?.message as TranslationKeyOverride}
          name={field.name}
          label={field.label}
          type={field.type}
          placeholder={field.placeholder}
        />
      ))}
      <label className="form checkbox">
        <input
          checked={acceptedTerms}
          type="checkbox"
          className="form__checkbox"
          onChange={handleTermsAccept}
          required
        />
        <i className="checkbox__indicator box-content" />
        {t('I accept')} <TermsLink>{t('the licende of agreement')}</TermsLink>.
        <div>
          <strong className="ml-1.5">{t('You have to accept the agreement to register.')}</strong>
        </div>
      </label>
      <ButtonContainer>
        <Button buttonType="third" disabled={!isValid || isLoading || !acceptedTerms}>
          {t('Next')}
        </Button>
        {isLoading && <Loader />}
        <Link to={'/'}>{t('Cancel')}</Link>
      </ButtonContainer>
    </form>
  )
}

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 768px) {
    justify-content: center;
  }
`

export default EmailAndPasswordForm
