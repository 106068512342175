import React, { FC, MouseEventHandler } from 'react'
import { Link } from 'react-router-dom'

type Props =
  | {
      returnURL: string
      onCancel?: undefined
    }
  | { onCancel: MouseEventHandler; returnURL?: undefined }

const FullScreenClose: FC<Props> = ({ returnURL, onCancel }: Props) => {
  if (returnURL) {
    return (
      <Link to={returnURL} className="fullscreen__close ugly-orange-focus">
        <div className="fullscreen__close-icon-better ">×</div>
        <div>esc</div>
      </Link>
    )
  }
  return (
    <button className="fullscreen__close ugly-orange-focus" onClick={onCancel}>
      <div className="fullscreen__close-icon-better">×</div>
      <div>esc</div>
    </button>
  )
}

export default FullScreenClose
