import React from 'react'
import TemplateImage1 from '../../../assets/images/pi_vekter_front.jpg'
import { MessageContent, MessageDetail } from '../../../types/Messages'
import useI18n from '../../../i18n/useI18n'
import { Link } from 'react-router-dom'

interface Props {
  message: MessageDetail
}

const MessageBox = ({ message }: Props) => {
  const { t } = useI18n()
  const text: MessageContent = message.Body
  return (
    <div className="bg-white px-4">
      <div className="flex justify-end">
        <Link to={'/messages'} className="fullscreen__back m-4 text-md">
          {t('Back')}
        </Link>
      </div>
      <img alt="PI-vekter" src={TemplateImage1} />
      <h1 className="text-center p-2 my-4">{message.Subject}</h1>
      <div>
        <>
          {text.Paragraphs.map((paragraph, i) => (
            <p className="paragraph" key={i}>
              {paragraph}
            </p>
          ))}
          <p className="paragraph mt-4 pb-4">
            {text.greetingL1 ? <span>{text.greetingL1}</span> : <span />} <br />
            {text.greetingL2 ? <span>{text.greetingL2}</span> : <span />}
            {text.Url && (
              <a href={text.Url} rel="noopener noreferrer" target="_blank">
                {text.UrlTxt}
              </a>
            )}
          </p>
        </>
      </div>
    </div>
  )
}

export default MessageBox
