import { UserFeature } from '../types/UserDetails'
import { useSelector } from 'react-redux'
import { RootState } from '../store'

const featureSelector = (feature: UserFeature) => ({ user }: RootState) =>
  !!user.User && user.User.Features.includes(feature)
export const useFeatureToggle = (feature: UserFeature) => {
  const featureEnabled = useSelector(featureSelector(feature))
  return featureEnabled
}
