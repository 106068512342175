import React, { useContext } from 'react'
import useI18n from '../../i18n/useI18n'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { passwordValidator } from '../../components/forms/validators'
import { useForm } from 'react-hook-form'
import TextField from '../../components/forms/TextField'
import FormButtons from '../../components/forms/FormButtons'
import { TwofactorContext } from '../../components/TwoFactorPrompt/TwoFactorPrompt'
import { string } from 'yup'
import FullscreenPage from '../../components/pages/FullscreenPage'
import { useChangePassword } from '../../services/requestHooks/user'
import { TranslationKeyOverride } from '../../types/TranslationKeyOverride'

const resolver = yup.object({
  existingPassword: string().required('Required'),
  newPassword: passwordValidator.required('Required'),
})

interface ChangePasswordFormValues {
  existingPassword: string
  newPassword: string
}

const ChangePasswordPage = () => {
  const { t } = useI18n()
  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<ChangePasswordFormValues>({
    resolver: yupResolver(resolver),
    mode: 'all',
  })
  const { promptForTwoFactor } = useContext(TwofactorContext)
  const { run: changePassword, isLoading } = useChangePassword()

  const onSubmit = async (form: ChangePasswordFormValues) => {
    const validationCode = await promptForTwoFactor()
    if (validationCode) {
      changePassword({
        OldPassword: form.existingPassword,
        NewPassword: form.newPassword,
        ValidationCode: validationCode,
      })
    }
  }

  return (
    <FullscreenPage returnURL={'/account'}>
      <form onSubmit={handleSubmit(onSubmit)} className="form">
        <h1 className="margin-bottom text-2xl">{t('Set new password')}</h1>
        <input
          style={{ display: 'none' }}
          aria-hidden
          autoComplete="username"
          type="text"
          name="username"
        />
        <TextField
          {...register('existingPassword')}
          label="Existing Password"
          type="password"
          errorKey={errors.existingPassword?.message as TranslationKeyOverride}
        />
        <TextField
          {...register('newPassword')}
          label="New Password"
          type="password"
          errorKey={errors.newPassword?.message as TranslationKeyOverride}
        />
        <FormButtons isValid={isValid} isLoading={isLoading} cancelUrl={'/account'} />
      </form>
    </FullscreenPage>
  )
}

export default ChangePasswordPage
