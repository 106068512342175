import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import Button from '../../../../../components/Button'
import AddButton from '../../../../../components/Button/AddButton'
import useI18n from '../../../../../i18n/useI18n'
import { AppUser } from '../../../../../types/AppUser'
import RadioButtonField, { RadioButton } from '../components/RadioButtonField'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../store'

export type PropertyContactSubmit = (values: FormValues) => void

type FormValues = {
  selectedUserId: string
}

const resolver = yup.object({
  selectedUserId: yup.string().required('Required'),
})

type Props = {
  propertyContacts: AppUser[] | undefined
  onSubmit: PropertyContactSubmit
  onAddClick: () => void
  onCancel: () => void
  panelId: string
}

const PropertyContactForm: FC<Props> = ({
  propertyContacts,
  onSubmit,
  onAddClick,
  onCancel,
  panelId,
}) => {
  const { t } = useI18n()

  const previouslySelectedContact = useSelector(
    (state: RootState) => state.panel[panelId]?.Info?.PropertyContact,
  )

  const {
    watch,
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormValues>({
    mode: 'all',
    resolver: yupResolver(resolver),
    defaultValues: {
      selectedUserId: previouslySelectedContact?.AppUserId,
    },
  })

  const selectedUserIndex = watch('selectedUserId')

  return (
    <form onSubmit={handleSubmit(onSubmit!)}>
      <RadioButtonField
        legend={
          <>
            <h1 className="text-3xl">{t('PROPERTY_CONTACT_HEADER')}</h1>
            <p className="pb-4">{t('PROPERTY_CONTACT_INFO_TEXT')}</p>
          </>
        }
      >
        {propertyContacts?.map(({ FirstName, LastName, PhoneNumber, AppUserId }) => (
          <RadioButton
            key={AppUserId}
            {...register('selectedUserId')}
            value={AppUserId}
            isSelected={selectedUserIndex === AppUserId}
          >
            <div>{`${FirstName} ${LastName}`}</div>
            <div>{PhoneNumber}</div>
          </RadioButton>
        ))}
      </RadioButtonField>
      <AddButton className="mb-8" type="button" onClick={onAddClick}>
        {t('ADD_CONTACT')}
      </AddButton>
      <Button buttonType="primary" disabled={!isValid}>
        {t('Confirm')}
      </Button>
      <Button type="button" onClick={onCancel} buttonType="secondary">
        {t('Cancel')}
      </Button>
    </form>
  )
}

export default PropertyContactForm
